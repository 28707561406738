export enum JobSort {
  Name = "Name",
  State = "State",
  AllocatedTo = "AllocatedTo",
  Scheduled = "Scheduled",
  Completed = "Completed",
  Organisation = "Organisation",
  Site = "Site",
  Reference = "Reference",
  CompletedDate = "CompletedDate",
}

export enum JobState {
  New = "e0f72601-c9bc-4cc3-b66c-7b6496df0301",
  Allocated = "068da9da-73cc-48c5-8b74-bb224a912c22",
  OnDevice = "b3afe7f2-cf80-4d7c-aa68-3052920646b9",
  InProgress = "c9d91ba9-2d2e-4684-a102-469d93c4360e",
  Uploading = "0d465388-c133-41d9-a476-0e008f0b982d",
  Review = "01da8eb2-61e5-45e4-b067-be9fd9b3f6f4",
  Completed = "070eb6a5-62cf-42d7-93e8-60b87ea133d5",
  Cancelled = "3951d504-0008-47e9-a6a4-ed0e849d32dc",
  Rejected = "7e9c7d65-6004-42e3-9fef-f8de4f058fce",
  CreatedOnDevice = "1f57a109-d622-4a5c-bf5c-db99963e458b",
  Generating = "ce194428-ac3d-4632-bc99-baf151fa246d",
  CompletedOnDevice = "36333cac-a9b7-42c1-a7b1-d148f4bfec75",
  UploadingRejected = "8750f501-3af6-4626-9327-5115751e2c69",
}

export type Job = JobDto & {
  clientName: string;
  organisation: {
    id: string;
    name: string;
  };
  siteName: string;
  site: {
    id: string;
    address: {
      address1: string;
      address2: string;
      city: string;
      country: string;
      zip: string;
    };
    name: string;
  };
  siteAddress: {
    id: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    zip: string;
  };
  state: {
    name: string;
    id: JobState;
  };
  allocatedTo: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userTypeName: string;
  };
  jobType: {
    name: string;
    version: number;
    state: string;
  };
  isOverdue: boolean;
  isRecurrence: boolean;
  monitoringRegimeId: string;
  recurrenceTypeName: string;
  tenantNumericCode: number;
  numericCode: number;
  completedDate?: string;
  scheduledDateAndTimeOffset: string;
  updatedDateAndTimeOffset: string;
  createdDateAndTimeOffset: string;
};

export type JobDto = {
  id: string;
  jobTypeId: string;
  name: string;
  scheduledDate: string;
  createdDate: string;
  updatedDate: string;
  timeInMinutes: number;
  notes: string;
  externalRef: string | null;
  parentJobId?: string;
  isGroupJob: boolean;
  assetId?: string;
  stateId: string;
  allocatedToId: string;
  contactId: string | null;
  siteId: string;
  organisationId: string;
  jobReferenceCode: string;
};

export type JobFilters = Partial<{
  dateFrom: string;
  dateTo: string;
  searchString: string;
  stateIds: string[];
  orgId: string;
}>;

export type JobDocument = {
  documentId: string;
  jobId: string;
  updatedDate: string;
  pdfGenerated: boolean;
  documentUri: string;
  visibleOnPortal: boolean;
  name: string;
  refresh?: boolean;
};
