const MAPS_ENDPOINT = "https://www.google.com/maps";

const buildUrl = (path: "search" | "dir" | "@", params: URLSearchParams) => {
  params.append("api", "1"); // required for all requests
  return `${MAPS_ENDPOINT}/${path}/?${params}`;
};

export const search = (inputQuery: string[]) => {
  const query = inputQuery.filter((value) => Boolean(value)).join(", ");
  const params = new URLSearchParams({
    query,
  });

  const url = buildUrl("search", params);
  redirect(url);
};

const redirect = (url: string) => {
  window.open(url, "_blank");
};
