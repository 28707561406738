import { logger } from "./log-helpers";

export type FileReaderResult = {
  data: string | ArrayBuffer | null;
  name: string;
  mimeType: string;
};

export const readFile = async (file: File) =>
  new Promise<FileReaderResult>((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (!e.target) throw new Error("No file data");
        const readResult = e.target.result;
        resolve({
          data: readResult,
          name: file.name,
          mimeType: file.type,
        });
      };
      reader.readAsDataURL(file);
    } catch (e) {
      logger.error(e);
      reject(e);
    }
  });

export const escapeBase64 = (data: FileReaderResult["data"]) => {
  if (typeof data !== "string") return data;
  const base64 = "base64,";
  const [, escapedData] = data.split(base64);
  return escapedData;
};
