import { Box, styled, SxProps, Theme } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { FileReaderResult } from "../../helpers/file-parser";
import { readFile } from "../../helpers/file-parser";
import { logger } from "../../helpers/log-helpers";
import { appStrings as strings } from "../../resources/strings/app";

export enum PreviewType {
  Name,
  Image,
}

interface Props {
  maxSize: number;
  allowedFileTypes: string[];
  handleUpload: (file: FileReaderResult) => Promise<void>;
  previewType?: PreviewType;
  previewData?: string;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.lightgrey,
  border: theme.spacing(0.1, "dashed", `${theme.palette.common.grey}`),
  "&:hover": {
    cursor: "pointer",
  },
});

const Image = styled("img")({
  maxHeight: 500,
  maxWidth: 500,
});

export const FileUpload: Component = ({
  maxSize,
  allowedFileTypes,
  handleUpload,
  previewType = PreviewType.Name,
  previewData,
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const onUpload = useCallback(handleUpload, [handleUpload]);

  const maxSizeInBytes = useMemo(() => maxSize * 1024, [maxSize]);
  const isTooLarge = useMemo(() => {
    if (!file) return false;
    return file.size > maxSizeInBytes;
  }, [file, maxSizeInBytes]);

  const isInvalidFileType = useMemo(() => {
    if (!file) return false;
    return !allowedFileTypes.includes(file.type);
  }, [file, allowedFileTypes]);

  useEffect(() => {
    if (!file) return;
    if (isTooLarge || isInvalidFileType) return;
    logger.info("Calling file upload handler");
    readFile(file).then((fileData) => {
      onUpload(fileData);
    });
  }, [file, isTooLarge, isInvalidFileType, onUpload]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target;
    if (!files) return;
    setFile(files[0]);
  };

  const onDrop: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (!files) return;
    setFile(files[0]);
  };

  const onDragEnter: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const onDragOver: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
  };

  const onClick = () => {
    if (!fileInput.current) return;
    fileInput.current.click();
  };

  const renderPreview = () => {
    if (previewType === PreviewType.Image && previewData) {
      return renderImagePreview();
    }
    if (!file) {
      return strings.labels.uploadFile;
    }
    if (isTooLarge) return strings.errors.fileTooLarge(file.name);
    if (isInvalidFileType) return strings.errors.invalidFileType(file.name);
    return file.name;
  };

  const renderImagePreview = () => {
    return (
      <Image src={previewData} srcSet={previewData} alt={""} loading="lazy" />
    );
  };

  return (
    <Box
      sx={[rootStyles]}
      display="flex"
      height={"100%"}
      width={"100%"}
      padding={4}
      justifyContent="center"
      onClick={onClick}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      data-testid="file-upload-root"
    >
      <input
        data-testid="file-upload-input"
        ref={fileInput}
        accept={allowedFileTypes.join()}
        type="file"
        hidden
        multiple={false}
        onChange={onChange}
      />
      {renderPreview()}
    </Box>
  );
};
