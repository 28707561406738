import { NotificationManager } from "react-notifications";
import { appStrings as strings } from "../resources/strings/app";

const DEFAULT_TIMEOUT = 5000;

const error = (
  body = "",
  title = strings.notifications.type.error,
  timeout = DEFAULT_TIMEOUT
) => NotificationManager.error(title, body, timeout);

const info = (
  title = strings.notifications.type.info,
  body = "",
  timeout = DEFAULT_TIMEOUT
) => NotificationManager.info(title, body, timeout);

const success = (
  title = strings.notifications.type.success,
  body = "",
  timeout = DEFAULT_TIMEOUT
) => NotificationManager.success(title, body, timeout);

const cleanUp = () => NotificationManager.removeAll();

export const notifications = { error, info, success, cleanUp };
