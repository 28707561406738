import { useState } from "react";
import type { JobPermissionsContext } from "../../context/JobPermissionsContext";
import { JobPermissionsContextProvider } from "../../context/JobPermissionsContext";
import { useCanCopyJob } from "../../hooks/jobs/permissions/useCanCopyJob";
import { useCanEditJobDetails } from "../../hooks/jobs/permissions/useCanEditJobDetails";
import { useCanEditNotes } from "../../hooks/jobs/permissions/useCanEditNotes";
import { useCanEditSitesAndContacts } from "../../hooks/jobs/permissions/useCanEditSitesAndContacts";
import { useCanGenerateDocument } from "../../hooks/jobs/permissions/useCanGenerateDocument";
import type { Mode } from "../general/types/Modify";

interface Props {
  children: JSX.Element;
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const JobPermissions: Component = ({ mode, children }) => {
  // not sure if "fetch" is the best variable name but we just need a way to
  // be able to trigger the below permissions to be updated
  const [fetch, setFetch] = useState(() => false);

  const [canCopyJob] = useCanCopyJob(mode, fetch);
  const [canEditJobDetails] = useCanEditJobDetails(mode, fetch);
  const [canEditSitesAndContacts] = useCanEditSitesAndContacts(mode, fetch);
  const [canEditNotes] = useCanEditNotes(mode, fetch);
  const [canGenerateDocument] = useCanGenerateDocument(mode, fetch);

  // create a refresh handler so that the permissions can be manually refreshed as required
  // for example, when the job state is changed by the user
  const refreshPermissions = () => setFetch((prev) => !prev);

  const jobPermissions: JobPermissionsContext = {
    refreshPermissions,
    canCopyJob,
    canEditJobDetails,
    canEditSitesAndContacts,
    canEditNotes,
    canGenerateDocument,
  };

  return (
    <JobPermissionsContextProvider value={jobPermissions}>
      {children}
    </JobPermissionsContextProvider>
  );
};
