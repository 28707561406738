import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { ShowIf } from "../authentication/ShowIfAuthorised";

export type TabItem = {
  label: string;
  value: any;
  Content?: JSX.Element;
};

interface Props {
  items: Array<TabItem>;
}
type Component = (props: Props) => JSX.Element;

type TabPanelProps = {
  children: JSX.Element;
  isCurrentTab: boolean;
  value: any;
};

const a11yTabProps = (value: any) => {
  return {
    id: `tab-${value}`,
    "aria-controls": `tabpanel-${value}`,
  };
};

const a11yPanelProps = (value: any) => {
  return {
    id: `tabpanel-${value}`,
    "aria-labelledby": `tab-${value}`,
  };
};

const TabPanel = ({ children, isCurrentTab, value }: TabPanelProps) => {
  return (
    <div role="tabpanel" hidden={!isCurrentTab} {...a11yPanelProps(value)}>
      <ShowIf show={isCurrentTab}>{children}</ShowIf>
    </div>
  );
};

export const TabsComponent: Component = ({ items = [] }) => {
  const [currentTab, setCurrentTab] = useState<any>(() => {
    const [item] = items;
    return item ? item.value : 0;
  });

  const handleChange = (_event: React.SyntheticEvent, newValue: any) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label="tabs"
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          {items.map(({ label, value }) => (
            <Tab
              key={value}
              label={label}
              value={value}
              {...a11yTabProps(value)}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        {items.map(({ Content, value }) => {
          if (!Content) return null;
          return (
            <TabPanel
              key={value}
              isCurrentTab={currentTab === value}
              value={value}
            >
              {Content}
            </TabPanel>
          );
        })}
      </Box>
    </>
  );
};
