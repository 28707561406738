import { Alert, Stack, Typography } from "@mui/material";
import { useFormVersionRelationshipCheck } from "../../hooks/forms/useFormVersionRelationshipCheck";
import { formStrings as strings } from "../../resources/strings/forms";
import { Loader } from "../general/Loader";

interface Props {
  versionId: string;
}
type Component = (props: Props) => JSX.Element;

export const FormDeleteVersionModalContent: Component = ({ versionId }) => {
  const [item, loading, error] = useFormVersionRelationshipCheck(versionId);
  if (loading) return <Loader inline active />;

  return (
    <Stack spacing={2}>
      <Typography>{strings.text.deleteVersion}</Typography>
      {error ? (
        <Alert severity="error">{strings.errors.relationshipCheckError}</Alert>
      ) : (
        <Alert severity="info">
          {strings.text.deleteVersionJobsWarning(item.jobs)}
        </Alert>
      )}
    </Stack>
  );
};
