import { useParams } from "react-router-dom";
import { paths } from "../../navigation/paths";
import { siteStrings as strings } from "../../resources/strings/sites";
import type { FullRouteProps } from "../../types";
import { OrganisationList } from "../organisations/OrganisationList";

export interface Props extends FullRouteProps {}
type Component = (props: Props) => JSX.Element;

export const SiteList: Component = ({ ...routeProps }) => {
  const { orgId } = useParams<{ orgId?: string }>();
  return (
    <OrganisationList
      {...routeProps}
      header={strings.headers.siteList}
      createButtonLabel={strings.labels.createSite}
      createButtonPath={paths.sites.create}
      filterStorageKey="siteFilters"
      sortStorageKey="siteSort"
      getViewPath={paths.sites.view}
      editActionLabel={strings.labels.editSite}
      jobsActionLabel={strings.labels.viewJobs}
      isParentOrganisation={false}
      parentId={orgId}
      organisationTypeId={routeProps.lowestOrganisationTypeId}
    />
  );
};
