import type { AxiosRequestConfig, CancelToken } from "axios";
import axios from "../libs/axios/axios-jobs";

export type JobPermissionHandler = (
  id: string,
  cancelToken?: CancelToken
) => Promise<boolean>;

const getJobPermission =
  (permission: string) =>
  async (jobId: string, cancelToken?: CancelToken): Promise<boolean> => {
    const params = new URLSearchParams({ jobId });
    const config: AxiosRequestConfig = { params, cancelToken };
    return axios.get<boolean>(permission, config).then(({ data }) => data);
  };

const copyJob: JobPermissionHandler = getJobPermission("/cancopyjob");

const editJobDetails: JobPermissionHandler =
  getJobPermission("/caneditjobdetails");

const editSitesAndContacts: JobPermissionHandler = getJobPermission(
  "/caneditsitesandcontacts"
);

const editNotes: JobPermissionHandler = getJobPermission("/caneditnotes");

const generateDocument: JobPermissionHandler = getJobPermission(
  "/cangeneratedocument"
);

export const api = {
  copyJob,
  editJobDetails,
  editSitesAndContacts,
  editNotes,
  generateDocument,
};
