import {
  LockOpenOutlined as LockOpenIcon,
  LockOutlined as LockIcon,
} from "@mui/icons-material";
import type { CancelToken } from "axios";
import { logger } from "../../../helpers/log-helpers";
import { formStrings as strings } from "../../../resources/strings/forms";
import { api } from "../../../services/jobtypes.service";
import { JobTypeState } from "../../../types/documents/JobType";
import { Button } from "../../general/Button";
import { IconAction } from "../../general/IconAction";
import { Modal } from "../../general/Modal";
import { FormPublishModalContent } from "./../FormPublishModalContent";

type PublishActionProps = {
  id: string;
  isNew: boolean;
  hasPublishedVersion: boolean;
  handleChange: (newState: JobTypeState) => void;
  cancelToken: CancelToken;
  isPublished: boolean;
};

export const PublishAction = ({
  id,
  isNew,
  hasPublishedVersion,
  cancelToken,
  handleChange,
  isPublished,
}: PublishActionProps) => {
  const handlePublish = async () => {
    try {
      await api.updatePublishStatus(id, true, false, cancelToken);
      handleChange(JobTypeState.Published);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleUnpublish = async () => {
    try {
      await api.updatePublishStatus(id, false, false, cancelToken);
      handleChange(JobTypeState.Unpublished);
    } catch (e) {
      logger.error(e);
    }
  };

  if (isPublished) {
    return (
      <IconAction tooltip={strings.labels.unpublish} onClick={handleUnpublish}>
        <LockOpenIcon />
      </IconAction>
    );
  }

  type PublishActionComponent = (props: {
    onClick?: () => Promise<void>;
  }) => JSX.Element;
  const Action: PublishActionComponent = ({ onClick }) => (
    <IconAction tooltip={strings.labels.publish} onClick={onClick}>
      <LockIcon />
    </IconAction>
  );

  if (isNew && hasPublishedVersion) {
    return (
      <Modal
        trigger={<Action />}
        header={strings.labels.publishForm}
        actions={({ onClose }) => [
          <Button label={strings.labels.cancel} onClick={onClose} />,
          <Button
            label={strings.labels.ok}
            variant="outlined"
            onClick={handlePublish}
          />,
        ]}
      >
        <FormPublishModalContent versionId={id} />
      </Modal>
    );
  }

  return <Action onClick={handlePublish} />;
};
