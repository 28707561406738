export const clientStrings = {
  headers: {
    clientList: "Client List",
    createClient: "Create Client",
    editClient: "Edit Client",
    deleteClient: "Delete Client",
    client: "Client",
  },
  labels: {
    createClient: "Create Client",
    editClient: "Edit Client",
    viewJobs: "View Jobs for the Client",
    viewSites: "View Sites for the Client",
    saveClient: "Save",
  },
};
