import type { SxProps, Theme } from "@mui/material";
import { Grid, styled } from "@mui/material";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { FilterChangeHandler } from "../../types";
import { Link } from "../general/controls/Link";
import { ChangeHandler } from "../general/types/Modify";
import { OrganisationInputFilters } from "./filters/OrganisationInputFilters";

interface Props<D extends { searchString?: string }> {
  filters: D;
  handleFilterChange: FilterChangeHandler;
  clearFilters: () => void;
}

const Span = styled("span")({});

const paddingStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(1.5, 1, 0),
});

export const OrganisationSearchBar = <D extends { searchString?: string }>({
  filters: { searchString },
  handleFilterChange,
  clearFilters,
}: Props<D>) => {
  const handleChange: ChangeHandler = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    handleFilterChange(name, value);
  };

  return (
    <Grid container spacing={1} role="searchbox">
      <Grid item xs={6}>
        <OrganisationInputFilters
          filters={{ searchString }}
          handleFilterChange={handleChange}
        />
      </Grid>
      <Span sx={[paddingStyles]}>
        <Link
          config={{
            text: strings.labels.reset,
            variant: "body2",
            onClick: clearFilters,
          }}
        />
      </Span>
    </Grid>
  );
};
