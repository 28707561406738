import { paths } from "../../navigation/paths";
import { clientStrings as strings } from "../../resources/strings/clients";
import type { RouteProps } from "../../types";
import { OrganisationUserList } from "../organisations/OrganisationUserList";

export interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const ClientUserList: Component = ({ ...routeProps }) => {
  return (
    <OrganisationUserList
      {...routeProps}
      parentOrganisationLabel={strings.headers.client}
      parentOrganisationPath={paths.clients.view}
      getCreateUserPath={paths.clients.users.create}
      getViewUserPath={paths.clients.users.view}
      listHeader={strings.headers.clientList}
      listPath={paths.clients.list}
      filterStorageKey="clientUserFilters"
      sortStorageKey="clientUserSort"
    />
  );
};
