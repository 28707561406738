import type {
  SubSectionTemplate,
  UpdateSubSectionTemplateData,
} from "@pulsion/forms-designer-2";
import instance from "./axios";
import config from "./config";

export interface ISubSectionTemplateApi {
  create(id: string, controlTemplate: SubSectionTemplate): Promise<any>;
  remove(id: string, subSectionId: string, removeJobs: boolean): Promise<any>;
  update(
    id: string,
    subSectionId: string,
    subSectionTemplate: UpdateSubSectionTemplateData
  ): Promise<any>;
  get(id: string, subSectionId: string): Promise<any>;
  reorder(id: string, sections: Array<string>): Promise<any>;
}

const create = async (
  id: string,
  controlTemplate: SubSectionTemplate
): Promise<any> => {
  const url = config.apiEndPoints.subSectionTemplate.createWithId(id);
  const res = await instance({
    url,
    method: "post",
    data: controlTemplate,
  });
  return res.data;
};

const get = async (id: string): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.subSectionTemplate.get(id),
    method: "get",
  });
  return res.data;
};

const remove = async (id: string, subsectionId: string): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.subSectionTemplate.delete(id, subsectionId),
    method: "delete",
  });
  return res.data;
};

const update = async (
  subSection: UpdateSubSectionTemplateData,
  id: string,
  subSectionId: string
): Promise<any> => {
  const url = config.apiEndPoints.subSectionTemplate.update(id, subSectionId);
  const res = await instance({
    url: url,
    method: "put",
    data: subSection,
  });
  return res.data;
};

const reorder = async (
  id: string,
  subSections: Array<string>
): Promise<any> => {
  const url = config.apiEndPoints.subSectionTemplate.reorder(id);
  const res = await instance({
    url: url,
    method: "put",
    data: subSections,
  });
  return res.data;
};

export { get, update, remove, create, reorder };
