import { useCallback, useState } from "react";
import { ShowIf } from "../../components/authentication/ShowIfAuthorised";
import { BasicCheckbox } from "../../components/general/controls/BasicCheckbox";
import { StateCircle } from "../../components/general/StateCircle";
import { JobReportActions } from "../../components/jobs/JobReportActions";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import type { PaletteStyle } from "../../hooks/general/usePalette";
import { usePalette } from "../../hooks/general/usePalette";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api as documentsApi } from "../../services/jobdocuments.service";
import { api } from "../../services/templates.service";
import type { RouteProps } from "../../types";
import type { DocumentTemplate } from "../../types/documents";
import { DocumentState } from "../../types/documents/DocumentTemplate";
import { JobState } from "../../types/documents/Job";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {
  jobId: string;
  jobTypeId: string;
  jobStateId: JobState;
}
type Component = (props: Props) => JSX.Element;

export const JobReportList: Component = ({
  jobId,
  jobTypeId,
  jobStateId,
  ...routeProps
}) => {
  const cancelToken = useCancelToken();

  const palette = usePalette();
  const [filters, setFilters] = useState<Partial<DocumentTemplate>>({
    id: jobId,
    jobTypeId,
    refresh: false,
  });

  const handleRefresh = useCallback(
    () => setFilters((prev) => ({ ...prev, refresh: !prev.refresh })),
    []
  );

  const getStatus = (state?: DocumentState): [PaletteStyle, string] => {
    switch (state) {
      case DocumentState.Failed:
        return [palette.red, strings.documentStates.failed];
      case DocumentState.Generated:
        return [palette.lightgreen, strings.documentStates.generated];
      case DocumentState.Generating:
        return [palette.orange, strings.documentStates.generating];
      default:
        return [palette.blue, strings.documentStates.new];
    }
  };

  const handleVisibilityChange = async (
    documentId: string,
    checked?: boolean
  ) => {
    if (typeof checked !== "boolean") return;
    try {
      await documentsApi.updateVisibility(documentId, checked, cancelToken);
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <div>
      <TableContainer<DocumentTemplate>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "name",
            Header: strings.labels.reportName,
            accessor: "name",
          },
          {
            id: "status",
            Header: strings.labels.status,
            accessor: ({ lastDocument = {} }) => {
              const { documentStateId } = lastDocument;
              const [styles, stateName] = getStatus(documentStateId);
              return <StateCircle stateName={stateName} styles={styles} />;
            },
            maxWidth: 50,
          },
          {
            id: "actions",
            Header: strings.labels.actions,
            accessor: (documentTemplate) => (
              <JobReportActions
                template={documentTemplate}
                permissions={routeProps.permissions}
                jobStateId={jobStateId}
                jobId={jobId}
                handleRefresh={handleRefresh}
              />
            ),
            maxWidth: 60,
          },
          {
            id: "visibleOnPortal",
            Header: strings.labels.visibleOnPortal,
            accessor: ({ siteVisitReport, lastDocument }) => (
              <ShowIf show={!siteVisitReport}>
                <BasicCheckbox
                  config={{
                    name: "visibleOnPortal",
                    defaultChecked: lastDocument?.visibleOnPortal,
                    size: "small",
                    compact: true,
                  }}
                  handleChange={(e) => {
                    if (!lastDocument?.documentId) return;
                    handleVisibilityChange(
                      lastDocument.documentId,
                      e.target.checked
                    );
                  }}
                />
              </ShowIf>
            ),
            maxWidth: 50,
          },
        ]}
        inlineLoader
        filters={filters as any}
      />
    </div>
  );
};
