import type {
  IFormsDesignerApi,
  SectionTemplate,
} from "@pulsion/forms-designer-2";
import instance from "./axios";
import config from "./config";

export interface ISectionTemplateApi {
  create(sectionTemplate: SectionTemplate): Promise<any>;
  remove(id: string, removeJobs: boolean): Promise<any>;
  update(id: string, sectionTemplate: SectionTemplate): Promise<any>;
  get(id: string): Promise<any>;
  reorder(id: string, sections: Array<string>): Promise<any>;
}

const create = async (sectionTemplate: SectionTemplate): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.sectionTemplate.create,
    method: "post",
    data: sectionTemplate,
  });
  return res.data;
};

const get = async (id: string): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.sectionTemplate.get(id),
    method: "get",
  });
  return res.data;
};

const remove = async (id: string): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.sectionTemplate.delete(id),
    method: "delete",
  });
  return res.data;
};

const update = async (
  id: string,
  sectionTemplate: SectionTemplate
): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.sectionTemplate.update(id),
    method: "put",
    data: sectionTemplate,
  });
  return res.data;
};

const reorder = async (id: string, sections: Array<string>): Promise<any> => {
  const url = config.apiEndPoints.sectionTemplate.reorder(id);
  const res = await instance({
    url: url,
    method: "put",
    data: sections,
  });
  return res.data;
};

const getSectionVariants: IFormsDesignerApi["getSectionVariants"] =
  async () => [];

export { get, update, remove, create, reorder, getSectionVariants };
