import { Tooltip } from "@mui/material";
import type { CancelToken } from "axios";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import type { Table, TableDefinition } from "../../helpers/export-helpers";
import { buildTableFromJson } from "../../helpers/export-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { appStrings as strings } from "../../resources/strings/app";
import { Button } from "../general/Button";
import { Loader } from "../general/Loader";

export type GetExportData = (cancelToken: CancelToken) => Promise<any[]>;

interface Props<D extends object> {
  getExportData: GetExportData;
  tableDefinition: TableDefinition<D>[];
  label?: string;
  fileName?: string;
  totalCount: number;
}

const EXPORT_LIMIT = 50000;

export const CSVExportButton = <D extends object>({
  getExportData,
  tableDefinition,
  label = strings.labels.export,
  fileName = "export.csv",
  totalCount,
}: Props<D>): JSX.Element => {
  const cancelToken = useCancelToken();
  const [exportData, setExportData] = useState<Table>(() => []);
  const [isExporting, setIsExporting] = useState(false);

  const linkRef = useRef<
    CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
  >(null);

  const onExport = async () => {
    setIsExporting(true);
    setExportData([]);

    try {
      const rawData = await getExportData(cancelToken);
      const tableData = buildTableFromJson(rawData, tableDefinition);

      setExportData(tableData);
      setIsExporting(false);

      linkRef.current?.link?.click();
    } catch (e) {
      if (cancelToken.reason) return;
      setIsExporting(false);
    }
  };

  const renderButton = () => {
    const disabled = totalCount > EXPORT_LIMIT;
    const button = (
      <span>
        <Button
          label={
            <Loader inline text="" active={isExporting} compact>
              <>{label}</>
            </Loader>
          }
          variant="outlined"
          onClick={onExport}
          disabled={disabled}
        />
      </span>
    );

    if (!disabled) return button;
    return <Tooltip title={strings.labels.exportTooLarge}>{button}</Tooltip>;
  };

  return (
    <>
      <CSVLink
        ref={linkRef}
        data={exportData}
        target="_blank"
        filename={fileName}
      />
      {renderButton()}
    </>
  );
};
