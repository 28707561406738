import { Alert, Stack, Typography } from "@mui/material";
import { useFormRelationshipCheck } from "../../hooks/forms/useFormRelationshipCheck";
import { formStrings as strings } from "../../resources/strings/forms";
import { Loader } from "../general/Loader";

interface Props {
  formId: string;
}
type Component = (props: Props) => JSX.Element;

export const FormDeleteModalContent: Component = ({ formId }) => {
  const [item, loading, error] = useFormRelationshipCheck(formId);
  if (loading) return <Loader inline active />;

  return (
    <Stack spacing={2}>
      <Typography>{strings.text.deleteForm}</Typography>
      {error ? (
        <Alert severity="error">{strings.errors.relationshipCheckError}</Alert>
      ) : (
        <Alert severity="info">
          {strings.text.deleteFormJobsWarning(item.jobs)}
        </Alert>
      )}
    </Stack>
  );
};
