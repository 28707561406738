import type { SxProps, Theme } from "@mui/material";
import { Typography } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { usePalette } from "../../hooks/general/usePalette";
import { appStrings } from "../../resources/strings/app";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { api } from "../../services/users.service";
import type { Permissions } from "../../types";
import type { UserDto } from "../../types/documents/User";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { BreadcrumbHeader, type Crumb } from "../general/BreadcrumbHeader";
import { Button } from "../general/Button";
import { Modal } from "../general/Modal";
import type { Mode } from "../general/types/Modify";

interface Props {
  mode: Mode;
  permissions: Permissions | null;
  organisationListHeader?: string;
  organisationListPath?: string;
  viewOrganisationHeader?: string;
  getViewOrganisationPath?: (organisationId: string) => string;
  getUsersListPath: (organisationId: string) => string;
  data: UserDto;
}
type Component = (props: Props) => JSX.Element;

const modalConfirmStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.red,
  "&:hover": {
    backgroundColor: theme.palette.common.red,
  },
});

export const OrganisationUserBreadcrumbHeader: Component = ({
  mode,
  permissions,
  organisationListHeader,
  organisationListPath,
  viewOrganisationHeader,
  getViewOrganisationPath,
  getUsersListPath,
}) => {
  const palette = usePalette();
  const { organisationId, id: userId } = useParams<{
    organisationId: string;
    id: string;
  }>();
  const { state } = useLocation<{ name: string }>();
  const cancelToken = useCancelToken();
  const history = useHistory();

  const saveButtons = (
    <>
      <Button type="reset" variant="outlined" label={strings.labels.cancel} />
      <Button type="submit" label={strings.labels.save} />
    </>
  );
  const crumbs: Crumb[] = [];
  if (organisationListHeader && organisationListPath) {
    crumbs.push({
      text: organisationListHeader,
      to: organisationListPath,
    });
  }
  if (viewOrganisationHeader && getViewOrganisationPath) {
    crumbs.push({
      text: state?.name ? state.name : viewOrganisationHeader,
      to: getViewOrganisationPath(organisationId),
    });
  }
  const usersListPath = getUsersListPath(organisationId);
  crumbs.push({
    text: strings.headers.users,
    to: usersListPath,
  });

  if (mode === "create") {
    return (
      <BreadcrumbHeader
        crumbs={[
          ...crumbs,
          {
            text: strings.labels.createUser,
          },
        ]}
        children={<>{saveButtons}</>}
      />
    );
  }

  const handleDelete = async () => {
    try {
      await api.deleteUser(userId, cancelToken);
      history.push(usersListPath, state);
    } catch (e) {
      logger.error(e);
    }
  };

  const deleteButton = (
    <ShowIfAuthorised
      userPermissions={permissions}
      entity={appStrings.entities.users}
      permission={appStrings.permissions.delete}
    >
      <Modal
        header={strings.headers.deleteUser}
        trigger={
          <Button
            variant="outlined"
            type="button"
            label={strings.labels.delete}
            sx={[palette.red]}
          />
        }
        actions={({ onClose }) => [
          <Button label={strings.labels.cancel} onClick={onClose} />,
          <Button
            label={strings.labels.confirm}
            onClick={handleDelete}
            sx={[modalConfirmStyles]}
          />,
        ]}
      >
        <Typography>{strings.text.delete}</Typography>
      </Modal>
    </ShowIfAuthorised>
  );

  return (
    <BreadcrumbHeader
      crumbs={[
        ...crumbs,
        {
          text: strings.labels.editUser,
        },
      ]}
      children={
        <>
          {deleteButton}
          {saveButtons}
        </>
      }
    />
  );
};
