import { useLoggedInUser } from "../../hooks/authentication/useLoggedInUser";
import { paths } from "../../navigation/paths";
import type { RouteProps } from "../../types";
import { OrganisationUserList } from "../organisations/OrganisationUserList";

export interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const TenantUserList: Component = ({ ...routeProps }) => {
  const { tenantId } = useLoggedInUser(routeProps);
  return (
    <OrganisationUserList
      {...routeProps}
      getCreateUserPath={paths.tenants.users.create}
      getViewUserPath={paths.tenants.users.view}
      organisationId={tenantId}
      isRootOrganisation
      filterStorageKey="tenantUserFilters"
      sortStorageKey="tenantUserSort"
    />
  );
};
