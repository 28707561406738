import { DateTime } from "luxon";
import { Mode } from "../../components/general/types/Modify";
import { JobAllocatedUserSelector } from "../../components/jobs/JobAllocatedUserSelector";
import { JobBreadcrumbHeader } from "../../components/jobs/JobBreadcrumbHeader";
import { JobLocationDetails } from "../../components/jobs/JobLocationDetails";
import { JobPermissions } from "../../components/jobs/JobPermissions";
import { JobTypeSelector } from "../../components/jobs/JobTypeSelector";
import { useJobPermissions } from "../../context/JobPermissionsContext";
import { toDateFormat } from "../../helpers/date-helpers";
import { paths } from "../../navigation/paths";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api } from "../../services/jobs.service";
import type { RouteProps, ValidationConstraints } from "../../types";
import type { JobDto } from "../../types/documents";
import { ModifyContainer } from "../general/ModifyContainer";
import { JobHistoryList } from "./JobHistoryList";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

const constraints: ValidationConstraints<JobDto> = {
  jobTypeId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.jobTypeId} is required`,
    },
  },
  organisationId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.clientId} is required`,
    },
  },
  siteId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.siteId} is required`,
    },
  },
  scheduledDate: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.scheduledDate} is required`,
    },
  },
  timeInMinutes: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.timeInMinutes} is required`,
    },
  },
};

const DEFAULT_SCHEDULED_DATE = toDateFormat(DateTime.now());

const Modify: Component = ({ mode, ...routeProps }) => {
  const { canEditNotes, canEditJobDetails } = useJobPermissions();

  return (
    <ModifyContainer<JobDto>
      {...routeProps}
      api={api}
      mode={mode}
      initialData={{
        id: "",
        createdDate: "",
        externalRef: null,
        isGroupJob: false,
        jobTypeId: "",
        name: "",
        notes: "",
        scheduledDate: DEFAULT_SCHEDULED_DATE,
        siteId: "",
        stateId: "",
        timeInMinutes: 0,
        updatedDate: "",
        allocatedToId: "",
        contactId: null,
        organisationId: "",
        jobReferenceCode: "",
      }}
      componentConfiguration={[
        {
          key: "header",
          content: [
            {
              controltype: "custom",
              Component: JobBreadcrumbHeader,
              md: 12,
              control: false,
            },
          ],
        },
        {
          key: "selectForm",
          modes: ["create"],
          content: [
            {
              controltype: "header",
              text: strings.header.selectForm,
            },
            {
              controltype: "custom",
              Component: JobTypeSelector,
            },
          ],
        },
        {
          key: "jobLocation",
          content: [
            {
              controltype: "header",
              text: strings.header.jobLocation,
            },
            {
              controltype: "custom",
              Component: JobLocationDetails,
              md: 12,
              control: false,
            },
          ],
        },
        {
          key: "jobDetails",
          content: [
            {
              controltype: "header",
              text: strings.header.jobDetails,
            },
            {
              controltype: "custom",
              Component: JobAllocatedUserSelector,
            },
            {
              controltype: "datepicker",
              name: "scheduledDate",
              label: strings.labels.scheduledDate,
              required: true,
              disabled: !canEditJobDetails,
            },
            {
              controltype: "timepicker",
              name: "scheduledDate",
              label: strings.labels.scheduledTime,
              required: true,
              outputFormatter: toDateFormat,
              disabled: !canEditJobDetails,
            },
            {
              controltype: "input",
              name: "externalRef",
              label: strings.labels.externalRef,
              disabled: !canEditJobDetails,
            },
            {
              controltype: "input",
              name: "timeInMinutes",
              label: strings.labels.timeInMinutes,
              type: "number",
              required: true,
              disabled: !canEditJobDetails,
            },
          ],
        },
        {
          key: "notes",
          content: [
            {
              controltype: "header",
              text: strings.header.notes,
            },
            {
              controltype: "input",
              name: "notes",
              placeholder: strings.placeholders.notes,
              minRows: 5,
              multiline: true,
              disabled: !canEditNotes || !canEditJobDetails,
            },
          ],
        },
        {
          key: "jobHistory",
          modes: ["update", "view"],
          content: [
            {
              controltype: "header",
              text: strings.header.jobHistory,
            },
            {
              controltype: "custom",
              Component: JobHistoryList,
              control: false,
              md: 12,
            },
          ],
        },
      ]}
      constraints={constraints}
      redirectPath={paths.jobs.list()}
    />
  );
};

export const JobModify: Component = (routeProps) => (
  <JobPermissions mode={routeProps.mode}>
    <Modify {...routeProps} />
  </JobPermissions>
);
