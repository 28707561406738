import type { AxiosRequestConfig, CancelToken } from "axios";
import axios, { anonymousInstance } from "../libs/axios/axios-users";
import type {
  CreateFunction,
  QueryFunction,
  ReadFunction,
  UpdateFunction,
} from "../types/API";
import type {
  CreateUserDto,
  ReadUserDto,
  UpdateUserDto,
  UserDto,
} from "../types/documents/User";

const query: QueryFunction<UserDto> = async (_body, _cancelToken?) =>
  Promise.resolve({ items: [], totalCount: 0 });

const read: ReadFunction<UserDto> = async (id, _childId, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = { cancelToken };
  const { data } = await axios.get<ReadUserDto>(path, config);
  const { password, confirmPassword, userTypeId, ...item } = data;
  return Promise.resolve({
    item: {
      ...item,
      password: "",
      confirmPassword: "",
      userTypeId: [userTypeId],
      originalUserType: item.userTypeName,
    },
  });
};

const create: CreateFunction<UserDto> = async ({ payload }, cancelToken?) => {
  const { id, userTypeId, mode, originalUserType, ...createPayload } = payload;
  const body: CreateUserDto = {
    ...createPayload,
    userTypeId: userTypeId[0],
  };
  const config: AxiosRequestConfig = { cancelToken };
  const { data } = await axios.post<UserDto>("", body, config);
  return Promise.resolve({
    id: data.id,
  });
};

const update: UpdateFunction<UserDto> = async ({ payload }, cancelToken?) => {
  const { id } = payload;
  const { mode, userTypeId, originalUserType, ...updatePayload } = payload;
  const { userTypeName } = updatePayload;
  const path = `/${id}`;
  const config: AxiosRequestConfig = { cancelToken };
  const body: UpdateUserDto = {
    ...updatePayload,
    userTypeId: userTypeId[0],
    currentUserType: originalUserType,
    newUserType: userTypeName,
  };
  await axios.put<UserDto>(path, body, config);
  return Promise.resolve({
    id: "",
  });
};

const requestPasswordReset = async (
  payload: { email: string; passwordResetURL: string },
  cancelToken?: CancelToken
) => {
  await anonymousInstance.post("/requestpasswordreset", payload, {
    cancelToken,
  });
  return Promise.resolve();
};

const resetPassword = async (
  payload: { userId: string; token: string; newPassword: string },
  cancelToken?: CancelToken
) => {
  await anonymousInstance.post("/resetpassword", payload, { cancelToken });
  return Promise.resolve();
};

const validatePasswordResetToken = async (
  payload: { userId: string; token: string },
  cancelToken?: CancelToken
) => {
  const { data } = await anonymousInstance.post<boolean>(
    "/validateresetpasswordtoken",
    payload,
    { cancelToken }
  );
  return data;
};

const deleteUser = async (id: string, cancelToken: CancelToken) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = { cancelToken };
  await axios.delete(path, config);
  return Promise.resolve(true);
};

export const api = {
  query,
  read,
  update,
  create,
  requestPasswordReset,
  resetPassword,
  validatePasswordResetToken,
  deleteUser,
};
