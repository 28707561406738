import { ExitToApp as SignOutIcon } from "@mui/icons-material";
import { Button, CssBaseline } from "@mui/material";
import logo from "../../assets/velappity-logo-white-text.svg";
import { useAuthContext } from "../../context/AuthContext";
import { signOut } from "../../libs/auth";
import { appStrings as strings } from "../../resources/strings/app";
import { Permissions } from "../../types";
import { menuItems } from "./listItems";
import { MenuComponent } from "./MenuComponent";

interface Props {
  children: JSX.Element;
  user: object | null;
  permissions: Permissions | null;
}
type Component = (props: Props) => JSX.Element;

export const Layout: Component = ({ children, user, permissions }) => {
  const { handleSignOut: logOutUser } = useAuthContext();

  const LoggedInLayout = () => {
    const handleSignOut = async () => {
      await signOut();
      logOutUser();
    };
    const additionalContent = (
      <Button color="inherit" onClick={handleSignOut}>
        {strings.labels.signOut}
        <SignOutIcon />
      </Button>
    );

    return (
      <MenuComponent
        menuItems={menuItems}
        permissions={permissions}
        logo={logo}
        additionalContent={additionalContent}
      >
        {children}
      </MenuComponent>
    );
  };
  const LoggedOutLayout = () => <>{children}</>;

  return (
    <>
      <CssBaseline />
      {user ? <LoggedInLayout /> : <LoggedOutLayout />}
    </>
  );
};
