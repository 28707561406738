import type { CancelToken } from "axios";
import { escapeBase64, FileReaderResult } from "../helpers/file-parser";
import axios from "../libs/axios/axios-jobs";
import type { QueryFunction } from "../types/API";
import type { JobDocument } from "../types/documents";

type UploadDocumentPayload = FileReaderResult & {
  jobId: string;
  visibleOnPortal: boolean;
};

const query: QueryFunction<JobDocument> = async ({ query }, cancelToken?) => {
  const { jobId } = query ?? {};
  const { data } = await axios.get<JobDocument[]>(
    `/${jobId}/getuploadeddocuments`,
    {
      cancelToken,
    }
  );
  return {
    items: data,
    totalCount: data.length,
  };
};

const updateVisibility = async (
  documentId: string,
  visibleOnPortal: boolean,
  cancelToken?: CancelToken
) => {
  await axios.put(
    `/updatedocumentvisibility/${documentId}/${visibleOnPortal}`,
    {},
    { cancelToken }
  );
  return Promise.resolve();
};

const deleteDocument = async (
  documentId: string,
  cancelToken?: CancelToken
) => {
  await axios.delete(`/deletedocument/${documentId}`, { cancelToken });
  return Promise.resolve();
};

const uploadDocument = async (
  jobId: string,
  result: FileReaderResult,
  cancelToken?: CancelToken
) => {
  const { data, ...fileData } = result;
  const escapedData = escapeBase64(data);
  const payload: UploadDocumentPayload = {
    ...fileData,
    data: escapedData,
    jobId,
    visibleOnPortal: false,
  };
  await axios.post("/adddocument", payload, { cancelToken });
  return Promise.resolve();
};

export const api = { query, updateVisibility, deleteDocument, uploadDocument };
