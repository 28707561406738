import { Grid, SxProps, Theme } from "@mui/material";
import { Header } from "./Header";

interface Props {
  children: JSX.Element | JSX.Element[];
  header: string | JSX.Element;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette.common.lightgrey,
  borderBottom: theme.spacing(0.1, "solid", theme.palette.common.grey!),
});

export const FormHeader: Component = ({ header, children }) => {
  const renderHeader = () => {
    if (typeof header !== "string") return header;
    return <Header text={header} />;
  };

  return (
    <Grid
      sx={[rootStyles]}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <Grid container justifyContent={"flex-start"}>
          {renderHeader()}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container justifyContent={"flex-end"}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
