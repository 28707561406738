import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DownloadRowsAction } from "../../components/settings/actions/DownloadRowsAction";
import { DownloadUploadedFileAction } from "../../components/settings/actions/DownloadUploadedFileAction";
import { BulkUploadListButtons } from "../../components/settings/BulkUploadListButtons";
import { BulkUploadStateCircle } from "../../components/settings/BulkUploadStateCircle";
import { formatDateTime } from "../../helpers/date-helpers";
import { bulkUploadStrings as strings } from "../../resources/strings/settings/bulkUpload";
import { api } from "../../services/bulkuploads.service";
import type { RouteProps } from "../../types";
import type { BulkUpload } from "../../types/documents";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const Span = styled("span")({});

export const BulkUploadList: Component = () => {
  return (
    <>
      <TableContainer<BulkUpload>
        api={api}
        permissions={null}
        user={null}
        tableColumns={[
          {
            Header: strings.labels.fileName,
            accessor: (upload) => (
              <DownloadUploadedFileAction upload={upload} />
            ),
          },
          {
            Header: strings.labels.createdBy,
            accessor: "createdByFullname",
          },
          {
            Header: strings.labels.uploadedDate,
            accessor: ({ createdDate }) => formatDateTime(createdDate),
          },
          {
            Header: strings.labels.status,
            accessor: (uploadData) => (
              <BulkUploadStateCircle uploadData={uploadData} />
            ),
          },
          {
            Header: strings.labels.downloadFailedRows,
            accessor: (upload) => <DownloadRowsAction upload={upload} />,
          },
        ]}
        header={strings.headers.bulkUpload}
        headerContent={<BulkUploadListButtons />}
      >
        <Typography variant="body2" align="left">
          <Span sx={[{ fontWeight: "bolder" }]}>{strings.labels.note}</Span>
          {strings.text.bulkUploadNote}
        </Typography>
      </TableContainer>
    </>
  );
};
