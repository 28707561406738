import { Autorenew as RefreshIcon } from "@mui/icons-material";
import { styled, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Link } from "../../components/general/controls/Link";
import { JobListButtons } from "../../components/jobs/JobListButtons";
import { JobSearchBar } from "../../components/jobs/JobSearchBar";
import { JobStateCircle } from "../../components/jobs/JobStateCircle";
import { formatDateTime } from "../../helpers/date-helpers";
import { displayAllocatedTo, displaySite } from "../../helpers/job-helpers";
import { usePalette } from "../../hooks/general/usePalette";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import { useJobStates } from "../../hooks/jobs/useJobStates";
import { paths } from "../../navigation/paths";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api } from "../../services/jobs.service";
import type { FilterChangeHandler, RouteProps } from "../../types";
import type { Job, JobFilters } from "../../types/documents";
import { JobSort } from "../../types/documents/Job";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {
  dateTo?: string;
}
type Component = (props: Props) => JSX.Element;

const DEFAULT_TO_DATE = DateTime.now().plus({ days: 14 }).endOf("day").toISO();

const Span = styled("span")({});

export const JobList: Component = ({
  dateTo = DEFAULT_TO_DATE,
  ...routeProps
}) => {
  const { orgId } = useParams<{ orgId?: string }>();

  const palette = usePalette();

  const [jobStates, jobStatesLoading] = useJobStates();

  const [filters, setFilters] = useSessionStorage<JobFilters>(
    {
      dateTo,
    },
    "jobFilters"
  );

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({ ...filters, orgId }), [filters, orgId]);

  return (
    <div>
      <TableContainer<Job>
        {...routeProps}
        api={api}
        header={strings.header.jobsList}
        headerContent={({ totalCount, page, size }) => (
          <JobListButtons
            permissions={routeProps.permissions}
            filters={filters}
            totalCount={totalCount}
            page={page}
            size={size}
          />
        )}
        tableColumns={[
          {
            id: "recurrence",
            Header: "",
            accessor: ({
              isRecurrence,
              recurrenceTypeName,
              monitoringRegimeId,
            }) => {
              if (!isRecurrence && !monitoringRegimeId) return " ";

              let tooltip = "";
              if (monitoringRegimeId) {
                tooltip = strings.labels.partOfMonitoringRegime;
              } else {
                const recurrenceType = recurrenceTypeName.toLowerCase();
                if (recurrenceType === strings.recurrenceTypes.forms) {
                  tooltip = strings.labels.partOfRecurrence;
                } else if (
                  recurrenceType === strings.recurrenceTypes.monitoring
                ) {
                  tooltip = strings.labels.partOfMonitoringVisit;
                }
              }

              return (
                <Tooltip title={tooltip}>
                  <RefreshIcon fontSize="inherit" />
                </Tooltip>
              );
            },
            disableSortBy: true,
            maxWidth: 25,
          },
          {
            id: JobSort.Reference,
            Header: strings.labels.jobReferenceCode,
            accessor: (job) => {
              const { id, jobReferenceCode, clientName, siteName, state } = job;
              const allocatedToId = displayAllocatedTo(job);
              const routeState: Partial<Job> = {
                clientName,
                siteName,
                stateId: state?.name,
                allocatedToId,
              };
              return (
                <Link
                  config={{
                    text: jobReferenceCode,
                    to: {
                      pathname: paths.jobs.edit(id),
                      state: routeState,
                    },
                    variant: "body2",
                  }}
                />
              );
            },
            maxWidth: 50,
          },
          {
            id: JobSort.Name,
            Header: strings.labels.name,
            accessor: "name",
            maxWidth: 75,
          },
          {
            id: JobSort.Organisation,
            Header: strings.labels.clientName,
            accessor: "clientName",
          },
          {
            id: JobSort.Site,
            Header: strings.labels.siteName,
            accessor: (job) => displaySite(job),
          },
          {
            id: JobSort.Scheduled,
            Header: strings.labels.scheduledDate,
            accessor: ({ scheduledDate, isOverdue }) => (
              <Span sx={[isOverdue && palette.red]}>
                {formatDateTime(scheduledDate)}
              </Span>
            ),
            maxWidth: 75,
          },
          {
            id: JobSort.AllocatedTo,
            Header: strings.labels.allocatedTo,
            accessor: (job) => displayAllocatedTo(job),
            maxWidth: 75,
          },
          {
            id: JobSort.State,
            Header: strings.labels.state,
            accessor: ({ state = {} }) => {
              const { name = "", id } = state;

              return <JobStateCircle state={id} name={name} />;
            },
            maxWidth: 75,
          },
          {
            id: JobSort.CompletedDate,
            Header: strings.labels.completedDate,
            accessor: ({ completedDate }) =>
              completedDate ? formatDateTime(completedDate) : "",
            maxWidth: 75,
          },
        ]}
        manualPagination
        defaultSort={[
          {
            id: JobSort.Reference,
            desc: true,
          },
        ]}
        children={
          <JobSearchBar
            filters={filters}
            handleFilterChange={handleFilterChange}
            clearFilters={clearFilters}
            tags={{ jobStates }}
          />
        }
        filters={tableFilters as any}
        loading={jobStatesLoading}
        sortStorageKey={"jobSort"}
      />
    </div>
  );
};
