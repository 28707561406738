import { usePalette } from "../../hooks/general/usePalette";
import type { BulkUpload } from "../../types/documents";
import { BulkUploadState } from "../../types/documents/BulkUpload";
import { StateCircle } from "../general/StateCircle";

interface Props {
  uploadData: BulkUpload;
}
type Component = (props: Props) => JSX.Element;

export const BulkUploadStateCircle: Component = ({
  uploadData: { statusDescription, status },
}) => {
  const palette = usePalette();
  const getStatusClass = (status?: BulkUploadState) => {
    switch (status) {
      case BulkUploadState.Complete:
        return palette.darkgreen;
      case BulkUploadState.PartialSuccess:
        return palette.lightgreen;
      case BulkUploadState.InProgress:
        return palette.orange;
      case BulkUploadState.Failure:
        return palette.red;
      default:
        return palette.blue;
    }
  };
  return (
    <StateCircle
      stateName={statusDescription}
      styles={getStatusClass(status)}
    />
  );
};
