export const appStrings = {
  entities: {
    users: "Users",
    settings: "Settings",
    jobs: "Jobs",
    formTemplates: "Form.Templates",
    forms: "Forms",
    assets: "Assets",
    issues: "Issues",
    clients: "Clients",
    tags: "Tags",
    velappity: "Velappity",
    bulkUpload: "BulkUpload",
    organisations: "Organisations",
  },
  permissions: {
    read: "View",
    create: "Create",
    update: "Update",
    access: "Access",
    delete: "Delete",
    viewList: "ViewList",
    createAdhocJob: "CreateAdhocJob",
    createForAnyUser: "CreateForAnyUser",
    godMode: "GodMode",
    copy: "Copy",
    publish: "Publish",
    export: "Export",
    import: "Import",
    editTenant: "EditTenant",
  },
  menuLabels: {
    dashboard: "Dashboard",
    users: "Users",
    settings: "Settings",
    help: "Help",
    jobs: "Jobs",
    forms: "Forms",
    assets: "Assets",
    issues: "Issues",
    bulkUpload: "Bulk Upload",
    subscription: "Subscription",
    payments: "Payments",
    clients: "Clients",
    sites: "Sites",
  },
  labels: {
    signOut: "Sign Out",
    signIn: "Login",
    loading: "Loading...",
    defaultSelect: "- select -",
    export: "Export",
    exportTooLarge:
      "Your search is too large for export, please refine your search further",
    organisationId: "Client",
    siteId: "Site",
    contactId: "Contact",
    loadingTags: "Loading Tags...",
    noOptionsFound: "No options found",
    uploadFile: "Drop files here to upload (or click)",
    close: "Close",
  },
  errors: {
    fileTooLarge: (fileName: string) =>
      `"${fileName}" is too large for upload.`,
    invalidFileType: (fileName: string) =>
      `"${fileName}" file type is not permitted for upload.`,
  },
  notifications: {
    type: { success: "Success", error: "Error", info: "Info" },
    messages: {
      invalidFields: "Form contains missing or invalid content",
      anErrorOccurred: "An error occurred while submitting the data.",
      submitted: "Data submitted successfully.",
    },
  },
};
