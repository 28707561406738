import type { AxiosInstance, CancelToken } from "axios";

export class BaseBulkUploadService {
  constructor(private axios: AxiosInstance) {}

  downloadOriginalFile = async (id: string, cancelToken?: CancelToken) => {
    const { data } = await this.axios.get<string>(
      `/downloadoriginalcsvfile/${id}`,
      {
        cancelToken,
      }
    );
    return data;
  };

  downloadFailedRows = async (id: string, cancelToken?: CancelToken) => {
    const { data } = await this.axios.get<string>(
      `/downloadfailedcsvfile/${id}`,
      {
        cancelToken,
      }
    );
    return data;
  };

  uploadFile = async (
    fileName: string,
    fileData: FormData,
    cancelToken?: CancelToken
  ) => {
    const { data } = await this.axios.post<string>(`/${fileName}`, fileData, {
      cancelToken,
    });
    return data;
  };
}
