export type DocumentTemplate = {
  lastDocument: {
    documentTemplateName: string;
    unverifiedStateId: string;
    documentStateId: DocumentState;
    isUpToDate: boolean;
    docGenCompletedByNow: boolean;
    documentId: string;
    jobId: string;
    updatedDate: string;
    pdfGenerated: boolean;
    visibleOnPortal: boolean;
  };
  siteVisitReport: boolean;
  id: string;
  jobTypeId: string;
  name: string;
  fileExtension: string;
  autoEmailOnJobCompletion: boolean;
  active: boolean;
  visibleOnPortal: boolean;
  refresh?: boolean;
};

export enum DocumentState {
  Generated = "b0fa7de5-e215-4407-baf6-071c3085efe6",
  Generating = "1a956372-c3d7-4a53-8a87-77cd0f50d2fd",
  Failed = "8933d8f2-b0f5-4228-8403-ea0bd1341b5d",
}
