import type { SxProps, Theme } from "@mui/material";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { usePalette } from "../../hooks/general/usePalette";
import { appStrings } from "../../resources/strings/app";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { api } from "../../services/organisations.service";
import { Permissions } from "../../types";
import { OrganisationDto } from "../../types/documents/Organisation";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { BreadcrumbHeader, type Crumb } from "../general/BreadcrumbHeader";
import { Button } from "../general/Button";
import { Modal } from "../general/Modal";
import type { Mode } from "../general/types/Modify";

interface Props {
  mode: Mode;
  createHeader: string;
  editHeader: string;
  listHeader: string;
  listPath: string;
  saveLabel: string;
  permissions: Permissions | null;
  data: OrganisationDto;
  deleteHeader: string;
  getUsersListPath: (id: string) => string;
}
type Component = (props: Props) => JSX.Element;

const modalConfirmStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.red,
  "&:hover": {
    backgroundColor: theme.palette.common.red,
  },
});

export const OrganisationBreadcrumbHeader: Component = ({
  mode,
  createHeader,
  editHeader,
  listHeader,
  listPath,
  saveLabel,
  permissions,
  data: { id, name },
  deleteHeader,
  getUsersListPath,
}) => {
  const palette = usePalette();
  const cancelToken = useCancelToken();
  const history = useHistory();

  const saveButton = <Button type="submit" label={saveLabel} />;
  const previousCrumbs: Crumb[] = [
    {
      text: listHeader,
      to: listPath,
    },
  ];

  if (mode === "create") {
    return (
      <BreadcrumbHeader
        crumbs={[
          ...previousCrumbs,
          {
            text: createHeader,
          },
        ]}
        children={saveButton}
      />
    );
  }

  const handleDelete = async () => {
    try {
      await api.deleteOrganisation(id, cancelToken);
      history.push(listPath);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleRedirect = () => {
    const path = getUsersListPath(id);
    history.push(path, { name });
  };

  const deleteButton = (
    <ShowIfAuthorised
      userPermissions={permissions}
      entity={appStrings.entities.organisations}
      permission={appStrings.permissions.delete}
    >
      <Modal
        header={deleteHeader}
        trigger={
          <Button
            variant="outlined"
            type="button"
            label={strings.labels.delete}
            sx={[palette.red]}
          />
        }
        actions={({ onClose }) => [
          <Button label={strings.labels.cancel} onClick={onClose} />,
          <Button
            label={strings.labels.confirm}
            onClick={handleDelete}
            sx={[modalConfirmStyles]}
          />,
        ]}
      >
        <Typography>{strings.text.delete}</Typography>
      </Modal>
    </ShowIfAuthorised>
  );

  const manageUsersButton = (
    <ShowIfAuthorised
      userPermissions={permissions}
      entity={appStrings.entities.users}
      permission={appStrings.permissions.read}
    >
      <Button
        variant="outlined"
        type="button"
        label={strings.labels.manageUsers}
        onClick={handleRedirect}
      />
    </ShowIfAuthorised>
  );

  return (
    <BreadcrumbHeader
      crumbs={[
        ...previousCrumbs,
        {
          text: editHeader,
        },
      ]}
      children={
        <>
          {deleteButton}
          {manageUsersButton}
          {saveButton}
        </>
      }
    />
  );
};
