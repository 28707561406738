import { api } from "../../services/organisations.service";
import { ReadFunction } from "../../types/API";
import {
  GetImmediateParentsDto,
  OrganisationDto,
} from "../../types/documents/Organisation";
import { useReadQuery } from "../general/useReadQuery";

const defaultResult: GetImmediateParentsDto = {
  isClientOrganisation: false,
  tenantId: "",
  organisations: [],
};

const defaultParentDetails: OrganisationDto = {
  id: "",
  address1: "",
  address2: "",
  city: "",
  contacts: [],
  country: "",
  county: "",
  documents: [],
  externalReference: "",
  image: "",
  name: "",
  organisationTypeId: "",
  timezone: "",
  zip: "",
  languageId: "",
  parentId: "",
};

const readImmediateParents: ReadFunction<GetImmediateParentsDto> = async (
  id,
  _childId,
  cancelToken?
) => api.getImmediateParents(id, 0, cancelToken).then((item) => ({ item }));

export const useParentOrganisationDetails = (
  organisationId: string,
  isParentOrganisation: boolean
) => {
  const [immedateParentsDto, loadingImmediateParents] = useReadQuery(
    defaultResult,
    readImmediateParents,
    isParentOrganisation ? organisationId : ""
  );
  const [parentDetails, loadingParentDetails] = useReadQuery(
    defaultParentDetails,
    api.read,
    isParentOrganisation ? immedateParentsDto.tenantId : organisationId
  );
  return [
    parentDetails,
    loadingParentDetails || loadingImmediateParents,
  ] as const;
};
