import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Crumb } from "../../components/general/BreadcrumbHeader";
import { OrganisationSearchBar } from "../../components/organisations/OrganisationSearchBar";
import { OrganisationUserListButtons } from "../../components/organisations/OrganisationUserListButtons";
import { displayName } from "../../helpers/string-helpers";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { api as organisationsApi } from "../../services/organisations.service";
import type { FilterChangeHandler, RouteProps } from "../../types";
import {
  OrganisationUserSort,
  type OrganisationUserFilters,
} from "../../types/documents/Organisation";
import { JobUser } from "../../types/documents/User";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {
  listHeader?: string;
  listPath?: string;
  parentOrganisationLabel?: string;
  parentOrganisationPath?: (organisationId: string) => string;
  getCreateUserPath: (organisationId: string) => string;
  getViewUserPath: (organisationId: string, userId: string) => string;
  organisationId?: string;
  isRootOrganisation?: boolean;
  filterStorageKey: string;
  sortStorageKey: string;
}
type Component = (props: Props) => JSX.Element;

const api = {
  query: organisationsApi.searchOrganisationUsers,
};

export const OrganisationUserList: Component = ({
  listHeader,
  listPath,
  parentOrganisationLabel,
  parentOrganisationPath,
  getCreateUserPath,
  getViewUserPath,
  organisationId: propsOrganisationId = "",
  isRootOrganisation,
  filterStorageKey,
  sortStorageKey,
  ...routeProps
}) => {
  const { id: organisationId = propsOrganisationId } = useParams<{
    id: string;
  }>();

  const [filters, setFilters] = useSessionStorage<OrganisationUserFilters>(
    {
      refresh: false,
    },
    filterStorageKey
  );

  const history = useHistory();
  const { state } = useLocation<{ name: string }>();

  const clickHandler = ({ id }: JobUser) => {
    const path = getViewUserPath(organisationId, id);
    history.push(path, state);
  };
  const crumbs: Crumb[] = [];

  const handleRefresh = () =>
    setFilters((prev) => ({ ...prev, refresh: !prev.refresh }));

  if (listPath && listHeader) {
    crumbs.push({
      text: listHeader,
      to: listPath,
    });
  }
  if (parentOrganisationLabel && parentOrganisationPath) {
    crumbs.push({
      text: state?.name ? state.name : parentOrganisationLabel,
      to: parentOrganisationPath(organisationId),
    });
  }

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => {
    setFilters({});
  };

  const tableFilters: any = useMemo(
    (): typeof filters => ({
      ...filters,
      parentId: organisationId,
    }),
    [filters, organisationId]
  );

  return (
    <>
      <TableContainer<JobUser>
        {...routeProps}
        api={api}
        header={strings.headers.users}
        headerContent={() => (
          <OrganisationUserListButtons
            permissions={routeProps.permissions}
            createPath={getCreateUserPath(organisationId)}
            isRootOrganisation={isRootOrganisation}
            organisationId={organisationId}
            handleRefresh={handleRefresh}
          />
        )}
        tableColumns={[
          {
            id: OrganisationUserSort.UserName,
            Header: strings.labels.userName,
            accessor: ({ firstName, lastName }) =>
              displayName(firstName, lastName),
          },
          {
            id: OrganisationUserSort.Email,
            Header: strings.labels.email,
            accessor: "email",
          },
          {
            Header: strings.labels.userType,
            accessor: "userTypeName",
          },
          {
            id: OrganisationUserSort.AccessFromParentOrganisation,
            Header: strings.labels.accessFromParentOrganisation,
            accessor: "parentOrgName",
            hidden: isRootOrganisation,
          },
        ]}
        manualPagination
        crumbs={crumbs}
        filters={tableFilters}
        defaultSort={[
          {
            id: OrganisationUserSort.AccessFromParentOrganisation,
            desc: false,
          },
        ]}
        clickHandler={clickHandler}
        children={
          <OrganisationSearchBar<OrganisationUserFilters>
            filters={filters}
            handleFilterChange={handleFilterChange}
            clearFilters={clearFilters}
          />
        }
        sortStorageKey={sortStorageKey}
      />
    </>
  );
};
