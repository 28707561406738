import type { SxProps, Theme } from "@mui/material";
import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  Hidden,
  styled,
  Typography,
} from "@mui/material";
import bgImage from "../../assets/velappity-bg-image.png";
import logo from "../../assets/velappity-logo-dark-text.svg";
import { authStrings as strings } from "../../resources/strings/auth";

interface Props {
  children: JSX.Element | JSX.Element[];
}
type Component = (props: Props) => JSX.Element;

interface LayoutProps {
  children: JSX.Element;
  bgImage: string;
  logo: string;
  legalText: string;
}

const Div = styled("div")({});

const rootStyles: SxProps<Theme> = {
  flexGrow: 1,
  height: "100vh",
};

const containerStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignContent: "center",
  height: "100%",
};

const mainContentStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  height: "100%",
  width: "100%",
};

const logoStyles: SxProps<Theme> = (theme) => ({
  height: theme.spacing(8),
  width: theme.spacing(40),
  marginX: "auto",
});

const backgroundImageStyles: SxProps<Theme> = {
  width: "auto",
  height: "100%",
};

const PageLayout = ({ children, bgImage, logo, legalText }: LayoutProps) => {
  return (
    <Div sx={[rootStyles]}>
      <Box display="flex" flexDirection="row" height="100vh" maxHeight="100vh">
        <Grid container>
          <Grid item sm={12} md={4} sx={[containerStyles]}>
            <div />
            <Grid item xs={8} sx={[mainContentStyles]}>
              <Avatar src={logo} variant="square" sx={[logoStyles]} />
              {children}
            </Grid>
            <div />
            <Typography variant="caption">{legalText}</Typography>
          </Grid>
          {/* Hide background image on smaller screen sizes */}
          <Hidden mdDown>
            <Grid item md>
              <CardMedia sx={[backgroundImageStyles]} image={bgImage} />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Div>
  );
};

export const AuthWrapper: Component = ({ children }) => {
  return (
    <PageLayout bgImage={bgImage} logo={logo} legalText={strings.text.legal}>
      <Box display="flex" flexDirection="column" justifyContent={"center"}>
        {children}
      </Box>
    </PageLayout>
  );
};
