import axios from "../libs/axios/axios-tags";
import type {
  QueryFunction,
  QueryResult,
  ReadFunction,
  UpdateFunction,
  UpdateResult,
} from "../types/API";
import type { TagGroupData, TagItem } from "../types/documents/Tag";

type TagResponse = Array<{
  name: string;
  id: string;
}>;

const read: ReadFunction<TagItem> = async (id, _childId, cancelToken?) => {
  const path = `/${id}`;
  const { data } = await axios.get<TagResponse>(path, {
    cancelToken,
  });
  return {
    item: {
      tagGroup: id,
      name: id,
      tags: data.map((tag, index) => ({
        tagGroup: id,
        id: tag.id,
        text: tag.name,
        order: index,
      })),
    },
  };
};

const query: QueryFunction<TagGroupData> = async (body, cancelToken?) => {
  const { data } = await axios.post<QueryResult<TagGroupData>>(
    "/groups",
    body,
    {
      cancelToken,
    }
  );
  return data;
};

const update: UpdateFunction<TagGroupData> = async (body, cancelToken?) => {
  const { data } = await axios.put<UpdateResult>("/submit", body, {
    cancelToken,
  });
  return data;
};

export const api = {
  read,
  query,
  update,
  create: update,
};
