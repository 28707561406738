import { IconButton, Tooltip } from "@mui/material";
import type { IconButtonProps } from "@mui/material";

interface Props extends IconButtonProps {
  children: JSX.Element;
  tooltip: string;
}
type Component = (props: Props) => JSX.Element;

export const IconAction: Component = ({ children, tooltip, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      <IconButton size="small" {...props}>
        {children}
      </IconButton>
    </Tooltip>
  );
};
