import { Typography } from "@mui/material";
import type { CancelToken } from "axios";
import { CopyIcon } from "../../../assets";
import { logger } from "../../../helpers/log-helpers";
import { formStrings as strings } from "../../../resources/strings/forms";
import { api } from "../../../services/jobtypes.service";
import { Button } from "../../general/Button";
import { IconAction } from "../../general/IconAction";
import { Modal } from "../../general/Modal";

type CopyActionProps = {
  id: string;
  cancelToken: CancelToken;
  handleEditRedirect: (id: string) => void;
};

export const CopyAction = ({
  id,
  cancelToken,
  handleEditRedirect,
}: CopyActionProps) => {
  const handleNewVersion = async () => {
    try {
      const { id: newVersionId } = await api.createNewVersion(id, cancelToken);
      handleEditRedirect(newVersionId);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleNewForm = async () => {
    try {
      const { id: newFormId } = await api.copyForm(id, cancelToken);
      handleEditRedirect(newFormId);
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <>
      <Modal
        trigger={
          <IconAction tooltip={strings.labels.copy}>
            <CopyIcon />
          </IconAction>
        }
        header={strings.labels.copyForm}
        actions={({ onClose }) => [
          <Button label={strings.labels.cancel} onClick={onClose} />,
          <Button
            label={strings.labels.newVersion}
            variant="outlined"
            onClick={handleNewVersion}
          />,
          <Button
            label={strings.labels.newForm}
            variant="outlined"
            onClick={handleNewForm}
          />,
        ]}
      >
        <Typography>{strings.text.copyForm}</Typography>
      </Modal>
    </>
  );
};
