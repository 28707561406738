const completePasswordResetBase = "/completePasswordReset";

export const paths = {
  root: "/",
  auth: {
    login: "/login",
    requestPasswordReset: "/passwordReset",
    completePasswordReset: `${completePasswordResetBase}/:userId/`,
    completePasswordResetBase,
    signUp: "/signUp",
  },
  dashboard: "/dashboard",
  help: "/help",
  settings: {
    list: "/settings",
    bulkUpload: "/settings/bulkUpload",
    subscription: "/settings/subscriptions",
    payments: `/settings/payments`,
  },
  jobs: {
    list: (orgId?: string) => {
      let path = "/jobs";
      if (orgId) path += `/${orgId}`;
      return path;
    },
    create: "/jobs/create",
    edit: (id: string) => `/jobs/edit/${id}`,
    sections: (id: string) => `/jobs/sections/${id}`,
    siteVisitResults: (id: string) => `/sitevisitresult/job/${id}`,
  },
  forms: {
    list: "/forms",
    create: "/forms/create",
    edit: (id: string) => `/forms/edit/${id}`,
    templates: (id: string) => `/forms/templates/${id}`,
  },
  assets: {
    list: "/assets",
  },
  issues: {
    list: "/issues",
  },
  clients: {
    list: "/clients",
    create: "/clients/create",
    view: (id: string) => `/clients/view/${id}`,
    users: {
      list: (organisationId: string) => `/clients/view/${organisationId}/users`,
      create: (organisationId: string) =>
        `/clients/view/${organisationId}/users/create`,
      view: (organisationId: string, userId: string) =>
        `/clients/view/${organisationId}/users/view/${userId}`,
    },
  },
  sites: {
    list: (orgId?: string) => {
      let path = "/sites";
      if (orgId) path += `/${orgId}`;
      return path;
    },
    create: "/sites/create",
    view: (id: string) => `/sites/view/${id}`,
    users: {
      list: (organisationId: string) => `/sites/view/${organisationId}/users`,
      create: (organisationId: string) =>
        `/sites/view/${organisationId}/users/create`,
      view: (organisationId: string, userId: string) =>
        `/sites/view/${organisationId}/users/view/${userId}`,
    },
  },
  tenants: {
    users: {
      list: () => "/users",
      create: () => "/users/create",
      view: (_organisationId: string, id: string) => `/users/view/${id}`,
    },
  },
  tags: {
    list: "/tags",
    view: (id: string) => `/tags/view/${id}`,
  },
};
