import type { SxProps, Theme } from "@mui/material";
import { Checkbox } from "@mui/material";
import type {
  BasicCheckbox as TBasicCheckbox,
  ChangeHandler,
} from "../types/Modify";

interface Props<D extends object> {
  config: Omit<TBasicCheckbox<D>, "controltype">;
  handleChange: ChangeHandler;
}

const compactStyles: SxProps<Theme> = {
  padding: 0,
};

export const BasicCheckbox = <D extends object>({
  handleChange,
  config: input,
}: Props<D>) => {
  const { compact = false, ...config } = input;
  return (
    <Checkbox
      color="primary"
      sx={[compact && compactStyles]}
      {...config}
      onChange={(e) => handleChange(e)}
    />
  );
};
