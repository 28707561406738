import type { SxProps, Theme } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { CancelToken } from "axios";
import { useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CopyIcon, DocumentIcon, UploadIcon } from "../../assets";
import { JobDocumentList } from "../../containers/jobs/JobDocumentList";
import { JobReportList } from "../../containers/jobs/JobReportList";
import { useJobPermissions } from "../../context/JobPermissionsContext";
import { convertEnumToArray } from "../../helpers/array-helpers";
import { formatDateTime } from "../../helpers/date-helpers";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { usePalette } from "../../hooks/general/usePalette";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api as jobsApi } from "../../services/jobs.service";
import { api } from "../../services/jobstates.service";
import type { JobDto } from "../../types/documents";
import { Job, JobState } from "../../types/documents/Job";
import { ShowIf, ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { defaultDropdownState, Dropdown } from "../general/controls/Dropdown";
import { FormHeader } from "../general/FormHeader";
import { Header } from "../general/Header";
import { IconAction } from "../general/IconAction";
import { Modal } from "../general/Modal";
import type { ChangeHandler, CustomProps } from "../general/types/Modify";

interface Props extends CustomProps<JobDto> {}
type Component = (props: Props) => JSX.Element;

const getAllowedStateIds = async (id: string, cancelToken: CancelToken) =>
  api
    .getAllowedJobStates(id, cancelToken)
    .then((states) => states.map(({ id }) => id));

const jobSectionStates = convertEnumToArray([
  JobState.New,
  JobState.Allocated,
  JobState.OnDevice,
  JobState.InProgress,
  JobState.Review,
  JobState.Rejected,
  JobState.Completed,
]);

const marginStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(0, 1),
});

const paddingStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(1),
});

const modalActionsStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.red,
  "&:hover": {
    backgroundColor: theme.palette.common.red,
  },
});

export const JobBreadcrumbHeader: Component = ({
  mode,
  data: { name, jobReferenceCode, updatedDate, stateId, id: jobId, jobTypeId },
  handleChange,
  permissions,
  user,
}) => {
  const { canCopyJob, canGenerateDocument, refreshPermissions } =
    useJobPermissions();
  const canSave = stateId === JobState.New;
  const showJobSections = jobSectionStates.includes(stateId);

  const { state } = useLocation<Job>();
  const palette = usePalette();
  const cancelToken = useCancelToken();
  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const getJobStates = useCallback(async () => {
    if (!id) return defaultDropdownState;
    const allowedStates = await getAllowedStateIds(id, cancelToken);
    return api.getJobStates(cancelToken).then((states = []) => {
      if (!states.length) return defaultDropdownState;
      const filteredStates = states.filter(({ id }) => {
        const isAllowedState = allowedStates.includes(id);
        const isCurrentState = id === stateId;
        return isAllowedState || isCurrentState;
      });
      return createDropdownOptions(filteredStates, "id", "name");
    });
  }, [id, stateId, cancelToken]);

  const saveButton = <Button label={strings.labels.save} type="submit" />;

  if (mode === "create") {
    return (
      <FormHeader
        header={strings.header.createJob}
        children={
          <>
            <Button
              label={strings.labels.cancel}
              variant="outlined"
              type="reset"
            />
            {saveButton}
          </>
        }
      />
    );
  }

  // if we arent in create mode, an ID should be present
  if (!id) return <></>;

  const handleCopy = async () => {
    try {
      const newJobId = await jobsApi.copyJob(jobId, cancelToken);
      history.push(paths.jobs.edit(newJobId));
    } catch (e) {
      logger.error(e);
    }
  };

  const handleDelete = async () => {
    try {
      await jobsApi.deleteJob(jobId, cancelToken);
      history.push(paths.jobs.list());
    } catch (e) {
      logger.error(e);
    }
  };

  const handleStateChange: ChangeHandler = async (event) => {
    try {
      const { value: jobStateId } = event.target;
      if (typeof jobStateId !== "string") return;

      await jobsApi.updateJobState(jobId, jobStateId, cancelToken);
      handleChange(event);
      refreshPermissions();
    } catch (e) {
      logger.error(e);
    }
  };

  const header = (
    <>
      <Header text={name} />
      <Typography
        variant="body2"
        sx={[palette.darkgrey, marginStyles, paddingStyles]}
      >
        {jobReferenceCode}
      </Typography>
      <Grid item xs={3} sx={[marginStyles]}>
        <BaseControl control={false}>
          <Dropdown
            config={{
              name: "stateId",
              value: stateId,
              options: getJobStates,
              text: state?.stateId,
              background: "white",
            }}
            handleChange={handleStateChange}
          />
        </BaseControl>
      </Grid>
    </>
  );

  const dateText = (
    <Typography
      variant="caption"
      sx={[palette.darkgrey, marginStyles, paddingStyles]}
    >{`${strings.labels.lastUpdated} ${formatDateTime(
      updatedDate
    )}`}</Typography>
  );

  const iconActions = (
    <>
      <ShowIf show={canCopyJob}>
        <span>
          <IconAction tooltip={strings.labels.copy} onClick={handleCopy}>
            <CopyIcon />
          </IconAction>
        </span>
      </ShowIf>
      <ShowIf show={canGenerateDocument}>
        <Modal
          trigger={
            <IconAction tooltip={strings.labels.viewDocuments}>
              <DocumentIcon />
            </IconAction>
          }
          header={strings.labels.viewDocuments}
          paddedContent={false}
        >
          <JobReportList
            permissions={permissions}
            user={user}
            jobId={jobId}
            jobTypeId={jobTypeId}
            jobStateId={stateId as JobState}
          />
        </Modal>
      </ShowIf>
      <Modal
        trigger={
          <IconAction tooltip={strings.labels.uploadDocuments}>
            <UploadIcon />
          </IconAction>
        }
        header={strings.labels.uploadDocuments}
        paddedContent={false}
      >
        <JobDocumentList permissions={permissions} user={user} jobId={jobId} />
      </Modal>
    </>
  );

  const redirectToSections = () =>
    history.push(paths.jobs.sections(id), { jobName: name });

  const buttons = (
    <>
      <ShowIf show={showJobSections}>
        <Button
          label={strings.labels.editJobSections}
          variant="outlined"
          onClick={redirectToSections}
        />
      </ShowIf>
      <ShowIfAuthorised
        userPermissions={permissions}
        entity={appStrings.entities.jobs}
        permission={appStrings.permissions.delete}
      >
        <Modal
          trigger={
            <Button
              label={strings.labels.delete}
              variant="outlined"
              sx={[palette.red]}
            />
          }
          header={strings.header.deleteJob}
          actions={({ onClose }) => [
            <Button label={strings.labels.cancel} onClick={onClose} />,
            <Button
              label={strings.labels.confirm}
              onClick={handleDelete}
              sx={[modalActionsStyles]}
            />,
          ]}
        >
          <Typography>{strings.text.delete}</Typography>
        </Modal>
      </ShowIfAuthorised>
      <ShowIf show={canSave}>{saveButton}</ShowIf>
    </>
  );

  return (
    <FormHeader
      header={header}
      children={
        <>
          {dateText}
          {iconActions}
          {buttons}
        </>
      }
    />
  );
};
