import { createCtx } from "../hooks/createCtx";

export type JobPermissionsContext = {
  refreshPermissions: () => void;
  canCopyJob: boolean;
  canEditJobData?: boolean;
  canEditJobDetails: boolean;
  canEditSitesAndContacts: boolean;
  canEditNotes: boolean;
  canComplete?: boolean;
  canCompleteEditData?: boolean;
  canCancel?: boolean;
  canGenerateDocument: boolean;
  canDeallocate?: boolean;
};

export const [useJobPermissions, JobPermissionsContextProvider] =
  createCtx<JobPermissionsContext>();
