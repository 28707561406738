import { paths } from "../../navigation/paths";
import { siteStrings as strings } from "../../resources/strings/sites";
import type { RouteProps } from "../../types";
import { OrganisationUserList } from "../organisations/OrganisationUserList";

export interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const SiteUserList: Component = ({ ...routeProps }) => {
  return (
    <OrganisationUserList
      {...routeProps}
      parentOrganisationLabel={strings.headers.site}
      parentOrganisationPath={paths.sites.view}
      getCreateUserPath={paths.sites.users.create}
      getViewUserPath={paths.sites.users.view}
      listHeader={strings.headers.siteList}
      listPath={paths.sites.list()}
      filterStorageKey="siteUserFilters"
      sortStorageKey="siteUserSort"
    />
  );
};
