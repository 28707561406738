import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";

import { useEffect } from "react";
import { useForm } from "../../../hooks/general/useForm";
import { usePalette } from "../../../hooks/general/usePalette";
import { organisationStrings as strings } from "../../../resources/strings/organisations";
import { Button } from "../../general/Button";
import { BaseControl } from "../../general/controls/BaseControl";
import { Input } from "../../general/controls/Input";
import { ChangeHandler } from "../../general/types/Modify";

interface Props<D extends { searchString?: string }> {
  filters: Pick<D, "searchString">;
  handleFilterChange: ChangeHandler;
}

export const OrganisationInputFilters = <D extends { searchString?: string }>({
  filters,
  handleFilterChange,
}: Props<D>) => {
  const palette = usePalette();
  const [currentFilters, handleChange, setData] =
    useForm<Props<D>["filters"]>(filters);

  useEffect(() => {
    setData(filters);
  }, [filters, setData]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    handleFilterChange({
      target: {
        name: "searchString",
        value: currentFilters.searchString,
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container>
        <Grid item xs>
          <BaseControl control={false}>
            <Input
              config={{
                name: "searchString",
                placeholder: strings.placeholders.searchString,
                value: currentFilters.searchString ?? "",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={[palette.darkgrey]} />
                    </InputAdornment>
                  ),
                },
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="outlined"
            type="submit"
            label={strings.labels.search}
          />
        </Grid>
      </Grid>
    </form>
  );
};
