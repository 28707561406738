import type { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation/paths";
import { siteStrings as strings } from "../../resources/strings/sites";
import type { RouteProps } from "../../types";
import { OrganisationUserModify } from "../organisations/OrganisationUserModify";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const SiteUserModify: Component = ({ mode, ...routeProps }) => {
  return (
    <OrganisationUserModify
      {...routeProps}
      mode={mode}
      organisationListHeader={strings.headers.siteList}
      organisationListPath={paths.sites.list()}
      getViewOrganisationPath={paths.sites.view}
      viewOrganisationHeader={strings.headers.site}
      getUsersListPath={paths.sites.users.list}
    />
  );
};
