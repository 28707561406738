import instance from "./axios";
import config from "./config";
import { setupSectionData } from "./JobFormService";

const createRow = async (
  jobId: string,
  sectionTypeId: string,
  isDuplicate: boolean = false
) => {
  if (jobId && sectionTypeId) {
    let response: any;
    await instance({
      url: config.createSection + `?save=${isDuplicate}`,
      method: "post",
      data: { jobId: jobId, sectionTypeId: sectionTypeId },
    });
    return response.data;
  } else {
    throw new Error("JobId or sectionTypeId not submitted");
  }
};

const deactivateRow = async (
  formData: any,
  containerSectionId: string,
  rowId: string,
  jobId: string
) => {
  if (containerSectionId && rowId) {
    const containerSectionData = getSectionData(formData, containerSectionId);

    try {
      JSON.parse(containerSectionData.sectionFormData);
    } catch (error) {
      throw new Error("Invalid Container Section Data submitted");
    }

    const preparedContainerSectionFormData = setupSectionData(
      jobId,
      containerSectionData
    );

    await Promise.all([
      instance({
        url: config.deactivateSection(jobId, rowId),
        method: "delete",
      }),
      instance({
        url: config.buildSectionRoute(containerSectionId),
        method: "put",
        data: preparedContainerSectionFormData,
      }),
    ]);
  } else if (containerSectionId && !rowId) {
    throw new Error("No Container Section Data available");
  } else if (!containerSectionId && rowId) {
    throw new Error("No Row Id passed");
  } else {
    throw new Error("No Container Section and Row Section Data available");
  }
};

function getSectionData(formData: Array<any>, sectionId: string) {
  return formData.find((section) => section?.sectionId === sectionId);
}

export { createRow, deactivateRow };
