import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../components/general/Loader";
import { ModifyComponent } from "../../components/general/ModifyComponent";
import type {
  ChangeHandler,
  ComponentConfiguration,
  Mode,
} from "../../components/general/types/Modify";
import { ModifyContextProvider } from "../../context/ModifyContext";
import { logger } from "../../helpers/log-helpers";
import { validate } from "../../helpers/validation-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { HooksLogger } from "../../hooks/hooks-logger";
import { notifications } from "../../libs/notifications";
import { RouteProps } from "../../types";
import type {
  CreateFunction,
  ReadFunction,
  UpdateFunction,
} from "../../types/API";

const hooksLogger = new HooksLogger("ModifyContainer/submitData");

export interface Props<D extends object> extends RouteProps {
  children?: JSX.Element;
  api: {
    read: ReadFunction<D>;
    update: UpdateFunction<D>;
    create: CreateFunction<D>;
  };
  initialData: D;
  componentConfiguration: ComponentConfiguration<D>;
  mode: Mode;
  constraints: object;
  redirectPath: string | ((id: string) => string);
  loading?: boolean;
}

export const ModifyContainer = <D extends { [key: string]: unknown }>({
  children,
  api,
  initialData: _initialData,
  componentConfiguration,
  mode: inputMode,
  permissions,
  constraints,
  redirectPath,
  loading: propsLoading = false,
  user,
}: Props<D>) => {
  const history = useHistory();
  const { id, childId } = useParams<{ id?: string; childId?: string }>();
  const cancelToken = useCancelToken();
  const [mode, setMode] = useState<Mode>(() => inputMode);

  const [initialData, setInitialData] = useState(() => _initialData);
  const [formData, setFormData] = useState<D>(initialData);
  const [loading, setLoading] = useState(() => false);

  const [validationResults, setValidationResults] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [success, setSuccess] = useState(() => false);

  const [redirectId, setRedirectId] = useState("");

  useEffect(() => {
    const query = async (inputId: string) => {
      hooksLogger.request("Getting item data");

      setLoading(true);
      try {
        const { item } = await api.read(inputId, childId, cancelToken);
        setFormData(item);
        setInitialData(item);
        setLoading(false);
        hooksLogger.success(item);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = logger.error(e);
        notifications.error(error);
        setLoading(false);
        hooksLogger.error(error);
      }
    };

    // pass the ID into the query function to avoid type issues when using
    // `id` inside query since technically it can be undefined
    // (should only be undefined in create mode when this query isnt required)
    if (id && inputMode !== "create") query(id);
  }, [id, childId, inputMode, cancelToken, api]);

  const handleModeSwitch = useCallback(() => {
    switch (mode) {
      case "update":
        break;
      case "view":
        setMode("update");
        break;
      case "create":
        let path = "";
        if (typeof redirectPath === "string") {
          path = redirectPath;
        } else {
          path = redirectPath(redirectId);
        }

        if (success && path) history.push(path);
        break;
      default:
        break;
    }
  }, [mode, history, redirectPath, redirectId, success]);

  const handleReset = useCallback(() => {
    setFormData(initialData);
    handleModeSwitch();
    setValidationResults(null);
  }, [initialData, handleModeSwitch]);

  useEffect(() => {
    if (success) {
      handleModeSwitch();
      notifications.success("Data submitted successfully");
      setSuccess(false);
    }
  }, [success, handleModeSwitch]);

  const handleChange: ChangeHandler = useCallback((e) => {
    const { target } = e;
    const { name, value, checked } = target;
    if (!name) return;

    setFormData((prev) => ({
      ...prev,
      [name]: target.hasOwnProperty("checked") ? checked : value,
    }));
  }, []);

  const submitData = async () => {
    const body = {
      eventType: mode,
      payload: formData,
    };
    let apiFunction = api.update;
    if (mode === "create") {
      apiFunction = api.create;
    }

    hooksLogger.request("Submitting form data");

    setLoading(true);
    setValidationResults(null);
    try {
      const { id } = await apiFunction(body, cancelToken);
      setRedirectId(id);
      setSuccess(true);
      setLoading(false);
      hooksLogger.success();
    } catch (e) {
      const error = logger.error(e);
      notifications.error(error);
      setLoading(false);
      hooksLogger.error(error);
    }
  };

  const validateForm = () =>
    validate<D>(formData, constraints, submitData, setValidationResults);

  const renderChildren = () => {
    if (!children) return null;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const isLoading = [loading, propsLoading].some((l) => l);

  return (
    <ModifyContextProvider value={{ handleModeSwitch }}>
      <Loader active={isLoading}>
        <div>
          <ModifyComponent<D>
            user={user}
            permissions={permissions}
            mode={mode}
            handleModeSwitch={handleModeSwitch}
            validateForm={validateForm}
            data={formData}
            handleChange={handleChange}
            handleReset={handleReset}
            componentConfiguration={componentConfiguration}
            loading={isLoading}
            setFormData={setFormData}
            validationResults={validationResults}
          />
          {renderChildren()}
        </div>
      </Loader>
    </ModifyContextProvider>
  );
};
