import { Loader } from "../components/general/Loader";
import { Layout } from "../components/layout";
import { useAuthContext } from "../context/AuthContext";
import { useAuthUser } from "../hooks/authentication/useAuthUser";
import { useReadQuery } from "../hooks/general/useReadQuery";
import { useUserPermissions } from "../hooks/permissions/queries/useUserPermissions";
import { Routes } from "../navigation/routes";
import { api } from "../services/organisationtypes.service";
import { OrganisationType } from "../types/documents/OrganisationType";

const Wrapper: React.FC = ({ children }) => (
  <div className="App">{children}</div>
);

const defaultOrganisationType: OrganisationType = {
  id: "",
  name: "",
  contactRequired: false,
  isLowestOrgType: false,
  isTheFirstOrgType: false,
  parentIsTheFirstOrgType: false,
};

const useOrganisationTypes = (loggedIn: boolean) => {
  const [lowestOrganisationType, loadingLowestOrganisationType] =
    useReadQuery<OrganisationType>(
      defaultOrganisationType,
      api.getLowest,
      loggedIn ? "id" : "",
      ""
    );
  const [secondLowestOrganisationType, loadingSecondLowestOrganisationType] =
    useReadQuery<OrganisationType>(
      defaultOrganisationType,
      api.getSecondLowest,
      loggedIn ? "id" : "",
      ""
    );
  return {
    lowestOrganisationType,
    loadingLowestOrganisationType,
    secondLowestOrganisationType,
    loadingSecondLowestOrganisationType,
  };
};

const App = () => {
  const { loggedIn } = useAuthContext();

  const { user, checkedAuth } = useAuthUser();
  const { permissions, loading: loadingPermissions } = useUserPermissions(
    // dont want to trigger the fetch before the user is actually logged in
    loggedIn ? user : null
  );
  const {
    lowestOrganisationType,
    secondLowestOrganisationType,
    loadingLowestOrganisationType,
    loadingSecondLowestOrganisationType,
  } = useOrganisationTypes(loggedIn);

  const isLoading = [
    !checkedAuth,
    loadingPermissions,
    loadingLowestOrganisationType,
    loadingSecondLowestOrganisationType,
  ].some((condition) => condition);
  if (isLoading) {
    return (
      <Wrapper>
        <Loader active />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Layout permissions={permissions} user={user}>
        <Routes
          user={user}
          permissions={permissions}
          lowestOrganisationTypeId={lowestOrganisationType.id}
          secondLowestOrganisationTypeId={secondLowestOrganisationType.id}
        />
      </Layout>
    </Wrapper>
  );
};

export default App;
