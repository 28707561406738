import type { CancelToken } from "axios";
import axios from "../libs/axios/axios-organisationtypes";
import type { ReadFunction, ReadResult } from "../types/API";
import type { OrganisationType } from "../types/documents/OrganisationType";

const getOrganisationType = async (
  path: string,
  cancelToken?: CancelToken
): Promise<ReadResult<OrganisationType>> => {
  const config = { cancelToken };
  const { data } = await axios.get<OrganisationType>(path, config);
  return { item: data };
};

const getSecondLowest: ReadFunction<OrganisationType> = async (
  _id,
  _childId,
  cancelToken
) => getOrganisationType("/secondlowest/", cancelToken);

const getLowest: ReadFunction<OrganisationType> = async (
  _id,
  _childId,
  cancelToken
) => getOrganisationType("/getlowest/", cancelToken);

export const api = { getSecondLowest, getLowest };
