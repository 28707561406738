import axios from "../libs/axios/axios-usertypes";
import type { ReadFunction } from "../types/API";
import { UserType } from "../types/documents/UserType";

const getUserTypes: ReadFunction<UserType[]> = async (
  _id,
  _childId,
  cancelToken
) => {
  const { data } = await axios.get<UserType[]>("/forSubscription", {
    cancelToken,
  });
  return { item: data };
};

export const api = {
  getUserTypes,
};
