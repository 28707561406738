import { useMemo } from "react";
import { BaseControl } from "../../../components/general/controls/BaseControl";
import { Dropdown } from "../../../components/general/controls/Dropdown";
import { getSelectedFormVersion } from "../../../helpers/jobtype-helpers";
import { formStrings as strings } from "../../../resources/strings/forms";
import type { DropdownOptions } from "../../../types";
import type { GroupedJobType } from "../../../types/documents";

type SelectVersionActionProps = {
  groupedForm: GroupedJobType;
  handleChange: (value: number) => void;
};

export const SelectVersionAction = ({
  groupedForm,
  handleChange,
}: SelectVersionActionProps) => {
  const { jobTypeList = [], selectedVersion } = groupedForm;

  const versionOptions: DropdownOptions = useMemo(
    () =>
      jobTypeList
        .sort((a, b) => a.version - b.version)
        .reverse()
        .map(({ version }) => ({
          label: `${strings.labels.version} ${version}`,
          value: version,
        })),
    [jobTypeList]
  );

  const jobType = getSelectedFormVersion(jobTypeList, selectedVersion);
  let version = 1;
  if (jobType?.version) version = jobType.version;
  return (
    <BaseControl control={false}>
      <Dropdown
        config={{
          name: "version",
          value: version,
          options: versionOptions,
          background: "white",
        }}
        handleChange={(e) => {
          const { value } = e.target;
          if (typeof value !== "number") return;
          handleChange(value);
        }}
      />
    </BaseControl>
  );
};
