import { usePalette } from "../../hooks/general/usePalette";
import { JobState } from "../../types/documents/Job";
import { StateCircle } from "../general/StateCircle";

interface Props {
  state?: JobState;
  name: string;
}
type Component = (props: Props) => JSX.Element;

export const JobStateCircle: Component = ({ state, name }) => {
  const palette = usePalette();

  const getStatusClass = (status?: JobState) => {
    switch (status) {
      case JobState.New:
        return palette.green;
      case JobState.Allocated:
        return palette.blue;
      case JobState.OnDevice:
        return palette.purple;
      case JobState.InProgress:
        return palette.orange;
      case JobState.Review:
        return palette.lightgreen;
      case JobState.Completed:
        return palette.darkgreen;
      case JobState.Cancelled:
        return palette.red;
      case JobState.Rejected:
        return palette.pink;
      default:
        return palette.blue;
    }
  };
  return <StateCircle styles={getStatusClass(state)} stateName={name} />;
};
