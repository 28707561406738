import axios from "../libs/axios/axios-contacttypes";
import type { ReadFunction } from "../types/API";
import type { ContactTypeDto } from "../types/documents/ContactType";

const getContactTypes: ReadFunction<ContactTypeDto[]> = async (
  _id,
  _childId,
  cancelToken
) => {
  const { data } = await axios.get<ContactTypeDto[]>("", { cancelToken });
  return { item: data };
};

export const api = { getContactTypes };
