import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { formatDateTime } from "../../helpers/date-helpers";
import { formatFromPascalCase } from "../../helpers/string-helpers";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api } from "../../services/jobhistory.service";
import type { RouteProps } from "../../types";
import { JobHistory } from "../../types/documents";
import { JobHistoryType } from "../../types/documents/JobHistory";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const getHistoryDetails = (history: JobHistory) => {
  let result: string[] = [];
  switch (history.jobHistoryTypeId) {
    case JobHistoryType.StateChange:
      if (history.sourceStateName) {
        result.push(strings.labels.historyFrom, `"${history.sourceStateName}"`);
      }
      if (history.destinationStateName) {
        result.push(
          strings.labels.historyTo,
          `"${history.destinationStateName}"`
        );
      }
      break;
    case JobHistoryType.AllocatedToUser:
      if (history.allocatedToName) {
        result.push(strings.labels.historyTo, `"${history.allocatedToName}"`);
      }
      break;
    case JobHistoryType.Cloned:
      if (history.clonedFromReference) {
        result.push(
          strings.labels.historyFrom,
          `"${history.clonedFromReference}"`
        );
      }
      break;
    default:
      result = [];
      break;
  }
  return result.join(" ");
};

export const JobHistoryList: Component = ({ ...routeProps }) => {
  const { id } = useParams<{ id: string }>();
  const filters: any = useMemo(() => ({ jobId: id }), [id]);
  return (
    <div>
      <TableContainer<JobHistory>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "jobHistoryType",
            Header: strings.labels.jobHistoryType,
            accessor: ({ jobHistoryTypeName }) =>
              formatFromPascalCase(jobHistoryTypeName),
            maxWidth: 40,
          },
          {
            id: "createdByName",
            Header: strings.labels.jobHistoryCreatedBy,
            accessor: "createdByName",
            maxWidth: 40,
          },
          {
            id: "createdDate",
            Header: strings.labels.jobHistoryCreated,
            accessor: ({ createdDate }) => formatDateTime(createdDate),
            maxWidth: 25,
          },
          {
            id: "jobHistoryDetails",
            Header: strings.labels.jobHistoryDetails,
            accessor: getHistoryDetails,
          },
        ]}
        inlineLoader
        topBorder
        pageSize={10}
        manualPagination
        filters={filters}
      />
    </div>
  );
};
