import { useEffect, useState } from "react";
import { Permissions } from "../../types";

export const useIsAuthorised = (
  // permissions may end up coming from store rather than
  // passing in each time
  userPermissions: Permissions | null,
  entity: string,
  inputPermission: string | string[] = ""
) => {
  const [isAuthorised, setIsAuthorised] = useState(false);

  useEffect(() => {
    if (userPermissions) {
      let allowedPermissions: string[] = [];
      if (userPermissions[entity]) {
        allowedPermissions = userPermissions[entity];
      } else {
        return;
      }

      const permissionsToCheck = Array.isArray(inputPermission)
        ? inputPermission
        : [inputPermission];
      const userHasPermission = permissionsToCheck.some((currentPermission) =>
        allowedPermissions.includes(currentPermission)
      );
      if (!inputPermission || userHasPermission) {
        setIsAuthorised(true);
      }
    }
  }, [userPermissions, entity, inputPermission]);

  return isAuthorised;
};
