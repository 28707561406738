import type { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation/paths";
import { clientStrings as strings } from "../../resources/strings/clients";
import type { FullRouteProps } from "../../types";
import { OrganisationModify } from "../organisations/OrganisationModify";

export interface Props extends FullRouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const ClientModify: Component = ({ ...routeProps }) => {
  return (
    <OrganisationModify
      {...routeProps}
      createHeader={strings.headers.createClient}
      editHeader={strings.headers.editClient}
      listHeader={strings.headers.clientList}
      listPath={paths.clients.list}
      saveLabel={strings.labels.saveClient}
      redirectPath={paths.clients.list}
      organisationTypeId={routeProps.secondLowestOrganisationTypeId}
      isParentOrganisation
      deleteHeader={strings.headers.deleteClient}
      getUsersListPath={paths.clients.users.list}
    />
  );
};
