import axios from "../libs/axios/axios-roles";
import type { ReadFunction } from "../types/API";
import type { Role } from "../types/documents/Role";

const getRoles: ReadFunction<Role[]> = async (_id, _childId, cancelToken) => {
  const { data } = await axios.get<Role[]>("", { cancelToken });
  return { item: data };
};

export const api = {
  getRoles,
};
