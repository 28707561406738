import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "../../../hooks/general/useForm";
import { usePalette } from "../../../hooks/general/usePalette";
import { formStrings as strings } from "../../../resources/strings/forms";
import type { JobTypeFilters } from "../../../types/documents/JobType";
import { Button } from "../../general/Button";
import { BaseControl } from "../../general/controls/BaseControl";
import { Input } from "../../general/controls/Input";
import type { ChangeHandler } from "../../general/types/Modify";

interface Props {
  filters: Pick<JobTypeFilters, "name">;
  handleFilterChange: ChangeHandler;
}
type Component = (props: Props) => JSX.Element;

export const FormInputFilters: Component = ({
  filters,
  handleFilterChange,
}) => {
  const palette = usePalette();
  const [currentFilters, handleChange, setData] =
    useForm<Props["filters"]>(filters);

  useEffect(() => {
    setData(filters);
  }, [filters, setData]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    handleFilterChange({
      target: {
        name: "name",
        value: currentFilters.name,
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <BaseControl control={false}>
            <Input
              config={{
                name: "name",
                placeholder: strings.placeholders.search,
                value: currentFilters.name ?? "",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={[palette.darkgrey]} />
                    </InputAdornment>
                  ),
                },
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="outlined"
            type="submit"
            label={strings.labels.search}
          />
        </Grid>
      </Grid>
    </form>
  );
};
