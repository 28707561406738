import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { getIdToken } from "../../libs/auth";
import { CognitoUser } from "../../types";
import { HooksLogger } from "../hooks-logger";

const logger = new HooksLogger("useAuthUser.ts");

type IdToken = { [key: string]: any };

const checkStringBoolean = (input: string) => input === "True";

const buildAuthUser = (userDetails: IdToken): CognitoUser => ({
  username: userDetails["preferred_username"],
  tenantId: userDetails["TenantId"],
  roleId: userDetails["role"],
  email: userDetails["email"],
  userId: userDetails["sub"],
  firstName: userDetails["given_name"],
  surname: userDetails["family_name"],
  isVelappityUser: checkStringBoolean(userDetails["IsVelappityUser"]),
  isFormsUser: checkStringBoolean(userDetails["IsFormsUser"]),
  isComplianceUser: checkStringBoolean(userDetails["IsComplianceUser"]),
  isClientUser: checkStringBoolean(userDetails["IsClientUser"]),
});

export const useAuthUser = () => {
  const { loggedIn, handleSignIn } = useAuthContext();

  const [user, setUser] = useState<null | CognitoUser>(null);
  const [checkedAuth, setCheckedAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (!loggedIn && user) {
      if (mounted) setUser(null);
    }

    return () => {
      mounted = false;
    };
  }, [loggedIn, user]);

  useEffect(() => {
    let mounted = true;

    logger.info("Calling auth user");
    const query = async () => {
      logger.info("Getting user");
      if (mounted) setLoading(true);

      try {
        const userDetails = await getIdToken();
        const authUser = buildAuthUser(userDetails);
        logger.success(userDetails);

        if (mounted) {
          if (!loggedIn) handleSignIn();
          setUser(authUser);
        }
      } catch (e) {}
      if (mounted) {
        setCheckedAuth(true);
        setLoading(false);
      }
    };
    query();

    return () => {
      mounted = false;
    };
  }, [loggedIn, handleSignIn]);

  return { user, checkedAuth, loading };
};
