import { Box, Typography } from "@mui/material";
import { usePalette } from "../../hooks/general/usePalette";
import { bulkUploadStrings as strings } from "../../resources/strings/settings/bulkUpload";
import type { TabItem } from "../general/TabsComponent";
import { TabsComponent } from "../general/TabsComponent";
import { AssetHelpPanel } from "./panels/AssetHelpPanel";
import { JobHelpPanel } from "./panels/JobHelpPanel";
import { OrganisationHelpPanel } from "./panels/OrganisationHelpPanel";

interface Props {}
type Component = (props: Props) => JSX.Element;

const tabs: Array<TabItem> = [
  {
    label: strings.headers.organisation,
    value: 0,
    Content: <OrganisationHelpPanel />,
  },
  {
    label: strings.headers.asset,
    value: 1,
    Content: <AssetHelpPanel />,
  },
  {
    label: strings.headers.job,
    value: 2,
    Content: <JobHelpPanel />,
  },
];

export const BulkUploadHelpContent: Component = () => {
  const palette = usePalette();
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={1}
        justifyContent="flex-end"
        sx={[{ pt: 2, pr: 2 }]}
      >
        <Typography fontWeight="bolder" sx={[palette.red]}>
          {"*"}
        </Typography>
        <Typography variant="body2">
          {strings.text.mandatoryFieldIndication}
        </Typography>
      </Box>
      <TabsComponent items={tabs} />
    </>
  );
};
