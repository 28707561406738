import { Box, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { logger } from "../../helpers/log-helpers";
import { displayName } from "../../helpers/string-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useForm } from "../../hooks/general/useForm";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { api } from "../../services/organisations.service";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { Dropdown } from "../general/controls/Dropdown";

export interface Props {
  organisationId: string;
  handleClose: () => void;
  handleRefresh: () => void;
}
type Component = (props: Props) => JSX.Element;

type Dto = {
  userIds: string[];
};

const useOrganisationUsers = (organisationId: string) => {
  const cancelToken = useCancelToken();
  return [
    useCallback(
      async () =>
        api
          .getUnassignedUsersForOrganisation(organisationId, "", cancelToken)
          .then(({ item }) =>
            createDropdownOptions(item, "id", ({ firstName, lastName }) =>
              displayName(firstName, lastName)
            )
          ),
      [cancelToken, organisationId]
    ),
    cancelToken,
  ] as const;
};

export const OrganisationUserAccess: Component = ({
  organisationId,
  handleClose,
  handleRefresh,
}) => {
  const [getOrganisationUsers, cancelToken] =
    useOrganisationUsers(organisationId);
  const [formData, handleChange] = useForm<Dto>({
    userIds: [],
  });
  const handleSubmit = async () => {
    try {
      await api.addUsersToOrganisation(
        organisationId,
        formData.userIds,
        cancelToken
      );
      handleClose();
      handleRefresh();
    } catch (e) {
      logger.error(e);
    }
  };
  return (
    <Grid container spacing={3} flexDirection="column">
      <Grid item xs>
        <Typography aria-label="help text">{strings.text.helpText}</Typography>
      </Grid>
      <Grid item xs>
        <BaseControl control={false}>
          <Dropdown<Dto>
            config={{
              name: "userIds",
              label: strings.placeholders.selectUsers,
              options: getOrganisationUsers,
              value: formData.userIds,
              SelectProps: {
                multiple: true,
              },
            }}
            handleChange={handleChange}
          />
        </BaseControl>
      </Grid>
      <Grid item xs>
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="button"
            variant="outlined"
            label={strings.labels.cancel}
            onClick={handleClose}
          />
          <Button
            type="button"
            label={strings.labels.submit}
            disabled={!formData.userIds.length}
            onClick={handleSubmit}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
