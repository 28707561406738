import type { AxiosRequestConfig, CancelToken } from "axios";
import axios from "../libs/axios/axios-jobtypes";
import type { QueryFunction, ReadFunction } from "../types/API";
import type { GroupedJobType, JobType } from "../types/documents";

type FormRelationshipsResult = {
  jobs: number;
  recurrences: number;
};

const getJobTypes = async (
  jobType: string,
  cancelToken?: CancelToken
): Promise<JobType[]> => {
  let path = ``;
  if (jobType) path += `/${jobType}`;
  const { data } = await axios.get<JobType[]>(path, {
    cancelToken,
  });
  return data;
};

const query: QueryFunction<GroupedJobType> = async (_body, cancelToken?) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const { data } = await axios.get<GroupedJobType[]>("/grouped", config);
  const formattedData = data
    .map((row) => {
      // default the selected version property to the latest
      // (the highest version number)
      const selectedVersion = Math.max(
        ...row.jobTypeList.map((jobType) => jobType.version)
      );
      return {
        ...row,
        selectedVersion,
      };
    })
    .sort((a, b) => {
      const nameA: string = a.jobTypeList[0].name;
      const nameB: string = b.jobTypeList[0].name;
      return nameA.localeCompare(nameB);
    });
  return {
    items: formattedData,
    totalCount: formattedData.length,
  };
};

const exportForm = async (id: string, cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/export/${id}`;
  const { data } = await axios.get<object>(path, config);
  return data;
};

const createNewVersion = async (id: string, cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}/newversion`;
  const { data } = await axios.post<JobType>(path, {}, config);
  return data;
};

const copyForm = async (id: string, cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}/copy`;
  const { data } = await axios.post<JobType>(path, {}, config);
  return data;
};

const updatePublishStatus = async (
  id: string,
  publishValue: boolean,
  remove = false,
  cancelToken?: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}/publish/${publishValue}/remove/${remove}`;
  await axios.put(path, {}, config);
  return Promise.resolve();
};

const deleteFormVersion = async (
  id: string,
  removeJobs: boolean,
  cancelToken: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}/remove/${removeJobs}`;
  await axios.delete(path, config);
  return Promise.resolve();
};

const deleteForm = async (
  id: string,
  removeJobs: boolean,
  cancelToken: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}/deleteallversions/remove/${removeJobs}`;
  await axios.delete(path, config);
  return Promise.resolve();
};

const checkFormVersionRelationships: ReadFunction<
  FormRelationshipsResult
> = async (id, _childId, cancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}/relationships`;
  const { data } = await axios.get<FormRelationshipsResult>(path, config);
  return { item: data };
};

const checkFormRelationships: ReadFunction<FormRelationshipsResult> = async (
  id,
  _childId,
  cancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}/relationships/group`;
  const { data } = await axios.get<FormRelationshipsResult>(path, config);
  return { item: data };
};

export const api = {
  getJobTypes,
  query,
  exportForm,
  createNewVersion,
  copyForm,
  updatePublishStatus,
  deleteForm,
  deleteFormVersion,
  checkFormRelationships,
  checkFormVersionRelationships,
};
