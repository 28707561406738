import type { SxProps, Theme } from "@mui/material";
import { Box, Grid, styled } from "@mui/material";
import { Fragment, useMemo } from "react";
import { getValidationProps } from "../../helpers/control-helpers";
import type { CognitoUser, Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { BaseControl } from "./controls/BaseControl";
import { BasicCheckbox } from "./controls/BasicCheckbox";
import { Button } from "./controls/Button";
import { Checkbox } from "./controls/Checkbox";
import { DatePicker } from "./controls/DatePicker";
import { Dropdown } from "./controls/Dropdown";
import { Header } from "./controls/Header";
import { Input } from "./controls/Input";
import { Link } from "./controls/Link";
import { TimePicker } from "./controls/TimePicker";
import { Loader } from "./Loader";
import type {
  ChangeHandler,
  Checkbox as TCheckbox,
  ComponentConfiguration,
  Content,
  DatePicker as TDatePicker,
  Dropdown as TDropdown,
  FormInput,
  Mode,
  Section,
  TimePicker as TTimePicker,
} from "./types/Modify";

export interface ConfigurationProps<D extends object> {
  data: D;
  mode: Mode;
}

export interface Props<D extends object> {
  data: D;
  componentConfiguration: ComponentConfiguration<D>;
  handleChange: ChangeHandler;
  handleReset: () => void;
  mode: Mode;
  handleModeSwitch: () => void;
  validateForm: () => boolean;
  loading?: boolean;
  permissions: Permissions | null;
  setFormData: React.Dispatch<React.SetStateAction<D>>;
  validationResults: { [key: string]: string[] } | null;
  user: CognitoUser | null;
}

const Div = styled("div")({});

const rootStyles: SxProps<Theme> = {
  flexGrow: 1,
};

export const ModifyComponent = <D extends object>(
  props: Props<D>
): JSX.Element => {
  const {
    data,
    componentConfiguration: inputCompConfig,
    handleChange,
    handleReset,
    validateForm,
    loading,
    mode,
    permissions,
    validationResults,
    setFormData,
  } = props;
  const componentConfiguration =
    typeof inputCompConfig === "function"
      ? inputCompConfig({ data, mode })
      : inputCompConfig;

  const configuration = useMemo(
    () =>
      componentConfiguration.filter((input) => {
        let result = true;
        // not sure if there should be rules on these taking
        // precedence over each other (probably should be)

        if (input.modes) result = input.modes.includes(mode);
        // will be completely static, use with caution, won't be able to react
        // to state updates
        if (input.filters) result = input.filters.some((f) => f);

        return result;
      }),
    [componentConfiguration, mode]
  );

  const renderComponent = (config: Content<D>) => {
    if (config.modes) {
      const isCorrectMode = config.modes.includes(mode);
      if (!isCorrectMode) return null;
    }

    const disabled = mode === "view";
    let errorMessage = "";

    switch (config.controltype) {
      case "input":
        const inputConfig: FormInput<D> = {
          value: data[config.name],
          disabled,
          ...config,
          ...getValidationProps(config.name, validationResults),
        };

        return <Input<D> config={inputConfig} handleChange={handleChange} />;
      case "datepicker":
        const datePickerConfig: TDatePicker<D> = {
          value: data[config.name],
          disabled,
          ...config,
          ...getValidationProps(config.name, validationResults),
        };

        return (
          <DatePicker<D>
            config={datePickerConfig}
            handleChange={handleChange}
          />
        );
      case "timepicker":
        const timePickerConfig: TTimePicker<D> = {
          value: data[config.name],
          disabled,
          ...config,
          ...getValidationProps(config.name, validationResults),
        };

        return (
          <TimePicker<D>
            config={timePickerConfig}
            handleChange={handleChange}
          />
        );
      case "header":
        return <Header config={config} />;
      case "link":
        return <Link config={config} />;
      case "dropdown":
        const dropdownConfig: TDropdown<D> = {
          value: data[config.name],
          disabled,
          ...config,
          ...getValidationProps(config.name, validationResults),
        };

        return (
          <Dropdown<D> config={dropdownConfig} handleChange={handleChange} />
        );
      case "button":
        return <Button config={config} />;
      case "checkbox":
        const checkboxConfig: TCheckbox<D> = {
          disabled,
          ...config,
        };
        const validationProps = getValidationProps(
          config.name,
          validationResults
        );
        if (validationProps.helperText)
          errorMessage = validationProps.helperText;

        return (
          <Checkbox
            config={checkboxConfig}
            setFormData={setFormData}
            //@ts-ignore
            checkData={data[config.name]}
            errorMessage={errorMessage}
          />
        );
      case "basiccheckbox":
        return <BasicCheckbox<D> config={config} handleChange={handleChange} />;
      case "custom":
        const { Component } = config;
        return <Component {...props} disabled={disabled} />;
    }
  };

  const renderConfiguration = (
    { key, content, userAccess, ariaLabel }: Section<D>,
    index: number
  ) => {
    const section = (
      <Box key={key} display="flex" flexDirection="row">
        <Grid
          key={`${key}-${index}`}
          container
          role="grid"
          aria-label={ariaLabel}
        >
          {content.map((config, subIndex) => {
            let { xs = 12, md = 6 } = config;
            const layoutProps = {
              sm: config.sm,
              xl: config.xl,
              lg: config.lg,
            };
            if (config.controltype === "header") md = 12;

            return (
              <Grid
                key={`${key}-${index}-${subIndex}`}
                item
                {...layoutProps}
                xs={xs}
                md={md}
              >
                <BaseControl control={config.control}>
                  {renderComponent(config)}
                </BaseControl>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );

    return userAccess ? (
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        {section}
      </ShowIfAuthorised>
    ) : (
      <>{section}</>
    );
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    validateForm();
  };

  return (
    <Loader active={loading}>
      <Div sx={[rootStyles]}>
        <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          {configuration.map((section, index) => (
            <Fragment key={`configuration-${index}`}>
              {renderConfiguration(section, index)}
            </Fragment>
          ))}
        </form>
      </Div>
    </Loader>
  );
};
