export type TableDefinition<D extends Record<string, any>> = {
  Header: string;
  accessor: keyof D | ((json: D) => any);
};

export type Table = Array<any[]>;

export const buildTableFromJson = <D extends Record<string, any>>(
  exportData: D[],
  tableDefinition: TableDefinition<D>[]
) => {
  const table: Table = [tableDefinition.map(({ Header }) => Header)];

  for (const rowJson of exportData) {
    const rowTableData = tableDefinition.map(({ accessor }) => {
      if (typeof accessor === "function") return accessor(rowJson);
      return rowJson[accessor];
    });
    table.push(rowTableData);
  }

  return table;
};

export const openUrl = (url: string) => window.open(url, "_parent");

export const createJsonFile = (data: object) => {
  const file = new Blob([JSON.stringify(data)], { type: "application/json" });
  const fileURL = URL.createObjectURL(file);
  return fileURL;
};
