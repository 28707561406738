import type { Theme } from "@mui/material";
import { useTheme } from "@mui/material";
import type { SystemStyleObject } from "@mui/system";

export type PaletteStyle = SystemStyleObject<Theme>;

export const usePalette = () => {
  const theme = useTheme();
  return {
    primary: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    black: {
      color: theme.palette.common.black,
      borderColor: theme.palette.common.black,
    },
    white: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
    grey: {
      color: theme.palette.common.grey,
      borderColor: theme.palette.common.grey,
    },
    lightgrey: {
      color: theme.palette.common.lightgrey,
      borderColor: theme.palette.common.lightgrey,
    },
    darkgrey: {
      color: theme.palette.common.darkgrey,
      borderColor: theme.palette.common.darkgrey,
    },
    green: {
      color: theme.palette.common.green,
      borderColor: theme.palette.common.green,
    },
    red: {
      color: theme.palette.common.red,
      borderColor: theme.palette.common.red,
    },
    blue: {
      color: theme.palette.common.blue,
      borderColor: theme.palette.common.blue,
    },
    purple: {
      color: theme.palette.common.purple,
      borderColor: theme.palette.common.purple,
    },
    orange: {
      color: theme.palette.common.orange,
      borderColor: theme.palette.common.orange,
    },
    pink: {
      color: theme.palette.common.pink,
      borderColor: theme.palette.common.pink,
    },
    lightgreen: {
      color: theme.palette.common.lightgreen,
      borderColor: theme.palette.common.lightgreen,
    },
    darkgreen: {
      color: theme.palette.common.darkgreen,
      borderColor: theme.palette.common.darkgreen,
    },
  };
};
