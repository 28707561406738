import { bulkUploadStrings as strings } from "../../../resources/strings/settings/bulkUpload";
import type { Field } from "../../general/FieldDetailsDisplay";
import { FieldDetailsDisplay } from "../../general/FieldDetailsDisplay";

interface Props {}
type Component = (props: Props) => JSX.Element;

const labels = strings.fields.organisations;
const descriptions = strings.descriptions.organisations;

const fields: Array<Field> = [
  {
    key: "org",
    label: labels.organisation,
    description: descriptions.organisation,
    required: true,
  },
  {
    key: "type",
    label: labels.orgType,
    description: descriptions.orgType,
    required: true,
  },
  {
    key: "externalRef",
    label: labels.externalRef,
    description: descriptions.externalRef,
    required: false,
  },
  {
    key: "parentExternalRef",
    label: labels.parentExternalRef,
    description: descriptions.parentExternalRef,
    required: false,
  },
  {
    key: "language",
    label: labels.language,
    description: descriptions.language,
    required: false,
  },
  {
    key: "address",
    label: labels.address,
    description: descriptions.address,
    required: true,
  },
  {
    key: "country",
    label: labels.country,
    description: descriptions.country,
    required: true,
  },
  {
    key: "timezone",
    label: labels.timezone,
    description: descriptions.timezone,
    required: true,
  },
  {
    key: "contactType",
    label: labels.contactType,
    description: descriptions.contactType,
    required: false,
  },
  {
    key: "jobTitle",
    label: labels.jobTitle,
    description: descriptions.jobTitle,
    required: false,
  },
  {
    key: "title",
    label: labels.title,
    description: descriptions.title,
    required: false,
  },
  {
    key: "firstName",
    label: labels.firstName,
    description: descriptions.firstName,
    required: true,
  },
  {
    key: "lastName",
    label: labels.lastName,
    description: descriptions.lastName,
    required: true,
  },
  {
    key: "phone",
    label: labels.phone,
    description: descriptions.phone,
    required: true,
  },
  {
    key: "mobile",
    label: labels.mobile,
    description: descriptions.mobile,
    required: false,
  },
  {
    key: "email",
    label: labels.email,
    description: descriptions.email,
    required: false,
  },
];

export const OrganisationHelpPanel: Component = () => {
  return <FieldDetailsDisplay fields={fields} />;
};
