import type { CancelToken } from "axios";
import axios from "../libs/axios/axios-contacts";
import type { Contact } from "../types/documents";
import type { ContactDto, SaveContactDto } from "../types/documents/Contact";

const getContactsForOrganisation = async (
  organisationId: string,
  cancelToken?: CancelToken
) => {
  const { data } = await axios.get<Contact[]>(`/all/${organisationId}`, {
    cancelToken,
  });
  return data;
};

const createContact = async (
  organisationId: string,
  contactDto: ContactDto,
  cancelToken: CancelToken
) => {
  const body: SaveContactDto = {
    ...contactDto,
    organisationId,
  };
  await axios.post<SaveContactDto[]>("", [body], {
    cancelToken,
  });
  return Promise.resolve(true);
};

const updateContact = async (
  id: string,
  organisationId: string,
  contactDto: ContactDto,
  cancelToken: CancelToken
) => {
  const body: SaveContactDto = {
    ...contactDto,
    id,
    organisationId,
  };
  await axios.put("", body, { cancelToken });
  return Promise.resolve(true);
};

const deleteContact = async (id: string, cancelToken: CancelToken) => {
  await axios.delete(`/${id}`, { cancelToken });
  return Promise.resolve(true);
};

export const api = {
  getContactsForOrganisation,
  createContact,
  updateContact,
  deleteContact,
};
