import { FileDownloadOutlined as DownloadIcon } from "@mui/icons-material";
import type { CancelToken } from "axios";
import { useRef } from "react";
import { createJsonFile } from "../../../helpers/export-helpers";
import { logger } from "../../../helpers/log-helpers";
import { formStrings as strings } from "../../../resources/strings/forms";
import { api } from "../../../services/jobtypes.service";
import { IconAction } from "../../general/IconAction";

type ExportActionProps = {
  id: string;
  name: string;
  cancelToken: CancelToken;
};

export const ExportAction = ({ id, name, cancelToken }: ExportActionProps) => {
  const exportRef = useRef<HTMLAnchorElement>(null);

  const handleExport = async () => {
    try {
      if (!exportRef.current) return;

      const jsonData = await api.exportForm(id, cancelToken);

      const href = createJsonFile(jsonData);
      const download = `${name}.json`;

      exportRef.current.href = href;
      exportRef.current.download = download;

      exportRef.current.click();
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <>
      <IconAction tooltip={strings.labels.export} onClick={handleExport}>
        <DownloadIcon />
      </IconAction>
      <a ref={exportRef} href="/" data-testid="export-link">
        {null}
      </a>
    </>
  );
};
