export type Role = {
  id: string;
  name: string;
  isClientRole: boolean;
  roleTypeId: string;
};

export enum Roles {
  Admin = "Administrator",
  OfficeUser = "Office user",
  ClientPortalUser = "Client portal user",
  MobileUser = "Mobile user",
}
