import { User, UserManager } from "oidc-client-ts";
import { AUTH_ENDPOINT, CLIENT_ID, CLIENT_SECRET } from "./config";

export const userManager = new UserManager({
  authority: AUTH_ENDPOINT,
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
  redirect_uri: "/redirect",
  silent_redirect_uri: "/silent",
  response_type: "code",
  loadUserInfo: true,
  automaticSilentRenew: false,
  revokeTokensOnSignout: true,
});

export { User };
