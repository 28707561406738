import type { Address } from "../types/documents/Address";

export const displayAddress = (address: Address) => {
  const output = [];

  const { address1 = "", address2 = "", city = "", zip = "" } = address;
  if (address1) output.push(address1);
  if (address2) output.push(address2);
  if (city) output.push(city);
  if (zip) output.push(zip);

  return output.join(", ");
};
