import { complianceFormNames } from "../../resources/options/forms";
import { appStrings } from "../../resources/strings/app";
import type { CognitoUser, Permissions } from "../../types";
import type { JobType } from "../../types/documents";
import { JobTypeState } from "../../types/documents/JobType";
import { useIsAuthorised } from "../authentication/useIsAuthorised";
import { useLoggedInUser } from "../authentication/useLoggedInUser";

export const useFormPermissions = (
  permissions: Permissions | null,
  inputUser: CognitoUser | null,
  form: JobType
) => {
  const isReadonly = complianceFormNames.includes(form.name);
  const isNew = form.state === JobTypeState.New;
  const isUnpublished = form.state === JobTypeState.Unpublished;
  const isDeleted = form.state === JobTypeState.Deleted;

  const user = useLoggedInUser({ user: inputUser });

  let canCopyJobType = useIsAuthorised(
    permissions,
    appStrings.entities.forms,
    appStrings.permissions.copy
  );
  let canEditJobType = useIsAuthorised(
    permissions,
    appStrings.entities.forms,
    appStrings.permissions.create
  );
  let canViewJobType = !isNew;
  let canPublishJobType = useIsAuthorised(
    permissions,
    appStrings.entities.forms,
    appStrings.permissions.publish
  );
  let canViewTemplates = useIsAuthorised(
    permissions,
    appStrings.entities.formTemplates,
    appStrings.permissions.read
  );
  let canDeleteJobType = useIsAuthorised(
    permissions,
    appStrings.entities.forms,
    appStrings.permissions.delete
  );
  let canExportJobType = useIsAuthorised(
    permissions,
    appStrings.entities.forms,
    appStrings.permissions.export
  );

  if (isReadonly) {
    canCopyJobType = false;
    canPublishJobType = false;
    canViewTemplates = false;
    canDeleteJobType = false;
    canExportJobType = false;
  }
  if (!user.isVelappityUser) {
    canExportJobType = false;
  }
  if (!isNew) {
    canEditJobType = false;
  }
  if (isUnpublished) {
    canPublishJobType = false;
  }
  if (isDeleted) {
    canCopyJobType = false;
    canPublishJobType = false;
    canViewTemplates = false;
    canDeleteJobType = false;
    canExportJobType = false;
    canEditJobType = false;
    canViewJobType = false;
  }

  return {
    isReadonly,
    isNew,
    isUnpublished,
    isDeleted,
    canCopyJobType,
    canEditJobType,
    canViewJobType,
    canViewTemplates,
    canDeleteJobType,
    canExportJobType,
    canPublishJobType,
  };
};
