import { FiberManualRecord as Circle } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import type { SystemStyleObject } from "@mui/system";

interface Props {
  styles: SystemStyleObject<Theme>;
  stateName: string;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  fontSize: theme.spacing(2),
  marginRight: theme.spacing(0.5),
});

export const StateCircle: Component = ({ styles, stateName }) => {
  return (
    <>
      <Circle sx={[styles, rootStyles]} />
      {stateName}
    </>
  );
};
