import type { ValidationConstraints, ValidationResults } from "../types";
import validatejs from "../validation";

export const validate = <D extends object>(
  formData: Partial<D>,
  constraints: ValidationConstraints<D>,
  onSuccess: () => void,
  onFail: (results: ValidationResults<D>) => void
): boolean => {
  const results = validatejs(formData, constraints) ?? {};
  const isValid = !Object.keys(results).length;
  if (!isValid) {
    onFail(results);
    return false;
  }
  onSuccess();
  return true;
};
