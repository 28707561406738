import type {
  ControlTemplate,
  UpdateControlTemplateData,
} from "@pulsion/forms-designer-2";
import instance from "./axios";
import config from "./config";

export interface IControlTemplateApi {
  create(controlTemplate: ControlTemplate): Promise<any>;
  remove(id: string, removeJobs: boolean): Promise<any>;
  update(id: string, controlTemplate: UpdateControlTemplateData): Promise<any>;
  get(id: string): Promise<any>;
}

const create = async (controlTemplate: ControlTemplate): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.controlTemplate.create,
    method: "post",
    data: controlTemplate,
  });
  return res.data;
};

const get = async (id: string): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.controlTemplate.get(id),
    method: "get",
  });
  return res.data;
};

const remove = async (id: string, removeJobs: boolean): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.controlTemplate.delete(id, removeJobs),
    method: "delete",
  });
  return res.data;
};

const update = async (
  updateData: UpdateControlTemplateData,
  id: string,
  subSectionId?: string
): Promise<any> => {
  const url = config.apiEndPoints.controlTemplate.update(id, subSectionId);
  const res = await instance({
    url: url,
    method: "put",
    data: updateData,
  });
  return res.data;
};

const getNewControlId = async (): Promise<any> => crypto.randomUUID();

const getControls = async (id: string, subsectionId: string): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.controlTemplate.getControls(id, subsectionId),
    method: "get",
  });
  return res.data;
};

export { get, update, remove, create, getNewControlId, getControls };
