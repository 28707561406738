export type BulkUpload = {
  id: string;
  createdDate: string;
  failedFileLocation: string;
  createdByFullname: string;
  tenantOrganisationId: string;
  createdById: string;
  fileName: string;
  status: BulkUploadState;
  statusDescription: string;
  failedFileLocationId: string;
  bulkUploadTypeId: string;
  bulkUploadTypeName: BulkUploadType;
};

export enum BulkUploadState {
  Complete = "complete",
  InProgress = "inProgress",
  PartialSuccess = "partialSuccess",
  Failure = "failure",
}

export enum BulkUploadType {
  Organisation = "Organisation",
  Job = "Job",
  Asset = "Asset",
}
