import type { IJobRendererApi } from "@pulsion/forms-designer-2";

import { createRow, deactivateRow } from "./libs/GridService";
import {
  addRepeatableSection,
  addStoredSection,
  deleteCopyOfRepeatableSection,
  getAllJobStates,
  getControlAttachment,
  getFormListData,
  getJobData,
  getJobFormData,
  getStoredSections,
  getTemplateOnlySections,
  saveRow,
  saveRowReordering,
  updateAttachmentMetadata,
  updateSectionData,
  uploadAttachment,
} from "./libs/JobFormService";

export const jobRendererApi: IJobRendererApi = {
  addRepeatableSection,
  deleteCopyOfRepeatableSection,
  setAllowChanges: async (data: any) => {
    const allJobStates = await getAllJobStates();
    const editableJobStates = allJobStates.filter((x: any) =>
      ["New", "Rejected", "Review"].includes(x.name)
    );
    const returnObject = editableJobStates
      .map((x: any) => x.id)
      .includes(data.data.stateId);
    return returnObject;
  },
  getFormData: getJobFormData,
  getFormListData,
  getTemplateOnlySections,
  updateSectionData,
  createRow,
  deactivateRow,
  setMustBeComplete: async (data: any) => {
    const allJobStates = await getAllJobStates();
    const jobStateReview = allJobStates.find((x: any) => x.name === "Review");
    return jobStateReview.id === data.stateId;
  },
  getJobData,
  getControlAttachment,
  updateAttachmentMetadata,
  uploadAttachment,
  saveRow,
  saveRowReordering,
  getStoredSections,
  addStoredSection,
};
