import { createCtx } from "../hooks/createCtx";
import type { Permissions } from "../types";

export type MenuContext = {
  permissions: Permissions | null;
  open: boolean;
  selected: string[];
  handleSelect: (newSelected: string, parent?: string) => void;
};

export const [useMenuContext, MenuContextProvider] = createCtx<MenuContext>();
