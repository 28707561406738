import type { DropdownOptions } from "../../types";
import { formStrings as strings } from "../strings/forms";

const { formGroups } = strings.labels;

export const formGroupOptions: DropdownOptions = [
  {
    label: formGroups.published,
    value: "published",
  },
  {
    label: formGroups.new,
    value: "new",
  },
  {
    label: formGroups.unpublished,
    value: "unpublished",
  },
];

export const complianceFormNames = [
  "Compliance Form",
  "Compliance Form (Non-AutoComplete)",
];
