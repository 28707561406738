import { ThemeProvider as MuiV4ThemeProvider } from "@material-ui/core";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { AdapterLuxon as Adapter } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContextProvider } from "../context/AuthContext";
import { theme } from "../styles/theme";
import type { Permissions } from "../types";

import { setLogLevel } from "../helpers/log-helpers";
import { LOG_LEVEL } from "../libs/config";

import "react-notifications/lib/notifications.css";

const locale = DateTime.local().locale;

const initialState = {
  loggedIn: false,
  userPermissions: {},
};

setLogLevel(LOG_LEVEL);

// component for Router, store, providers etc.
// will be used for main app as well as any unit tests
export const Wrappers: React.FC<{ state?: typeof initialState }> = ({
  children,
  state = initialState,
}) => {
  const [loggedIn, setLoggedIn] = useState(state.loggedIn);
  const [userPermissions, setUserPermissions] = useState<Permissions>(
    state.userPermissions
  );
  const handleSignIn = useCallback(() => setLoggedIn(true), []);
  const handleSignOut = useCallback(() => setLoggedIn(false), []);

  const authContext = {
    loggedIn,
    handleSignIn,
    handleSignOut,
    userPermissions,
    setUserPermissions,
  };

  return (
    <Router>
      <NotificationContainer />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthContextProvider value={authContext}>
            <LocalizationProvider dateAdapter={Adapter} adapterLocale={locale}>
              <MuiV4ThemeProvider theme={theme}>{children}</MuiV4ThemeProvider>
            </LocalizationProvider>
          </AuthContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
};

export const DefaultWrappers: React.FC = ({ children }) => (
  <Wrappers>{children}</Wrappers>
);
