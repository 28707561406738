import { Box } from "@mui/material";
import { useMemo } from "react";
import { ShowIfAuthorised } from "../../components/authentication/ShowIfAuthorised";
import { ContactListActions } from "../../components/contacts/ContactListActions";
import { Button } from "../../components/general/Button";
import { BasicCheckbox } from "../../components/general/controls/BasicCheckbox";
import { Modal } from "../../components/general/Modal";
import type {
  ChangeHandler,
  CustomProps,
} from "../../components/general/types/Modify";
import { findValue } from "../../helpers/dropdown-helpers";
import { displayName } from "../../helpers/string-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useContactTypes } from "../../hooks/organisations/useContactTypes";
import { appStrings } from "../../resources/strings/app";
import { contactStrings as strings } from "../../resources/strings/contacts";
import { api as contactsApi } from "../../services/contacts.service";
import { api as organisationsApi } from "../../services/organisations.service";
import { QueryFunction } from "../../types/API";
import { ContactDto } from "../../types/documents/Contact";
import { OrganisationDto } from "../../types/documents/Organisation";
import { TableContainer } from "../general/TableContainer";
import { ContactModify } from "./ContactModify";

export interface Props extends CustomProps<OrganisationDto> {
  isParentOrganisation: boolean;
}
type Component = (props: Props) => JSX.Element;

type CreateContactActionProps = Pick<Props, "permissions" | "user"> & {
  handleNewContact: (dto: ContactDto) => void;
};

const CreateContactAction = ({
  permissions,
  handleNewContact,
  user,
}: CreateContactActionProps) => {
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      justifyContent="flex-end"
    >
      <ShowIfAuthorised
        userPermissions={permissions}
        entity={appStrings.entities.organisations}
        permission={[
          appStrings.permissions.create,
          appStrings.permissions.editTenant,
        ]}
      >
        <Modal
          trigger={<Button label={strings.labels.newContact} />}
          header={strings.headers.newContact}
          actions={() => []}
        >
          {({ onClose }) => (
            <ContactModify
              permissions={permissions}
              user={user}
              handleContactChange={handleNewContact}
              handleClose={onClose}
            />
          )}
        </Modal>
      </ShowIfAuthorised>
    </Box>
  );
};

export const ContactList: Component = ({
  permissions,
  user,
  data: { id: organisationId, contacts = [], defaultContactId },
  setFormData,
  mode,
  isParentOrganisation,
}) => {
  const [contactTypeOptions, contactTypesLoading] = useContactTypes();
  const cancelToken = useCancelToken();

  const api = useMemo<{ query: QueryFunction<ContactDto> }>(
    () => ({
      query: async () => ({ items: contacts, totalCount: contacts.length }),
    }),
    [contacts]
  );

  const handleNewContact = (contactDto: ContactDto) => {
    setFormData((prev) => ({
      ...prev,
      contacts: [...prev.contacts, contactDto],
    }));

    // if updating the parent organisation, need to save new contacts manually.
    // in create mode, contacts are sent in the organisation payload
    if (mode === "update") {
      contactsApi
        .createContact(organisationId, contactDto, cancelToken)
        .catch(() => {});
    }
  };

  const handleDeleteContact = (id: string) => {
    setFormData((prev) => ({
      ...prev,
      contacts: prev.contacts.filter((contact) => contact.id !== id),
    }));

    // if updating the parent organisation, need to save new contacts manually.
    // in create mode, contacts are sent in the organisation payload
    if (mode === "update") {
      contactsApi.deleteContact(id, cancelToken).catch(() => {});
    }
  };

  const handleEditContact = (id: string, contactDto: ContactDto) => {
    setFormData((prev) => ({
      ...prev,
      contacts: prev.contacts.map((contact) => {
        if (contact.id !== id) return contact;
        return contactDto;
      }),
    }));

    // if updating the parent organisation, need to save updated contacts manually.
    // in create mode, contacts are sent in the organisation payload
    if (mode === "update") {
      contactsApi
        .updateContact(id, organisationId, contactDto, cancelToken)
        .catch(() => {});
    }
  };

  const handleDefaultContactChange =
    (id: string, contactDto: ContactDto): ChangeHandler =>
    (e) => {
      const checked = e.target.checked ?? false;
      const contactId = checked ? id : null;
      const updatedContactDto: ContactDto = {
        ...contactDto,
        default: checked,
      };
      handleEditContact(id, updatedContactDto);
      setFormData((prev) => ({
        ...prev,
        defaultContactId: contactId,
      }));

      if (mode === "update") {
        organisationsApi
          .setDefaultContact(organisationId, contactId, cancelToken)
          .catch(() => {});
      }
    };

  return (
    <>
      <TableContainer<ContactDto>
        permissions={permissions}
        user={user}
        api={api}
        tableColumns={[
          {
            id: "defaultContactId",
            Header: strings.headers.defaultContact,
            hidden: isParentOrganisation,
            accessor: (dto) => (
              <BasicCheckbox<ContactDto>
                config={{
                  name: "default",
                  checked: defaultContactId === dto.id,
                  size: "small",
                  compact: true,
                }}
                handleChange={handleDefaultContactChange(dto.id, dto)}
              />
            ),
          },
          {
            id: "name",
            Header: strings.headers.name,
            accessor: ({ firstName, lastName }) =>
              displayName(firstName, lastName),
          },
          {
            id: "contactType",
            Header: strings.headers.contactType,
            accessor: ({ contactTypeId }) =>
              findValue(contactTypeId, contactTypeOptions),
          },
          {
            id: "action",
            Header: strings.headers.action,
            accessor: (data) => (
              <ContactListActions
                user={user}
                permissions={permissions}
                data={data}
                handleDeleteContact={handleDeleteContact}
                handleEditContact={handleEditContact}
              />
            ),
            maxWidth: 50,
          },
        ]}
        inlineLoader
        topBorder
        pageSize={10}
        loading={contactTypesLoading}
        children={
          <CreateContactAction
            permissions={permissions}
            user={user}
            handleNewContact={handleNewContact}
          />
        }
      />
    </>
  );
};
