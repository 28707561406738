import { DeleteFormAction } from "../../components/forms/actions/DeleteFormAction";
import { SelectVersionAction } from "../../components/forms/actions/SelectVersionAction";
import { FormListActions } from "../../components/forms/FormListActions";
import { FormListButtons } from "../../components/forms/FormListButtons";
import { FormSearchBar } from "../../components/forms/FormSearchBar";
import { getSelectedFormVersion } from "../../helpers/jobtype-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import { formStrings as strings } from "../../resources/strings/forms";
import { api } from "../../services/jobtypes.service";
import type { FilterChangeHandler, RouteProps } from "../../types";
import type { GroupedJobType, JobType } from "../../types/documents";
import type { JobTypeFilters } from "../../types/documents/JobType";
import { JobTypeState } from "../../types/documents/JobType";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const hasPublishedVersion = (versions: JobType[]) => {
  const publishedVersion = versions.find(
    (version) => version.state === JobTypeState.Published
  );
  return Boolean(publishedVersion);
};

export const FormList: Component = ({ ...routeProps }) => {
  const { user, permissions } = routeProps;
  const cancelToken = useCancelToken();

  const [filters, setFilters] = useSessionStorage<JobTypeFilters>(
    {},
    "jobTypeFilters"
  );

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  return (
    <div>
      <TableContainer<GroupedJobType>
        {...routeProps}
        api={api}
        header={strings.header.formsList}
        headerContent={<FormListButtons permissions={permissions} />}
        tableColumns={({ setTableData }) => {
          const updateRow = (
            id: string,
            updateObject: Partial<GroupedJobType>
          ) =>
            setTableData((tableData) =>
              tableData.map((current) => {
                if (id !== current.jobTypeGroupId) {
                  return current;
                }
                return { ...current, ...updateObject };
              })
            );

          const removeFormRow = (id: string) =>
            setTableData((tableData) =>
              tableData.filter((row) => row.jobTypeGroupId !== id)
            );

          return [
            {
              id: "name",
              Header: strings.labels.name,
              accessor: ({ jobTypeList = [], selectedVersion }) => {
                const jobType = getSelectedFormVersion(
                  jobTypeList,
                  selectedVersion
                );
                return jobType?.name;
              },
            },
            {
              id: "version",
              Header: strings.labels.version,
              accessor: (groupedForm) => {
                const handleChange = (newValue: number) =>
                  updateRow(groupedForm.jobTypeGroupId, {
                    selectedVersion: newValue,
                  });
                return (
                  <SelectVersionAction
                    groupedForm={groupedForm}
                    handleChange={handleChange}
                  />
                );
              },
              width: 40,
            },
            {
              id: "state",
              Header: strings.labels.state,
              accessor: ({ jobTypeList = [], selectedVersion }) => {
                const jobType = getSelectedFormVersion(
                  jobTypeList,
                  selectedVersion
                );
                let state = "";
                if (jobType?.state) state = jobType.state;
                return `${state.charAt(0).toUpperCase()}${state.slice(1)}`;
              },
              width: 40,
            },
            {
              id: "actons",
              Header: strings.labels.actions,
              disableSortBy: true,
              accessor: ({
                jobTypeList = [],
                selectedVersion,
                jobTypeGroupId,
              }) => {
                const form = getSelectedFormVersion(
                  jobTypeList,
                  selectedVersion
                );
                if (!form) return <></>;
                const hasPublished = hasPublishedVersion(jobTypeList);

                const handleChange = (newState: JobTypeState) => {
                  updateRow(jobTypeGroupId, {
                    jobTypeList: jobTypeList.map((current) => {
                      if (
                        newState === JobTypeState.Published &&
                        current.state === JobTypeState.Published
                      ) {
                        return { ...current, state: JobTypeState.Unpublished };
                      }
                      if (current.id !== form.id) return current;
                      return { ...current, state: newState };
                    }),
                  });
                };
                return (
                  <FormListActions
                    permissions={permissions}
                    user={user}
                    form={form}
                    hasPublishedVersion={hasPublished}
                    handleChange={handleChange}
                    cancelToken={cancelToken}
                  />
                );
              },
            },
            {
              id: "deleteForm",
              Header: strings.labels.deleteForm,
              disableSortBy: true,
              accessor: ({
                jobTypeList = [],
                selectedVersion,
                jobTypeGroupId,
              }) => {
                const form = getSelectedFormVersion(
                  jobTypeList,
                  selectedVersion
                );
                const handleRemove = () => removeFormRow(jobTypeGroupId);
                if (!form) return <></>;
                return (
                  <DeleteFormAction
                    form={form}
                    permissions={permissions}
                    user={user}
                    cancelToken={cancelToken}
                    formGroupId={jobTypeGroupId}
                    handleRemoveRow={handleRemove}
                  />
                );
              },
              width: 30,
            },
          ];
        }}
        children={
          <FormSearchBar
            filters={filters}
            handleFilterChange={handleFilterChange}
            clearFilters={clearFilters}
          />
        }
        filters={filters as any}
        manualFiltering={false}
      />
    </div>
  );
};
