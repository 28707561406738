import { useMemo } from "react";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { api } from "../../services/contacttypes.service";
import { useReadQuery } from "../general/useReadQuery";

export const useContactTypes = () => {
  const [contactTypes, loading] = useReadQuery([], api.getContactTypes, "id");
  const options = useMemo(
    () => createDropdownOptions(contactTypes, "id", "name"),
    [contactTypes]
  );
  return [options, loading] as const;
};
