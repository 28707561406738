import jwt_decode from "jwt-decode";
import { logger } from "../helpers/log-helpers";
import type { RefreshedToken } from "./identity-server";
import * as auth from "./identity-server";
import { User, userManager } from "./oidc";

type IdToken = {
  iss: string;
  aud: string;
  exp: number;
  nbf: number;
  iat: number;
  at_hash: string;
  sub: string;
  auth_time: number;
  idp: string;
  preferred_username: string;
  email: string;
  email_verified: string;
  role: string;
  name: string;
  given_name: string;
  family_name: string;
  zoneinfo: string;
  locale: string;
  SecurityStamp: string;
  IsComplianceUser: string;
  IsFormsUser: string;
  IsVelappityUser: string;
  HasOverduePayment: string;
  TrialPaymentReminder: string;
  amr: string[];
};

export const storeToken = async ({
  access_token,
  refresh_token,
  token_type,
  id_token,
}: RefreshedToken) => {
  const { sub, aud, exp, iat, iss } = jwt_decode<IdToken>(access_token);
  const user = new User({
    id_token,
    access_token,
    refresh_token,
    token_type,
    profile: {
      sub,
      aud,
      exp,
      iat,
      iss,
    },
    expires_at: exp,
  });
  await userManager.storeUser(user);
  return Promise.resolve(user);
};

export const getIdToken = async () => {
  const user = await getUser();
  if (!user.id_token) return Promise.reject("No user token");
  const decodedToken = jwt_decode<IdToken>(user.id_token);
  return Promise.resolve(decodedToken);
};

export const getAccessToken = async () => {
  const { expired, refresh_token, ...user } = await getUser();
  let { access_token } = user;
  // if the token has expired, fetch a new one
  // (provided a refresh token is present)
  if (expired && refresh_token) {
    logger.debug("refreshing token");
    // get the new token
    const newToken = await auth.refreshToken(refresh_token);
    // store it using the user manager
    await storeToken(newToken);
    // update the access token variable to ensure the latest one is used in any
    // http requests
    access_token = newToken.access_token;
  }

  return Promise.resolve(access_token);
};

const getUser = async () =>
  userManager
    .getUser()
    .then((user) => (user ? Promise.resolve(user) : Promise.reject("No user")));

export const removeToken = async () => userManager.removeUser();
