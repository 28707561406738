import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Grid,
  Paper,
  styled,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import {
  Row,
  SortingRule,
  TableOptions,
  useFilters,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Permissions, UserAccess } from "../../types";
import { Loader } from "../general/Loader";
import { BreadcrumbHeader, type Crumb } from "./BreadcrumbHeader";
import { CreateEntityButton } from "./CreateEntityButton";
import { TablePaginationActions } from "./TablePaginationActions";

/*
  The react table docs are useless when it comes to typescript

  Some useful resources:
    - Type declarations: 
        https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
    - For future projects, pay particular attention to the configuration 
      section of the readme in the above repo
    - React table example written in TypeScript from one of the React Table maintainers
        https://github.com/ggascoigne/react-table-example
*/

type TableEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | null;

type TableStateProps = {
  totalCount?: number;
  page?: number;
  size?: number;
};
export type HeaderContent =
  | JSX.Element
  | ((tableProps: TableStateProps) => JSX.Element);

export interface Props<D extends Record<string, unknown>>
  extends TableOptions<D> {
  header?: string;
  headerContent?: HeaderContent;
  totalCount?: number;
  size?: number;
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
    state?: object;
  };
  permissions: Permissions | null;
  loading?: boolean;
  clickHandler?: (data: D) => void;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  onSortChange: (sort: SortingRule<D>[]) => void;
  sortObject: SortingRule<D>[];
  children?: JSX.Element | Array<JSX.Element>;
  inlineLoader?: boolean;
  topBorder?: boolean;
  filters?: D;
  crumbs?: Crumb[];
}

const Div = styled("div")({});

const rootStyles: SxProps<Theme> = {
  boxShadow: "none",
  borderRadius: 0,
};

const bodyStyles: SxProps<Theme> = (theme) => ({
  "&:nth-of-type(even)": {
    background: theme.palette.common.lightgrey,
  },
});

const clickStyles: SxProps<Theme> = {
  "&:hover": {
    cursor: "pointer",
  },
};

const headStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.lightgrey,
  color: theme.palette.common.darkgrey,
  fontWeight: "bolder",
});

const borderStyles: SxProps<Theme> = (theme) => ({
  borderTop: theme.spacing(0.1, "solid", theme.palette.common.grey!),
});

const childrenStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
});

export const Table = <T extends Record<string, unknown>>({
  header,
  data: tableData,
  columns: tableColumns,
  totalCount = tableData.length,
  size = 10,
  createButtonConfig,
  permissions,
  loading,
  clickHandler,
  manualPagination,
  onPageChange,
  onSizeChange,
  onSortChange,
  sortObject,
  children,
  headerContent,
  inlineLoader,
  topBorder = false,
  filters,
  crumbs = [],
}: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const sort = useMemo(() => sortObject, [sortObject]);
  const pageCount = useMemo(
    () => Math.ceil(totalCount / size),
    [totalCount, size]
  );
  const filterArray = useMemo(
    () =>
      Object.entries(filters ?? {})
        // filter out any entries which have no associated column
        .filter(([id]) =>
          columns.find((column) => column.id === id || column.accessor === id)
        )
        // map that result into an array of objects which is the expected input for react table
        .map(([id, value]) => ({
          id,
          value,
        })),
    [filters, columns]
  );

  const hooks = [useFilters, useSortBy, usePagination, useFlexLayout];

  const instance = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: size,
        pageIndex: 0,
        sortBy: sort,
        filters: filterArray,
      },
      manualPagination,
      manualSortBy: manualPagination,
      pageCount,
      // this flag prevents weird behaviour when changing page
      // like fetching the next page then re-fetching the previous page immediately
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      disableMultiSort: true,
    },
    ...hooks
  );

  // NOTE: if a new hook plugin is used, it may be necessary to update
  // `types/react-table-config.d.ts` to get the correct types
  // See `TableOptions` interface
  const {
    // basic table props
    getTableProps,
    getTableBodyProps,
    headerGroups = [],
    prepareRow,
    state: { pageIndex, pageSize: tablePageSize, sortBy },
    // pagination props
    page: pageRows = [],
    gotoPage,
    setAllFilters,
    rows: allRows = [],
  } = instance;

  useEffect(() => {
    if (pageIndex >= pageCount) gotoPage(0);
  }, [pageCount, pageIndex, gotoPage]);

  // apply any filter updates when manual pagination is not set
  useEffect(() => {
    if (manualPagination) return;
    setAllFilters(filterArray);
    gotoPage(0);
  }, [filterArray, setAllFilters, manualPagination, gotoPage]);

  // If manual pagination is set, we want to forward any changes in table state
  // to trigger a new data fetch
  useEffect(() => {
    if (manualPagination) {
      onPageChange(pageIndex);
      onSizeChange(tablePageSize);
      onSortChange(sortBy);
    }
  }, [
    manualPagination,
    pageIndex,
    tablePageSize,
    sortBy,
    onPageChange,
    onSizeChange,
    onSortChange,
  ]);

  const handleChangePage = (_event: TableEvent, newPage: number) => {
    gotoPage(newPage);
  };

  const renderHeaderContent = () => {
    if (headerContent) {
      if (typeof headerContent === "function") {
        return headerContent({
          totalCount,
          page: pageIndex,
          size: tablePageSize,
        });
      } else {
        return headerContent;
      }
    }
    return renderCreateButton();
  };

  const renderCreateButton = () => {
    if (!createButtonConfig) return <></>;
    return (
      <CreateEntityButton
        config={createButtonConfig}
        permissions={permissions}
      />
    );
  };

  const renderHeader = () => {
    if (!header) return;
    return (
      <BreadcrumbHeader
        crumbs={[...crumbs, { text: header }]}
        children={renderHeaderContent()}
      />
    );
  };

  const renderTableChildren = () => {
    if (!children) return null;
    return (
      <Div data-testid="table-children" sx={[childrenStyles]}>
        <Grid container>{children}</Grid>
      </Div>
    );
  };

  const handleClick = (row: Row<T>) => {
    if (clickHandler) clickHandler(row.original);
  };

  const renderTableHead = () => (
    <TableHead component="span">
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()} component="span">
          {headerGroup.headers.map((column) => (
            <TableCell
              {...column.getHeaderProps(column.getSortByToggleProps())}
              component="span"
              // override the default onClick method as it sometimes leads to an empty
              // "sortBy" array.
              // from what i have found, this is not related to the usage in this project but
              // is also present in one of the examples from the react table docs.
              onClick={() => column.toggleSortBy(!column.isSortedDesc)}
              sx={[headStyles, (children || topBorder) && borderStyles]}
            >
              {column.render("Header")}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );

  const renderTableBody = () => {
    if (loading && inlineLoader) return <Loader active inline />;

    return (
      <TableBody {...getTableBodyProps()} component="span">
        {pageRows.map((row) => {
          prepareRow(row);
          const isClickable = Boolean(clickHandler);
          return (
            <TableRow
              {...row.getRowProps()}
              onClick={() => handleClick(row)}
              hover={isClickable}
              sx={[bodyStyles, isClickable && clickStyles]}
              component="span"
            >
              {row.cells.map((cell) => (
                <TableCell {...cell.getCellProps()} component="span">
                  <Typography noWrap variant="body2">
                    {cell.value ? cell.render("Cell") : "-"}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const renderTablePagination = () => {
    const currentCount = manualPagination ? totalCount : allRows.length;
    return (
      <TablePagination
        role="navigation"
        component="div"
        page={pageIndex}
        count={currentCount}
        rowsPerPageOptions={[]}
        rowsPerPage={tablePageSize}
        onPageChange={handleChangePage}
        SelectProps={{ title: "page options" }}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} to ${to} of ${count}`
        }
        ActionsComponent={TablePaginationActions}
      />
    );
  };

  const renderTable = () => {
    return (
      <>
        {renderTableChildren()}
        <TableContainer component={Paper} sx={[rootStyles]}>
          <TableComponent {...getTableProps()} component="span">
            {renderTableHead()}
            {renderTableBody()}
          </TableComponent>
        </TableContainer>
        {renderTablePagination()}
      </>
    );
  };

  return (
    <Box role="grid" display="flex" flexDirection="column">
      {renderHeader()}
      <Loader active={loading && !inlineLoader}>{renderTable()}</Loader>
    </Box>
  );
};
