import { Typography } from "@mui/material";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../components/authentication/PrivateRoute";
import { CompletePasswordReset } from "../containers/authentication/CompletePasswordReset";
import { CustomLogin as Login } from "../containers/authentication/CustomLogin";
import { PasswordReset } from "../containers/authentication/PasswordReset";
import { SignUp } from "../containers/authentication/SignUp";
import { ClientList } from "../containers/clients/ClientList";
import { ClientModify } from "../containers/clients/ClientModify";
import { ClientUserList } from "../containers/clients/ClientUserList";
import { ClientUserModify } from "../containers/clients/ClientUserModify";
import { FormList } from "../containers/forms/FormList";
import { FormDesigner } from "../containers/formsDesigner/FormDesigner";
import { JobRenderer } from "../containers/formsDesigner/JobRenderer";
import { JobList } from "../containers/jobs/JobList";
import { JobModify } from "../containers/jobs/JobModify";
import { BulkUploadList } from "../containers/settings/BulkUploadList";
import { SiteList } from "../containers/sites/SiteList";
import { SiteModify } from "../containers/sites/SiteModify";
import { SiteUserList } from "../containers/sites/SiteUserList";
import { SiteUserModify } from "../containers/sites/SiteUserModify";
import { TenantUserList } from "../containers/tenants/TenantUserList";
import { TenantUserModify } from "../containers/tenants/TenantUserModify";
import { appStrings as strings } from "../resources/strings/app";
import type { FullRouteProps } from "../types";
import { paths } from "./paths";

type Component = (props: FullRouteProps) => JSX.Element;
type TRoutes = JSX.Element[];

export const Routes: Component = (props) => {
  const appRoutes: TRoutes = [
    <Route key="login" exact path={paths.auth.login}>
      <Login user={props.user} />
    </Route>,
    <Route
      key="completePasswordReset"
      exact
      path={paths.auth.completePasswordReset}
    >
      <CompletePasswordReset user={props.user} />
    </Route>,
    <Route
      key="requestPasswordReset"
      exact
      path={paths.auth.requestPasswordReset}
    >
      <PasswordReset user={props.user} canCompletePassword={false} />
    </Route>,
    <Route key="signUp" exact path={paths.auth.signUp}>
      <SignUp user={props.user} />
    </Route>,
    <Route key="dashboard" exact path={paths.dashboard}>
      <></>
    </Route>,
    <Route key="root" exact path={paths.root}>
      <Redirect to={paths.jobs.list()} />
    </Route>,
  ];
  const jobRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="siteVisitJobResults"
      exact
      path={paths.jobs.siteVisitResults(":id")}
      userAccess={{
        entity: strings.entities.jobs,
        permission: strings.permissions.read,
      }}
    >
      <Typography>Site Visit Results - Job</Typography>
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="jobsEdit"
      exact
      path={paths.jobs.edit(":id")}
      userAccess={{
        entity: strings.entities.jobs,
        permission: strings.permissions.read,
      }}
    >
      <JobModify {...props} mode="update" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="jobSections"
      exact
      path={paths.jobs.sections(":id")}
      userAccess={{
        entity: strings.entities.jobs,
        permission: strings.permissions.read,
      }}
    >
      <JobRenderer />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="jobsCreate"
      exact
      path={paths.jobs.create}
      userAccess={{
        entity: strings.entities.jobs,
        permission: [
          strings.permissions.createAdhocJob,
          strings.permissions.createForAnyUser,
        ],
      }}
    >
      <JobModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="jobsList"
      exact
      path={paths.jobs.list(":orgId?")}
      userAccess={{
        entity: strings.entities.jobs,
        permission: strings.permissions.read,
      }}
    >
      <JobList {...props} />
    </PrivateRoute>,
  ];
  const formRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="formTemplates"
      exact
      path={paths.forms.templates(":id")}
      userAccess={{
        entity: strings.entities.formTemplates,
        permission: strings.permissions.read,
      }}
    >
      <Typography>Document templates</Typography>
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="formsEdit"
      exact
      path={paths.forms.edit(":id")}
      userAccess={{
        entity: strings.entities.forms,
        permission: strings.permissions.read,
      }}
    >
      <FormDesigner />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="formsCreate"
      exact
      path={paths.forms.create}
      userAccess={{
        entity: strings.entities.forms,
        permission: strings.permissions.create,
      }}
    >
      <FormDesigner />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="formsList"
      exact
      path={paths.forms.list}
      userAccess={{
        entity: strings.entities.forms,
        permission: strings.permissions.read,
      }}
    >
      <FormList {...props} />
    </PrivateRoute>,
  ];

  const settingRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="bulkUploadList"
      exact
      path={paths.settings.bulkUpload}
      userAccess={{
        entity: strings.entities.bulkUpload,
        permission: strings.permissions.create,
      }}
    >
      <BulkUploadList {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="payments"
      exact
      path={paths.settings.payments}
      userAccess={{
        entity: strings.entities.settings,
      }}
    >
      <Typography>Payments</Typography>
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="subscriptions"
      exact
      path={paths.settings.subscription}
      userAccess={{
        entity: strings.entities.settings,
      }}
    >
      <Typography>Subscription</Typography>
    </PrivateRoute>,
  ];

  const siteRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="siteCreate"
      exact
      path={paths.sites.create}
      userAccess={{
        entity: strings.entities.organisations,
        permission: strings.permissions.create,
      }}
    >
      <SiteModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="siteList"
      exact
      path={paths.sites.list(":orgId?")}
      userAccess={{
        entity: strings.entities.organisations,
        permission: strings.permissions.read,
      }}
    >
      <SiteList {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="siteEdit"
      exact
      path={paths.sites.view(":id")}
      userAccess={{
        entity: strings.entities.organisations,
        permission: strings.permissions.editTenant,
      }}
    >
      <SiteModify {...props} mode="update" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="siteUsersView"
      exact
      path={paths.sites.users.view(":organisationId", ":id")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <SiteUserModify {...props} mode="update" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="siteUsersCreate"
      exact
      path={paths.sites.users.create(":organisationId")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <SiteUserModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="siteUsersList"
      exact
      path={paths.sites.users.list(":id")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.read,
      }}
    >
      <SiteUserList {...props} />
    </PrivateRoute>,
  ];

  const clientRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="clientList"
      exact
      path={paths.clients.list}
      userAccess={{
        entity: strings.entities.organisations,
        permission: strings.permissions.read,
      }}
    >
      <ClientList {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="clientCreate"
      exact
      path={paths.clients.create}
      userAccess={{
        entity: strings.entities.organisations,
        permission: strings.permissions.create,
      }}
    >
      <ClientModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="clientEdit"
      exact
      path={paths.clients.view(":id")}
      userAccess={{
        entity: strings.entities.organisations,
        permission: strings.permissions.editTenant,
      }}
    >
      <ClientModify {...props} mode="update" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="clientUsersView"
      exact
      path={paths.clients.users.view(":organisationId", ":id")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <ClientUserModify {...props} mode="update" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="clientUsersCreate"
      exact
      path={paths.clients.users.create(":organisationId")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <ClientUserModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="clientUsersList"
      exact
      path={paths.clients.users.list(":id")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.read,
      }}
    >
      <ClientUserList {...props} />
    </PrivateRoute>,
  ];

  const tenantRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="tenantUsersView"
      exact
      path={paths.tenants.users.view("", ":id")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <TenantUserModify {...props} mode="update" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="tenantUsersCreate"
      exact
      path={paths.tenants.users.create()}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <TenantUserModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="tenantUsersList"
      exact
      path={paths.tenants.users.list()}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.read,
      }}
    >
      <TenantUserList {...props} />
    </PrivateRoute>,
  ];

  return (
    <Switch>
      {appRoutes}
      {jobRoutes}
      {formRoutes}
      {settingRoutes}
      {siteRoutes}
      {clientRoutes}
      {tenantRoutes}
      <PrivateRoute
        {...props}
        exact
        path={paths.assets.list}
        userAccess={{
          entity: strings.entities.assets,
          permission: strings.permissions.read,
        }}
      >
        <Typography>Assets</Typography>
      </PrivateRoute>
      <PrivateRoute
        {...props}
        exact
        path={paths.issues.list}
        userAccess={{
          entity: strings.entities.issues,
          permission: strings.permissions.read,
        }}
      >
        <Typography>Issues</Typography>
      </PrivateRoute>
      <Route key="notFound">
        <Typography>page not found</Typography>
      </Route>
    </Switch>
  );
};
