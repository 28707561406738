import type { FormTemplate, FormTemplateV2 } from "@pulsion/forms-designer-2";
import { JobType } from "../../../types/documents";
import instance from "./axios";
import config from "./config";

export interface IFormTemplateApi {
  create(formTemplate: FormTemplate): Promise<any>;
  remove(id: string, removeJobs: boolean): Promise<any>;
  update(id: string, formTemplate: FormTemplate): Promise<any>;
  get(id: string, formTemplate: JobType): Promise<JobType>;
}

const create = async <D>(formTemplate: FormTemplateV2 & D): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.formTemplate.create,
    method: "post",
    data: formTemplate,
  });
  return res.data;
};

const get = async <D>(id: string): Promise<FormTemplateV2 & D> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.get(id),
    "Form template not found"
  );
};

const remove = async (id: string, removeJobs: boolean): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.formTemplate.delete(id, removeJobs),
    method: "delete",
  });
  return res.data;
};

const update = async <D>(
  id: string,
  formTemplate: FormTemplateV2 & D
): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.formTemplate.update(id),
    method: "put",
    data: formTemplate,
  });
  return res.data;
};

const getFormSectionNames = async (id: string): Promise<any> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.getwithSectionNames(id),
    "Sections not found"
  );
};

const getFormSection = async (id: string): Promise<any> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.getFormSection(id),
    "Section not found"
  );
};

const getFormSectionDetails = async (id: string): Promise<any> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.getFormSectionDetails(id),
    "Section not found"
  );
};

const getFormSubSection = async (id: string): Promise<any> => {
  const res = await instance({
    url: config.apiEndPoints.formTemplate.getFormSubSection(id),
    method: "get",
  });
  return res.data;
};

const axiosGet = async (url: string, _error: string): Promise<any> => {
  const res = await instance({
    url: url,
    method: "get",
  });
  return res.data;
};

export {
  get,
  update,
  remove,
  create,
  getFormSectionNames,
  getFormSection,
  getFormSectionDetails,
  getFormSubSection,
};
