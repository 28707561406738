import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export const UpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M15.9179 13.5256L12.0744 9.6826C12.0196 9.6277 11.9563 9.6 11.8846 9.6C11.8134 9.6 11.7502 9.6277 11.6948 9.6826L7.8517 13.5256C7.797 13.5809 7.7692 13.6442 7.7692 13.7154C7.7692 13.7871 7.797 13.8503 7.8517 13.9052L8.264 14.3174C8.3189 14.3723 8.3822 14.4 8.4538 14.4C8.525 14.4 8.5883 14.3723 8.6436 14.3174L11.8846 11.0764L15.1262 14.3174C15.1809 14.3728 15.2442 14.4 15.3154 14.4C15.3871 14.4 15.4503 14.3723 15.5052 14.3174L15.9179 13.9052C15.9728 13.8503 16 13.7871 16 13.7154C16 13.6442 15.9728 13.5809 15.9179 13.5256Z" />
    </SvgIcon>
  );
};
