import { JobRendererTheme } from "@pulsion/forms-designer-2";
import { theme } from "../../../styles/theme";

export const jobRendererThemes: JobRendererTheme = {
  colors: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    modalHeader: `${theme.palette.common.darkgrey}`,
  },
  fonts: [`${theme.typography.fontFamily}`],
  font: {
    primary: {
      family: theme.typography.fontFamily,
      color: theme.palette.text.primary,
    },
    secondary: {
      family: `${theme.typography.fontFamily}`,
      color: theme.palette.text.secondary,
      size: "15px",
    },
  },
  button: {
    fontSize: "16px",
  },
  table: {
    marginBottom: "1.5rem",
    header: {
      color: theme.palette.common.lightgrey,
      justification: "space-between",
      font: theme.typography.fontFamily,
      fontSize: "2.2rem",
      padding: "0.5rem",
    },
    headerRow: {
      font: theme.typography.fontFamily,
      fontSize: "15px",
      fontColor: "#333333",
      fontWeight: "normal",
      textAlign: "left",
      backgroundColor: theme.palette.common.lightgrey,
    },
    cell: {
      font: theme.typography.fontFamily,
      fontSize: "15px",
      fontColor: "#666666",
      textAlign: "left",
      backgroundColor: "inherit",
    },
  },
  containers: {
    root: {
      boxShadow: "none",
      border: "none",
      borderRadius: "0",
      textAlign: "left",
    },
    section: {
      boxShadow: "none",
      border: "none",
      borderRadius: "0",
      textAlign: "left",
    },
  },
  saveButton: {
    root: {
      borderRadius: "0",
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: "white",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      boxShadow: "none",
    },
    hover: {
      boxShadow: "none",
    },
  },
  sectionColumns: {
    container: {
      borderTop: `1px solid ${theme.palette.common.grey}`,
    },
    sectionDisplay: {
      marginTop: 0,
      borderLeft: `1px solid ${theme.palette.common.grey}`,
    },
  },
  subsections: {
    all: {
      borderRadius: "0",
      borderColor: theme.palette.common.grey,
    },
    adjacent: {
      borderRadius: "0",
      borderColor: theme.palette.common.grey,
      marginTop: "14px",
    },
    header: {
      backgroundColor: theme.palette.common.lightgrey,
    },
    container: {
      padding: "0 20px",
    },
    divider: {
      borderTop: "none",
      borderBottom: "none",
      margin: 0,
    },
  },
  sectionMenu: {
    selected: {
      oldSelected: {
        borderLeft: "none",
        borderTop: "none !important",
      },
      borderLeft: `1px solid ${theme.palette.common.grey}`,
      borderTop: `4px solid ${theme.palette.primary.main} !important`,
    },
    header: {
      fontSize: "0",
      textTransform: "none",
      lineHeight: "0",
      color: theme.palette.common.darkgrey,
      padding: "0",
      margin: "0",
      display: "none",
    },
    container: {
      marginTop: "0 !important",
      padding: 0,
      border: "none !important",
      borderRadius: 0,
    },
    item: {
      root: {
        color: theme.palette.common.darkgrey,
        border: `1px solid ${theme.palette.common.grey}`,
        padding: "20px 15px",
        lineHeight: "21px",
        fontSize: "16px",
        maxWidth: "100%",
      },
      hover: {
        backgroundColor: theme.palette.common.grey,
      },
    },
  },
  headers: {
    color: theme.palette.primary.main,
    fontWeight: "bold !important",
    textTransform: "uppercase",
    textAlign: "left",
    lineHeight: "23px",
    fontSize: "1.1428571428571428rem",
  },
  fields: {
    borderRadius: "0",
    border: `1px solid ${theme.palette.common.grey}`,
    padding: "8px",
    background: theme.palette.common.lightgrey,
  },
  submitButtonContainer: {
    position: "absolute",
    top: "20px",
    right: "20px",
    display: "inline-block",
    width: "auto",
  },
  buttons: {
    root: {
      borderRadius: "0",
      boxShadow: "none",
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    hover: {
      borderRadius: "0",
      boxShadow: "none",
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
  },
  buttonSetControl: {
    root: {},
    hover: {},
    selected: {},
  },
  sectionContainer: {
    marginLeft: "0px",
    marginRight: "0px",
  },
};
