import { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-jobs";
import type { QueryFunction } from "../types/API";
import type { JobHistory } from "../types/documents";

type Response = {
  count: number;
  jobStateHistoryPageItems: JobHistory[];
};

const query: QueryFunction<JobHistory> = async (
  { page = 0, size = 20, query },
  cancelToken?
) => {
  const skip = page * size;
  const config: AxiosRequestConfig = {
    params: {
      take: size,
      skip,
    },
    cancelToken,
  };
  const path = `${query?.jobId}/statehistory`;
  const { data } = await axios.get<Response>(path, config);
  return {
    items: data.jobStateHistoryPageItems,
    totalCount: data.count,
  };
};

export const api = { query };
