import { FileDownloadOutlined as DownloadIcon } from "@mui/icons-material";
import { useMemo, useRef } from "react";
import { getApiService } from "../../../helpers/bulk-upload-helpers";
import { logger } from "../../../helpers/log-helpers";
import { useCancelToken } from "../../../hooks/general/useCancelToken";
import { bulkUploadStrings as strings } from "../../../resources/strings/settings/bulkUpload";
import { BulkUpload } from "../../../types/documents";
import { BulkUploadState } from "../../../types/documents/BulkUpload";
import { ShowIf } from "../../authentication/ShowIfAuthorised";
import { IconAction } from "../../general/IconAction";

interface Props {
  upload: BulkUpload;
}
type Component = (props: Props) => JSX.Element;

export const DownloadRowsAction: Component = ({
  upload: { id, status, bulkUploadTypeName },
}) => {
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const cancelToken = useCancelToken();
  const api = useMemo(
    () => getApiService(bulkUploadTypeName),
    [bulkUploadTypeName]
  );

  const canDownloadRows =
    status === BulkUploadState.PartialSuccess ||
    status === BulkUploadState.Failure;

  const handleDownload = async () => {
    try {
      if (!downloadRef.current) return;

      const url = await api.downloadFailedRows(id, cancelToken);
      downloadRef.current.href = url;

      downloadRef.current.click();
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <ShowIf show={canDownloadRows}>
      <IconAction tooltip={strings.labels.downloadCSV} onClick={handleDownload}>
        <DownloadIcon />
      </IconAction>
      <a ref={downloadRef} href="/" data-testid="download-link">
        {null}
      </a>
    </ShowIf>
  );
};
