import { API_URL } from "../../../libs/config";

declare type Config = {
  apiUrl: string;
  buildSectionRoute: (id: string) => string;
  createSection: string;
  deactivateSection: (jobId: string, id: string) => string;
  apiEndPoints: ApiEndpoints;
};

declare type CrudEndpoints = {
  create: string;
  delete: (id: string, props?: any) => string;
  get: (id: string) => string;
  update: (id: string) => string;
};

declare type ReorderEndpoint = {
  reorder: (id: string) => string;
};

declare type FormTemplateSectionEndpoints = CrudEndpoints &
  ReorderEndpoint & {};
declare type SubSectionTemplateEndpoints = CrudEndpoints &
  ReorderEndpoint & {
    createWithId: (id: string) => string;
    update: (id: string, subSectionId: string) => string;
  };
declare type ControlTemplateEndpoints = CrudEndpoints & {
  update: (id: string, subSectionId?: string) => string;
  getNewControlId: () => string;
  getControls: (id: string, subSectionId?: string) => string;
};

declare type FormTemplateEndpoints = CrudEndpoints & {
  getwithSectionNames: (id: string) => string;
  getFormSection: (id: string) => string;
  getFormSubSection: (id: string) => string;
  getFormSectionDetails: (id: string) => string;
};

declare type ApiEndpoints = {
  formTemplate: FormTemplateEndpoints;
  sectionTemplate: FormTemplateSectionEndpoints;
  subSectionTemplate: SubSectionTemplateEndpoints;
  controlTemplate: ControlTemplateEndpoints;
};

const config: Config = {
  apiUrl: API_URL,

  buildSectionRoute: (id) => `/sections/${id}`,
  createSection: "/sections/addsection",
  deactivateSection: (jobId, id) => `/sections/${jobId}/${id}`,
  apiEndPoints: {
    formTemplate: {
      create: "jobtypes",

      delete: (id: string, removeJobs: boolean) =>
        `jobtypes/${id}/remove/${removeJobs}`,
      get: (id: string) => `jobtypes/${id}`,
      getwithSectionNames: (id: string) => `jobtypes/${id}/sectionnames`,
      getFormSection: (id: string) => `sectionTypes/${id}`,
      getFormSectionDetails: (id: string) => `sectionTypes/${id}/getdetails`,
      getFormSubSection: (id: string) => `subsectionTypes/${id}`,
      update: (id: string) => `jobtypes/${id}`,
    },
    sectionTemplate: {
      create: `sectiontypes`,
      delete: (id: string) => `sectiontypes/${id}`,
      get: (id: string) => `sectiontypes/${id}`,
      update: (id: string) => `sectiontypes/${id}`,
      reorder: (id: string) => `sectiontypes/${id}/reorder`,
    },
    subSectionTemplate: {
      create: `subsectionTypes`,
      createWithId: (id: string) => `subsectionTypes/${id}`,
      delete: (id: string, subsectionId?: string) =>
        `subsectionTypes/${id}/${subsectionId}`,
      get: (id: string, subSectionId?: string) =>
        `subsectionTypes/${id}/${subSectionId}`,
      update: (id: string, subSectionId?: string) =>
        `subsectionTypes/${id}/${subSectionId}`,
      reorder: (id: string) => `subsectionTypes/${id}/reorder`,
    },
    controlTemplate: {
      create: `controltypes`,
      delete: (id: string) => `controltypes/${id}`,
      get: (id: string) => `controltypes/${id}`,
      update: (id: string, subSectionId?: string) => {
        if (subSectionId) return `controltypes/${id}/${subSectionId}`;
        return `controltypes/${id}`;
      },
      getNewControlId: () => `controltypes/getNewControlId`,
      getControls: (id: string, subSectionId?: string) => {
        if (subSectionId) return `controltypes/${id}/${subSectionId}`;
        return `controltypes/${id}`;
      },
    },
  },
};

export default config;
