import type { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-templates";
import type { QueryFunction } from "../types/API";
import type { DocumentTemplate } from "../types/documents";

const query: QueryFunction<DocumentTemplate> = async (
  { query },
  cancelToken?
) => {
  const { id: jobId = "", jobTypeId = "" } = query ?? {};
  const params = new URLSearchParams({ jobId, jobTypeId });
  const config: AxiosRequestConfig = {
    params,
    cancelToken,
  };
  const { data } = await axios.get<DocumentTemplate[]>("alldocstates", config);
  return {
    items: data,
    totalCount: data.length,
  };
};

export const api = { query };
