import type { CancelToken } from "axios";
import { DeleteIcon } from "../../../assets/DeleteIcon";
import { ShowIf } from "../../../components/authentication/ShowIfAuthorised";
import { FormDeleteModalContent } from "../../../components/forms/FormDeleteModalContent";
import { Button } from "../../../components/general/Button";
import { IconAction } from "../../../components/general/IconAction";
import { Modal } from "../../../components/general/Modal";
import { logger } from "../../../helpers/log-helpers";
import { useFormPermissions } from "../../../hooks/forms/useFormPermissions";
import { usePalette } from "../../../hooks/general/usePalette";
import { formStrings as strings } from "../../../resources/strings/forms";
import { api } from "../../../services/jobtypes.service";
import type { CognitoUser, Permissions } from "../../../types";
import type { JobType } from "../../../types/documents";

type DeleteFormActionProps = {
  form: JobType;
  permissions: Permissions | null;
  user: CognitoUser | null;
  cancelToken: CancelToken;
  formGroupId: string;
  handleRemoveRow: () => void;
};

export const DeleteFormAction = ({
  form,
  permissions,
  user,
  cancelToken,
  formGroupId,
  handleRemoveRow,
}: DeleteFormActionProps) => {
  const palette = usePalette();
  const { canDeleteJobType } = useFormPermissions(permissions, user, form);

  const handleDeleteForm = async (removeJobs: boolean = false) => {
    try {
      await api.deleteForm(formGroupId, removeJobs, cancelToken);
      handleRemoveRow();
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <ShowIf show={canDeleteJobType}>
      <Modal
        trigger={
          <IconAction tooltip={strings.labels.deleteForm}>
            <DeleteIcon sx={[palette.red]} />
          </IconAction>
        }
        header={strings.labels.deleteForm}
        actions={({ onClose }) => [
          <Button label={strings.labels.cancel} onClick={onClose} />,
          <Button
            label={strings.labels.removeJobs}
            variant="outlined"
            onClick={async () => {
              await handleDeleteForm(true);
              // onClose();
            }}
          />,
          <Button
            label={strings.labels.keepJobs}
            variant="outlined"
            onClick={async () => {
              await handleDeleteForm();
              // onClose();
            }}
          />,
        ]}
      >
        <FormDeleteModalContent formId={formGroupId} />
      </Modal>
    </ShowIf>
  );
};
