import "@fontsource/open-sans";
import * as colors from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// colour palette
const primary = "#499def";
const secondary = "#2f4050";
const lightgrey = "#fafafa";
const grey = "#d8d8d8";
const darkgrey = "#666666";
const altgrey = "#999999";

// job states
const green = "#37dbb0";
const red = "#cc0033";
const blue = "#3b84db";
const purple = "#b274b9";
const orange = "#ffae39";
const pink = "#e27181";
const lightgreen = "#95d34c";
const darkgreen = "#3ca534";

// typography
const font = "'Open Sans', sans-serif";

let theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: colors.common.white,
    },
    secondary: {
      main: secondary,
      contrastText: colors.common.white,
    },
    background: {
      default: colors.common.white,
    },
    common: {
      grey,
      lightgrey,
      darkgrey,
      green,
      red,
      blue,
      purple,
      orange,
      pink,
      lightgreen,
      darkgreen,
      altgrey,
    },
  },
  typography: {
    fontFamily: font,
  },
});

theme = responsiveFontSizes(theme);

export { theme };
