export enum JobHistoryType {
  Created = "3f9e2a57-59ba-4059-ba7f-2762e6fd2d59",
  StateChange = "4ccb01a1-3040-46ee-95f2-6298541d2d4f",
  AllocatedToUser = "379bd239-51ab-4b08-baa1-6b8c283d3763",
  Updated = "bab6ce98-89ce-4bb2-9974-216b55c39fb9",
  AttachmentUploaded = "4d95fa34-96c3-426d-8f63-dabc76205fce",
  Cloned = "f4dc8d55-aa5a-4886-ada7-cf59df75bbea",
  Deactivated = "2f5632bb-b669-42a6-9b80-a1b1ca2a9281",
  DocumentationGenerated = "d2f4e439-969a-4ea0-9864-2d06e1fa67b7",
}

export type JobHistory = {
  jobHistoryTypeId: JobHistoryType;
  jobHistoryTypeName: string;
  jobId: string;
  createdByName: string;
  createdById: string;
  createdBy: object;
  createdDate: string;
  updatedById: string;
  updatedBy: object;
  updatedDate: string;
  sourceStateId?: string;
  sourceStateName?: string;
  destinationStateId?: string;
  destinationStateName?: string;
  allocatedToId?: string;
  allocatedToName?: string;
  clonedFromId?: string;
  clonedFromReference?: string;
};
