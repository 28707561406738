import type { CancelToken } from "axios";
import { logger } from "../helpers/log-helpers";
import { api } from "../services/users.service";
import * as utils from "./auth-utils";
import * as auth from "./identity-server";

export const signOut = async () => {
  try {
    await utils.removeToken();
  } catch (e) {
    logger.error(e);
  }
};

export const signUp = async (
  _username: string,
  _password: string,
  _email: string
) => {
  return Promise.reject("not yet implemented");
};

export const confirmSignUp = async (_username: string, _code: string) => {
  return Promise.reject("not yet implemented");
};

export const signInWithDetails = async (username: string, password: string) => {
  try {
    // request a token using provided username and password
    const response = await auth.token(username, password);
    // call the token endpoint again passing the refresh token to get
    // an ID Token since it is not included in above response
    const token = await auth.refreshToken(response.refresh_token);
    await utils.storeToken(token);
    return Promise.resolve(token);
  } catch (e) {
    const message = logger.error(e);
    return Promise.reject(message);
  }
};

export const completeNewPassword = async (
  _user: object,
  _newPassword: string
) => {
  return Promise.reject("not yet implemented");
};

export const getIdToken = async () => {
  try {
    const userDetails = await utils.getIdToken();
    return Promise.resolve(userDetails);
  } catch (e) {
    logger.error(e);
    return Promise.reject(e);
  }
};

export const getToken = async () => {
  try {
    const token = await utils.getAccessToken();
    return Promise.resolve(token);
  } catch (e) {
    logger.error(e);
    return Promise.reject(e);
  }
};

export const forgotPassword = async (
  email: string,
  passwordResetURL: string,
  cancelToken?: CancelToken
) => {
  try {
    await api.requestPasswordReset({ email, passwordResetURL }, cancelToken);
    return Promise.resolve(true);
  } catch (e) {
    logger.error(e);
    return Promise.reject(e);
  }
};

export const forgotPasswordSubmit = async (
  userId: string,
  token: string,
  newPassword: string,
  cancelToken?: CancelToken
) => {
  try {
    await api.resetPassword({ userId, token, newPassword }, cancelToken);
    return Promise.resolve(true);
  } catch (e) {
    logger.error(e);
    return Promise.reject(e);
  }
};

export const validateToken = async (
  payload: { userId: string; token: string },
  cancelToken?: CancelToken
) => {
  try {
    const result = await api.validatePasswordResetToken(payload, cancelToken);
    return Promise.resolve(result);
  } catch (e) {
    logger.error(e);
    return Promise.reject(e);
  }
};
