import { Box, Grid, styled, Typography } from "@mui/material";
import { usePalette } from "../../hooks/general/usePalette";
import { ShowIf } from "../authentication/ShowIfAuthorised";

export type Field = {
  key: string;
  label: string;
  description: string;
  required: boolean;
};

interface Props {
  fields: Array<Field>;
}
type Component = (props: Props) => JSX.Element;

type FieldDetailsProps = { field: Field };

const Span = styled("span")({});

const FieldDetails = ({ field }: FieldDetailsProps) => {
  const palette = usePalette();

  return (
    <Box
      key={field.key}
      display="flex"
      flexDirection="row"
      gap={2}
      role="rowgroup"
    >
      <Grid item xs={3} role="term">
        <Typography fontWeight="bolder" variant="body2">
          {field.label}
          <ShowIf show={field.required}>
            <Span sx={[palette.red]}>{" * "}</Span>
          </ShowIf>
        </Typography>
      </Grid>
      <Grid item xs role="definition">
        <Typography variant="body2">{field.description}</Typography>
      </Grid>
    </Box>
  );
};

export const FieldDetailsDisplay: Component = ({ fields }) => {
  return (
    <Box sx={[{ p: 3 }]} gap={2} display="flex" flexDirection={"column"}>
      {fields.map((field) => (
        <FieldDetails key={field.key} field={field} />
      ))}
    </Box>
  );
};
