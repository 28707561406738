import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  AssetIcon,
  ClientIcon,
  DashboardIcon,
  FormIcon,
  HelpIcon,
  IssueIcon,
  JobIcon,
  SettingsIcon,
  SiteIcon,
  UserIcon,
} from "../../assets";
import { paths } from "../../navigation/paths";
import { appStrings as strings } from "../../resources/strings/app";
import { UserAccess } from "../../types";

type IconType =
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>
  | (() => JSX.Element);

const { entities, permissions, menuLabels } = strings;

interface BaseMenuItem {
  key: string;
  label: string;
  Icon?: IconType;
  itemPermission?: UserAccess;
}

interface MenuItem extends BaseMenuItem {
  type: "standard";
  link: string;
}

interface MenuItemWithChildren extends BaseMenuItem {
  type: "parent";
  childItems: MenuItem[];
}

export type MenuItemType = MenuItem | MenuItemWithChildren;

export type MenuItemsType = Array<MenuItemType>;

export const menuItems: MenuItemsType = [
  {
    key: "dashboard",
    type: "standard",
    label: menuLabels.dashboard,
    Icon: DashboardIcon,
    link: paths.dashboard,
  },
  {
    key: "client",
    type: "standard",
    label: menuLabels.clients,
    Icon: ClientIcon,
    link: paths.clients.list,
  },
  {
    key: "site",
    type: "standard",
    label: menuLabels.sites,
    Icon: SiteIcon,
    link: paths.sites.list(),
  },
  {
    key: "jobs",
    type: "standard",
    label: menuLabels.jobs,
    Icon: JobIcon,
    itemPermission: {
      entity: entities.jobs,
      permission: permissions.viewList,
    },
    link: paths.jobs.list(),
  },
  {
    key: "users",
    type: "standard",
    label: menuLabels.users,
    Icon: UserIcon,
    itemPermission: {
      entity: strings.entities.users,
      permission: strings.permissions.read,
    },
    link: paths.tenants.users.list(),
  },
  {
    key: "forms",
    type: "standard",
    label: menuLabels.forms,
    Icon: FormIcon,
    itemPermission: {
      entity: entities.forms,
      permission: permissions.read,
    },
    link: paths.forms.list,
  },
  {
    key: "assets",
    type: "standard",
    label: menuLabels.assets,
    Icon: AssetIcon,
    itemPermission: {
      entity: entities.assets,
      permission: permissions.read,
    },
    link: paths.assets.list,
  },
  {
    key: "issues",
    type: "standard",
    label: menuLabels.issues,
    Icon: IssueIcon,
    itemPermission: {
      entity: entities.issues,
      permission: permissions.read,
    },
    link: paths.issues.list,
  },
  {
    key: "settings",
    type: "parent",
    label: menuLabels.settings,
    Icon: SettingsIcon,
    itemPermission: {
      entity: entities.settings,
    },
    childItems: [
      {
        key: "bulkUpload",
        type: "standard",
        label: menuLabels.bulkUpload,
        itemPermission: {
          entity: entities.bulkUpload,
          permission: permissions.create,
        },
        link: paths.settings.bulkUpload,
      },
      {
        key: "subscription",
        type: "standard",
        label: menuLabels.subscription,
        link: paths.settings.subscription,
      },
      {
        key: "payments",
        type: "standard",
        label: menuLabels.payments,
        link: paths.settings.payments,
      },
    ],
  },
  {
    key: "help",
    type: "standard",
    label: menuLabels.help,
    Icon: HelpIcon,
    link: paths.help,
  },
];
