export const contactStrings = {
  headers: {
    name: "Name",
    contactType: "Contact Type",
    action: "Action",
    newContact: "Create Contact",
    editContact: "Edit Contact",
    deleteContact: "Delete Contact",
    defaultContact: "Default Contact",
  },
  text: {
    delete: "This is a permanent action and cannot be undone. Are you sure?",
  },
  labels: {
    newContact: "Create Contact",
    noContactsAdded: "No Contacts Added",
    noContactsAddedDetail:
      "Add a Contact by clicking the Create Contact button below",
    editContact: "Edit Contact",
    deleteContact: "Delete Contact",
    deleteContactWarning: "Are you sure you wish to delete this contact?",
    contactTypeId: "Contact Type",
    jobTitle: "Job Title",
    titleId: "Title",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    mobile: "Mobile",
    email: "Email",
    cancel: "Cancel",
    submit: "Submit",
    confirm: "Confirm",
  },
};
