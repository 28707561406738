import { useLocation } from "react-router-dom";
import { appStrings } from "../../resources/strings/app";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import type { Permissions } from "../../types";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { Button } from "../general/Button";
import { CreateEntityButton } from "../general/CreateEntityButton";
import { Modal } from "../general/Modal";
import { OrganisationUserAccess } from "./OrganisationUserAccess";

interface Props {
  permissions: Permissions | null;
  createPath: string;
  isRootOrganisation?: boolean;
  organisationId: string;
  handleRefresh: () => void;
}
type Component = (props: Props) => JSX.Element;

export const OrganisationUserListButtons: Component = ({
  createPath,
  permissions,
  isRootOrganisation,
  organisationId,
  handleRefresh,
}) => {
  const { state } = useLocation<object>();
  return (
    <>
      <ShowIf show={!isRootOrganisation}>
        <Modal
          header={strings.headers.grantUserAccess}
          trigger={
            <Button
              type="button"
              label={strings.labels.grantUserAccess}
              variant="outlined"
            />
          }
          actions={() => []}
        >
          {({ onClose }) => (
            <OrganisationUserAccess
              organisationId={organisationId}
              handleClose={onClose}
              handleRefresh={handleRefresh}
            />
          )}
        </Modal>
      </ShowIf>
      <CreateEntityButton
        config={{
          label: strings.labels.createUser,
          path: createPath,
          userAccess: {
            entity: appStrings.entities.organisations,
          },
          state,
        }}
        permissions={permissions}
      />
    </>
  );
};
