const BASE_API_URL = process.env.REACT_APP_API_URL;

// Endpoints
export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_URL;
export const PERMISSIONS_ENDPOINT = `${BASE_API_URL}/permissions`;
export const TAGS_ENDPOINT = `${BASE_API_URL}`;
export const JOBS_ENDPOINT = `${BASE_API_URL}/jobs`;
export const JOB_TYPES_ENDPOINT = `${BASE_API_URL}/jobtypes`;
export const JOB_STATES_ENDPOINT = `${BASE_API_URL}/jobstates`;
export const CLIENTS_ENDPOINT = `${BASE_API_URL}/clients`;
export const USERS_ENDPOINT = `${BASE_API_URL}/users`;
export const ORGANISATIONS_ENDPOINT = `${BASE_API_URL}/organisations`;
export const CONTACTS_ENDPOINT = `${BASE_API_URL}/contacts`;
export const TEMPLATES_ENDPOINT = `${BASE_API_URL}/documenttemplates`;
export const BULK_UPLOADS_ENDPOINT = `${BASE_API_URL}/csvuploads`;
export const JOB_UPLOAD_ENDPOINT = `${BASE_API_URL}/jobupload`;
export const ASSET_UPLOAD_ENDPOINT = `${BASE_API_URL}/assetupload`;
export const ORGANISATION_UPLOAD_ENDPOINT = `${BASE_API_URL}/organisationupload`;
export const ORGANISATION_TYPES_ENDPOINT = `${BASE_API_URL}/organisationtypes`;
export const CONTACT_TYPES_ENDPOINT = `${BASE_API_URL}/contacttypes`;
export const TITLES_ENDPOINT = `${BASE_API_URL}/titles`;
export const USER_TYPES_ENDPOINT = `${BASE_API_URL}/usertypes`;
export const ROLES_ENDPOINT = `${BASE_API_URL}/roles`;

// App config
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const WEB_URL = process.env.REACT_APP_WEB_URL;
export const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;

export { BASE_API_URL as API_URL };
