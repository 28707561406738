import { Link as UILink, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import type { Link as TLink } from "../types/Modify";
import type { SxProps, Theme } from "@mui/material";

interface Props {
  config: Omit<TLink, "controltype">;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = {
  "&:hover": {
    cursor: "pointer",
  },
};

export const Link: Component = ({ config }) => {
  const { text = "", to = "", href } = config;

  const renderLink = () => {
    if (to) {
      return (
        <UILink
          component={RouterLink}
          to={to}
          color="primary"
          underline="always"
        >
          {text}
        </UILink>
      );
    }
    return (
      <UILink color="primary" underline="always" href={href}>
        {text}
      </UILink>
    );
  };

  return (
    <Typography component={"span"} {...config} sx={[rootStyles]}>
      {renderLink()}
    </Typography>
  );
};
