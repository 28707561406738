import { useCallback, useState } from "react";
import type { ChangeHandler } from "../../components/general/types/Modify";

export const useForm = <T extends object>(formData: T) => {
  const [data, setData] = useState<T>(formData);

  const handleChange = useCallback<ChangeHandler>((e) => {
    const { name, value } = e.target;
    if (!name) return;

    setData((currentData) => ({
      ...currentData,
      [name]: value,
    }));
  }, []);

  return [data, handleChange, setData] as const;
};
