import { InputAdornment } from "@mui/material";
import { useCallback, useMemo } from "react";
import { LocationIcon } from "../../assets";
import { ShowIf } from "../../components/authentication/ShowIfAuthorised";
import { IconAction } from "../../components/general/IconAction";
import type {
  ContentConfig,
  CustomProps,
  Mode,
} from "../../components/general/types/Modify";
import { OrganisationBreadcrumbHeader } from "../../components/organisations/OrganisationBreadcrumbHeader";
import { OrganisationImageUpload } from "../../components/organisations/OrganisationImageUpload";
import { OrganisationParentDetails } from "../../components/organisations/OrganisationParentDetails";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import * as maps from "../../helpers/maps-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import {
  countryOptions,
  timezoneOptions,
} from "../../resources/options/organisations";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { api } from "../../services/organisations.service";
import type { RouteProps, ValidationConstraints } from "../../types";
import type { OrganisationDto } from "../../types/documents/Organisation";
import { ContactList } from "../contacts/ContactList";
import { ModifyContainer } from "../general/ModifyContainer";

export interface Props extends RouteProps {
  mode: Mode;
  createHeader: string;
  editHeader: string;
  listHeader: string;
  listPath: string;
  saveLabel: string;
  redirectPath: string;
  organisationTypeId: string;
  isParentOrganisation: boolean;
  deleteHeader: string;
  getUsersListPath: (id: string) => string;
}
type Component = (props: Props) => JSX.Element;

const constraints: ValidationConstraints<OrganisationDto> = {
  parentId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.parent} is required`,
    },
  },
  name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.name} is required`,
    },
  },
  address1: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.address1} is required`,
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.city} is required`,
    },
  },
  zip: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.zip} is required`,
    },
  },
  country: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.country} is required`,
    },
  },
  timezone: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.timezone} is required`,
    },
  },
};

type RenderBreadcrumbHeaderProps = Pick<
  Props,
  | "createHeader"
  | "editHeader"
  | "listHeader"
  | "listPath"
  | "saveLabel"
  | "deleteHeader"
  | "getUsersListPath"
>;

type RenderParentDetailsProps = Pick<Props, "isParentOrganisation">;

type RenderContactListProps = Pick<Props, "isParentOrganisation">;

const renderBreadcrumbHeader =
  (orgProps: RenderBreadcrumbHeaderProps) =>
  (props: CustomProps<OrganisationDto>) =>
    <OrganisationBreadcrumbHeader {...props} {...orgProps} />;

const renderParentDetails =
  (orgProps: RenderParentDetailsProps) =>
  (props: CustomProps<OrganisationDto>) =>
    <OrganisationParentDetails {...props} {...orgProps} />;

const renderContactList =
  (orgProps: RenderContactListProps) => (props: CustomProps<OrganisationDto>) =>
    <ContactList {...props} {...orgProps} />;

const useTagResolvers = (organisationTypeId: string) => {
  const cancelToken = useCancelToken();
  const getClients = useCallback(
    async () =>
      api
        .getImmediateParents(organisationTypeId, 1000, cancelToken)
        .then(({ organisations }) =>
          createDropdownOptions(organisations, "id", "name")
        ),
    [organisationTypeId, cancelToken]
  );
  return { getClients };
};

export const OrganisationModify: Component = ({
  mode,
  createHeader,
  editHeader,
  listHeader,
  listPath,
  saveLabel,
  redirectPath,
  organisationTypeId,
  isParentOrganisation,
  deleteHeader,
  getUsersListPath,
  ...routeProps
}) => {
  const { getClients } = useTagResolvers(organisationTypeId);

  const breadcrumb = useMemo(
    () =>
      renderBreadcrumbHeader({
        createHeader,
        editHeader,
        listHeader,
        listPath,
        saveLabel,
        deleteHeader,
        getUsersListPath,
      }),
    [
      createHeader,
      editHeader,
      listHeader,
      listPath,
      saveLabel,
      deleteHeader,
      getUsersListPath,
    ]
  );
  const parentDetails = useMemo(
    () => renderParentDetails({ isParentOrganisation }),
    [isParentOrganisation]
  );
  const contactList = useMemo(
    () => renderContactList({ isParentOrganisation }),
    [isParentOrganisation]
  );

  return (
    <ModifyContainer<OrganisationDto>
      {...routeProps}
      api={api}
      mode={mode}
      initialData={{
        id: "",
        address1: "",
        address2: "",
        city: "",
        contacts: [],
        country: "",
        county: "",
        documents: [],
        externalReference: "",
        image: "",
        name: "",
        organisationTypeId,
        timezone: "",
        zip: "",
        languageId: "",
        parentId: "",
      }}
      componentConfiguration={({ data, mode }) => {
        const organisationDetailsFields: ContentConfig<OrganisationDto>[] = [
          {
            controltype: "header",
            text: strings.headers.organisationDetails,
          },
          {
            controltype: "input",
            label: strings.labels.name,
            name: "name",
            required: true,
          },
          {
            controltype: "input",
            label: strings.labels.externalReference,
            name: "externalReference",
          },
          {
            controltype: "custom",
            Component: OrganisationImageUpload,
          },
        ];
        if (!isParentOrganisation) {
          const clientField: ContentConfig<OrganisationDto> = {
            controltype: "dropdown",
            label: strings.labels.parent,
            name: "parentId",
            required: true,
            options: getClients,
          };
          const spacer: ContentConfig<OrganisationDto> = {
            controltype: "custom",
            Component: () => <></>,
          };
          organisationDetailsFields.splice(1, 0, clientField, spacer);
        }

        return [
          {
            key: "header",
            content: [
              {
                controltype: "custom",
                Component: breadcrumb,
                md: 12,
                control: false,
              },
            ],
          },
          {
            key: "organisationDetails",
            ariaLabel: strings.ariaLabels.organisationDetails,
            content: organisationDetailsFields,
          },
          {
            key: "address",
            ariaLabel: strings.ariaLabels.address,
            content: [
              {
                controltype: "header",
                text: strings.headers.address,
              },
              {
                controltype: "input",
                label: strings.labels.address1,
                name: "address1",
                required: true,
                InputProps: {
                  endAdornment: (
                    <ShowIf show={mode !== "create"}>
                      <InputAdornment position="end">
                        <IconAction
                          edge="end"
                          size="medium"
                          tooltip={strings.labels.mapLocation}
                          onClick={() =>
                            maps.search([
                              data.name,
                              data.address1,
                              data.address2,
                              data.city,
                              data.county,
                              data.country,
                              data.zip,
                            ])
                          }
                        >
                          <LocationIcon />
                        </IconAction>
                      </InputAdornment>
                    </ShowIf>
                  ),
                },
              },
              {
                controltype: "input",
                label: strings.labels.address2,
                name: "address2",
              },
              {
                controltype: "input",
                label: strings.labels.city,
                name: "city",
                required: true,
              },
              {
                controltype: "input",
                label: strings.labels.county,
                name: "county",
              },
              {
                controltype: "input",
                label: strings.labels.zip,
                name: "zip",
                required: true,
              },
              {
                controltype: "dropdown",
                label: strings.labels.country,
                name: "country",
                required: true,
                options: countryOptions,
              },
            ],
          },
          {
            key: "language",
            ariaLabel: strings.ariaLabels.language,
            content: [
              {
                controltype: "header",
                text: strings.headers.language,
              },
              {
                controltype: "dropdown",
                label: strings.labels.timezone,
                name: "timezone",
                required: true,
                options: timezoneOptions,
              },
            ],
          },
          {
            key: "contacts",
            ariaLabel: strings.ariaLabels.contacts,
            content: [
              {
                controltype: "header",
                text: strings.headers.contacts,
              },
              {
                controltype: "custom",
                Component: contactList,
                control: false,
                md: 12,
              },
            ],
          },
          {
            key: "parentDetails",
            modes: ["create"],
            content: [
              {
                controltype: "custom",
                Component: parentDetails,
                control: false,
              },
            ],
          },
        ];
      }}
      constraints={constraints}
      redirectPath={redirectPath}
    />
  );
};
