import { useMemo, useRef } from "react";
import { getApiService } from "../../../helpers/bulk-upload-helpers";
import { logger } from "../../../helpers/log-helpers";
import { useCancelToken } from "../../../hooks/general/useCancelToken";
import type { BulkUpload } from "../../../types/documents";
import { Link } from "../../general/controls/Link";

interface Props {
  upload: BulkUpload;
}
type Component = (props: Props) => JSX.Element;

export const DownloadUploadedFileAction: Component = ({
  upload: { id, fileName, bulkUploadTypeName },
}) => {
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const cancelToken = useCancelToken();
  const api = useMemo(
    () => getApiService(bulkUploadTypeName),
    [bulkUploadTypeName]
  );

  const handleDownload = async () => {
    try {
      if (!downloadRef.current) return;

      const url = await api.downloadOriginalFile(id, cancelToken);
      downloadRef.current.href = url;

      downloadRef.current.click();
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <>
      <Link
        config={{
          text: fileName,
          variant: "body2",
          onClick: handleDownload,
        }}
      />
      <a ref={downloadRef} href="/" data-testid="download-link">
        {null}
      </a>
    </>
  );
};
