import { useCallback, useState } from "react";
import { DeleteIcon } from "../../assets/DeleteIcon";
import { BasicCheckbox } from "../../components/general/controls/BasicCheckbox";
import { Link } from "../../components/general/controls/Link";
import { FileUpload } from "../../components/general/FileUpload";
import { IconAction } from "../../components/general/IconAction";
import type { FileReaderResult } from "../../helpers/file-parser";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { usePalette } from "../../hooks/general/usePalette";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api } from "../../services/jobdocuments.service";
import type { RouteProps } from "../../types";
import { JobDocument } from "../../types/documents";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {
  jobId: string;
}
type Component = (props: Props) => JSX.Element;

const allowedFileTypes = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  "application/pdf",
  "text/plain",
  "text/csv",
  "application/json",
];

export const JobDocumentList: Component = ({ jobId, ...routeProps }) => {
  const palette = usePalette();
  const cancelToken = useCancelToken();

  const [filters, setFilters] = useState<Partial<JobDocument>>({
    jobId,
    refresh: false,
  });

  const handleRefresh = () =>
    setFilters((prev) => ({ ...prev, refresh: !prev.refresh }));

  const handleVisibilityChange = async (
    documentId: string,
    checked?: boolean
  ) => {
    if (typeof checked !== "boolean") return;
    try {
      await api.updateVisibility(documentId, checked, cancelToken);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleDelete = async (documentId: string) => {
    try {
      await api.deleteDocument(documentId, cancelToken);
      handleRefresh();
    } catch (e) {
      logger.error(e);
    }
  };

  const handleFileUpload = useCallback(
    async (fileData: FileReaderResult) => {
      try {
        await api.uploadDocument(jobId, fileData, cancelToken);
        handleRefresh();
      } catch (e) {
        logger.error(e);
      }
    },
    [jobId, cancelToken]
  );

  return (
    <div>
      <TableContainer<JobDocument>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "name",
            Header: strings.labels.filename,
            accessor: ({ name, documentUri }) => (
              <Link
                config={{
                  text: name,
                  href: documentUri,
                  variant: "body2",
                }}
              />
            ),
          },
          {
            id: "visibleOnPortal",
            Header: strings.labels.visibleOnPortal,
            accessor: ({ visibleOnPortal, documentId }) => (
              <BasicCheckbox
                config={{
                  name: "visibleOnPortal",
                  defaultChecked: visibleOnPortal,
                  size: "small",
                  compact: true,
                }}
                handleChange={(e) =>
                  handleVisibilityChange(documentId, e.target.checked)
                }
              />
            ),
          },
          {
            id: "delete",
            Header: strings.labels.delete,
            accessor: ({ documentId }) => (
              <IconAction
                tooltip={strings.labels.deleteDocument}
                onClick={() => handleDelete(documentId)}
              >
                <DeleteIcon sx={[palette.red]} />
              </IconAction>
            ),
            disableSortBy: true,
            maxWidth: 20,
          },
        ]}
        filters={filters as any}
        inlineLoader
      >
        <FileUpload
          maxSize={102400}
          allowedFileTypes={allowedFileTypes}
          handleUpload={handleFileUpload}
        />
      </TableContainer>
    </div>
  );
};
