import type { Job } from "../types/documents";
import { displayName } from "./string-helpers";

export const displaySite = ({ siteName, siteAddress }: Job) => {
  const output = [];
  if (siteName) output.push(siteName);

  if (siteAddress) {
    const { address1 = "", address2 = "", city = "", zip = "" } = siteAddress;
    if (address1) output.push(address1);
    if (address2) output.push(address2);
    if (city) output.push(city);
    if (zip) output.push(zip);
  }
  return output.join(", ");
};

export const displayAllocatedTo = ({ allocatedTo }: Job) =>
  displayName(allocatedTo?.firstName, allocatedTo?.lastName);
