import type { DropdownOptions } from "../../../types";
import { BulkUploadType } from "../../../types/documents/BulkUpload";
import { bulkUploadStrings } from "../../strings/settings/bulkUpload";

export const fileTypeOptions: DropdownOptions = [
  {
    label: bulkUploadStrings.headers.organisation,
    value: BulkUploadType.Organisation,
  },
  {
    label: bulkUploadStrings.headers.asset,
    value: BulkUploadType.Asset,
  },
  {
    label: bulkUploadStrings.headers.job,
    value: BulkUploadType.Job,
  },
];
