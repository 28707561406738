import { CloseOutlined as CloseIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import type { CancelToken } from "axios";
import { logger } from "../../../helpers/log-helpers";
import { formStrings as strings } from "../../../resources/strings/forms";
import { api } from "../../../services/jobtypes.service";
import { Button } from "../../general/Button";
import { IconAction } from "../../general/IconAction";
import { Modal } from "../../general/Modal";
import { FormDeleteVersionModalContent } from "../FormDeleteVersionModalContent";

type DeleteActionProps = {
  id: string;
  cancelToken: CancelToken;
  handleChange: () => void;
  isPublished: boolean;
};

export const DeleteVersionAction = ({
  id,
  handleChange,
  cancelToken,
  isPublished,
}: DeleteActionProps) => {
  const handleDeleteVersion = async (removeJobs: boolean = false) => {
    try {
      await api.deleteFormVersion(id, removeJobs, cancelToken);
      handleChange();
    } catch (e) {
      logger.error(e);
    }
  };

  const modalTrigger = (
    <IconAction tooltip={strings.labels.deleteVersion}>
      <CloseIcon />
    </IconAction>
  );
  const modalHeader = strings.labels.deleteVersion;

  if (!isPublished) {
    return (
      <Modal
        trigger={modalTrigger}
        header={modalHeader}
        actions={({ onClose }) => [
          <Button label={strings.labels.cancel} onClick={onClose} />,
          <Button
            label={strings.labels.ok}
            variant="outlined"
            onClick={async () => {
              await handleDeleteVersion();
              // onClose();
            }}
          />,
        ]}
      >
        <Typography>{strings.text.deleteVersion}</Typography>
      </Modal>
    );
  }

  return (
    <Modal
      trigger={modalTrigger}
      header={modalHeader}
      actions={({ onClose }) => [
        <Button label={strings.labels.cancel} onClick={onClose} />,
        <Button
          label={strings.labels.removeJobs}
          variant="outlined"
          onClick={async () => {
            await handleDeleteVersion(true);
            // onClose();
          }}
        />,
        <Button
          label={strings.labels.keepJobs}
          variant="outlined"
          onClick={async () => {
            await handleDeleteVersion();
            // onClose();
          }}
        />,
      ]}
    >
      <FormDeleteVersionModalContent versionId={id} />
    </Modal>
  );
};
