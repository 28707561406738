import { useLocation } from "react-router-dom";
import { useJobPermissions } from "../../context/JobPermissionsContext";
import type { Job, JobDto } from "../../types/documents";
import { LocationDetails } from "../general/LocationDetails";
import type { CustomProps } from "../general/types/Modify";

export const JobLocationDetails = (props: CustomProps<JobDto>) => {
  const { state } = useLocation<Job>();

  const { canEditSitesAndContacts, canEditJobDetails } = useJobPermissions();
  return (
    <LocationDetails
      {...props}
      disabled={!canEditSitesAndContacts || !canEditJobDetails}
      text={{
        organisationId: state?.clientName,
        siteId: state?.siteName,
      }}
    />
  );
};
