export const jobStrings = {
  header: {
    jobsList: "Jobs List",
    createJob: "Create Job",
    selectForm: "Select Form",
    jobLocation: "Job Location",
    jobDetails: "Job Details",
    notes: "Notes",
    uploadDocuments: "Upload Documents",
    deleteJob: "Delete Job",
    jobHistory: "Job History",
  },
  labels: {
    createJob: "Create Job",
    jobReferenceCode: "Reference",
    name: "Job",
    clientName: "Client",
    siteName: "Site",
    scheduledDate: "Scheduled Date",
    allocatedTo: "Allocated To",
    state: "Status",
    completedDate: "Completed on",
    partOfRecurrence: "Part of Recurrence",
    partOfMonitoringVisit: "Part of Monitoring Visit",
    partOfMonitoringRegime: "Part of Monitoring Regime",
    export: "Export",
    search: "Search",
    from: "From",
    to: "To",
    showStates: "Show States",
    reset: "Reset",
    cancel: "Cancel",
    save: "Save",
    jobTypeId: "Form",
    clientId: "Client",
    siteId: "Site",
    contactId: "Contact",
    scheduledTime: "Scheduled Time",
    externalRef: "External Reference",
    timeInMinutes: "Estimated Job Duration (Minutes)",
    delete: "Delete",
    editJobSections: "Edit Job Sections",
    lastUpdated: "Last updated: ",
    copy: "Copy Job",
    viewDocuments: "Report Documents",
    uploadDocuments: "Upload Documents",
    filename: "Filename",
    visibleOnPortal: "Visible on Portal",
    deleteDocument: "Delete document",
    status: "Status",
    actions: "Actions",
    refresh: "Refresh",
    downloadPDF: "Download PDF Document",
    downloadWord: "Download Word Document",
    generate: "Generate",
    siteVisitReport: "View Site Visit report",
    reportName: "Report",
    confirm: "Confirm",
    jobHistoryType: "Type",
    jobHistoryCreatedBy: "User",
    jobHistoryCreated: "Date",
    jobHistoryDetails: "Details",
    jobState: "Job State",
    historyFrom: "From:",
    historyTo: "To:",
    jobSections: "Sections",
    job: "Job",
  },
  placeholders: {
    searchBox: "Search Client, Site, Allocated To, Form, State or Reference",
    select: "- select -",
    showAll: "Show All",
    notes: "Enter any additional job notes",
  },
  text: {
    delete: "This is a permanent action and cannot be undone. Are you sure?",
  },
  recurrenceTypes: {
    forms: "forms",
    monitoring: "monitoring",
  },
  documentStates: {
    failed: "Failed",
    new: "New",
    generated: "Generated",
    generating: "Generating",
  },
};
