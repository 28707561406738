import { Grid, styled } from "@mui/material";
import { formGroupOptions } from "../../resources/options/forms";
import { formStrings as strings } from "../../resources/strings/forms";
import type { FilterChangeHandler } from "../../types";
import type { JobTypeFilters } from "../../types/documents/JobType";
import { BaseControl } from "../general/controls/BaseControl";
import { Dropdown } from "../general/controls/Dropdown";
import type { ChangeHandler } from "../general/types/Modify";
import { FormInputFilters } from "./filters/FormInputFilters";
import type { SxProps, Theme } from "@mui/material";
import { Link } from "../general/controls/Link";

interface Props {
  filters: JobTypeFilters;
  handleFilterChange: FilterChangeHandler;
  clearFilters: () => void;
}
type Component = (props: Props) => JSX.Element;

const Span = styled("span")({});

const paddingStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(1.5, 3, 0),
});

export const FormSearchBar: Component = ({
  filters: { state, name },
  handleFilterChange,
  clearFilters,
}) => {
  const handleChange: ChangeHandler = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    handleFilterChange(name, value);
  };

  return (
    <Grid container spacing={1} role="searchbox">
      <Grid item xs={5}>
        <FormInputFilters
          filters={{ name }}
          handleFilterChange={handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <BaseControl control={false}>
          <Dropdown
            config={{
              name: "state",
              label: strings.labels.showFormGroups,
              options: formGroupOptions,
              value: state,
            }}
            handleChange={handleChange}
          />
        </BaseControl>
      </Grid>
      <Span sx={[paddingStyles]}>
        <Link
          config={{
            text: strings.labels.reset,
            variant: "body2",
            onClick: clearFilters,
          }}
        />
      </Span>
    </Grid>
  );
};
