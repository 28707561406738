import { ListItem, Popover, SxProps, Theme } from "@mui/material";
import { useMenuContext } from "../../context/MenuContext";
import type { MenuItemsType } from "./listItems";
import { MenuItems } from "./MenuItemsComponent";

interface Props {
  selectedStyles: SxProps<Theme>;
  isSelected: boolean;
  itemKey: string;
  childItems: MenuItemsType;
  anchorEl: HTMLButtonElement | null;
  handleClosePopover: () => void;
}
type Component = (props: Props) => JSX.Element;

export const SubMenuItemContent: Component = ({
  selectedStyles,
  isSelected,
  itemKey,
  childItems,
  anchorEl,
  handleClosePopover,
}) => {
  const { open: menuOpen } = useMenuContext();

  const SubMenuContent = <MenuItems parent={itemKey} menuItems={childItems} />;

  if (menuOpen) {
    return (
      <ListItem
        sx={[
          ...(Array.isArray(selectedStyles)
            ? selectedStyles
            : [selectedStyles]),
        ]}
        selected={isSelected}
        disableGutters
        disablePadding
      >
        {SubMenuContent}
      </ListItem>
    );
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={handleClosePopover}
      elevation={1}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {SubMenuContent}
    </Popover>
  );
};
