import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import { useForm } from "../../../hooks/general/useForm";
import { usePalette } from "../../../hooks/general/usePalette";
import { jobStrings as strings } from "../../../resources/strings/jobs";
import type { JobFilters } from "../../../types/documents";
import { Button } from "../../general/Button";
import { BaseControl } from "../../general/controls/BaseControl";
import { DatePicker } from "../../general/controls/DatePicker";
import { Input } from "../../general/controls/Input";
import type { ChangeHandler } from "../../general/types/Modify";

interface Props {
  filters: Pick<JobFilters, "searchString" | "dateFrom" | "dateTo">;
  handleFilterChange: ChangeHandler;
}
type Component = (props: Props) => JSX.Element;

export const JobInputFilters: Component = ({ filters, handleFilterChange }) => {
  const palette = usePalette();

  const [currentFilters, handleChange] = useForm<Props["filters"]>(filters);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    handleFilterChange({
      target: {
        name: "dateFrom",
        value: currentFilters.dateFrom,
      },
    });
    handleFilterChange({
      target: {
        name: "dateTo",
        value: currentFilters.dateTo,
      },
    });
    handleFilterChange({
      target: {
        name: "searchString",
        value: currentFilters.searchString,
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <BaseControl control={false}>
            <Input
              config={{
                name: "searchString",
                placeholder: strings.placeholders.searchBox,
                value: currentFilters.searchString ?? "",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={[palette.darkgrey]} />
                    </InputAdornment>
                  ),
                },
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <DatePicker
              config={{
                name: "dateFrom",
                placeholder: strings.placeholders.select,
                label: strings.labels.from,
                value: currentFilters.dateFrom ?? "",
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <DatePicker
              config={{
                name: "dateTo",
                placeholder: strings.placeholders.select,
                label: strings.labels.to,
                value: currentFilters.dateTo ?? "",
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            type="submit"
            label={strings.labels.search}
          />
        </Grid>
      </Grid>
    </form>
  );
};
