import axios from "./axios/axios-auth";
import { CLIENT_ID, CLIENT_SECRET } from "./config";

export type Token = {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  challengeName: string;
};
export type RefreshedToken = Token & {
  id_token: string;
  expires_at?: number;
};

type GetToken = (username: string, password: string) => Promise<Token>;
type RefreshToken = (refresh_token: string) => Promise<RefreshedToken>;

export const token: GetToken = async (username, password) => {
  const payload = new URLSearchParams({
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: "openid offline_access pulsionmobileapiaccess pulsionmobileprofile",
    grant_type: "password",
    username: username,
    password: password,
  });
  return axios
    .post("/identity/connect/token", payload)
    .then(({ data }) => Promise.resolve(data))
    .catch(({ response: { data } }) => Promise.reject(data));
};

export const refreshToken: RefreshToken = async (refresh_token) => {
  const payload = new URLSearchParams({
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: "refresh_token",
    refresh_token,
  });
  return axios
    .post("/identity/connect/token", payload)
    .then(({ data }) => Promise.resolve(data))
    .catch(({ response: { data } }) => Promise.reject(data));
};
