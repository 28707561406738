import { useEffect } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import { api } from "../../../services/permissions.service";
import { CognitoUser, Permissions } from "../../../types";
import { useReadQuery } from "../../general/useReadQuery";

const { read } = api;

export const useUserPermissions = (user: CognitoUser | null) => {
  const { setUserPermissions } = useAuthContext();
  const [permissions, loading, error] = useReadQuery<Permissions>(
    {},
    read,
    user !== null ? "id" : ""
  );
  useEffect(() => {
    setUserPermissions(permissions);
  }, [permissions, setUserPermissions]);
  return { permissions, loading, error };
};
