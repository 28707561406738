import {
  BarChart as BarChartIcon,
  PictureAsPdfOutlined as PDFIcon,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { DocumentIcon } from "../../assets";
import { logger } from "../../helpers/log-helpers";
import { useIsAuthorised } from "../../hooks/authentication/useIsAuthorised";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api } from "../../services/jobs.service";
import type { Permissions } from "../../types";
import type { DocumentTemplate } from "../../types/documents";
import { DocumentState } from "../../types/documents/DocumentTemplate";
import { JobState } from "../../types/documents/Job";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { Link } from "../general/controls/Link";
import { IconAction } from "../general/IconAction";
import type { SxProps, Theme } from "@mui/material";

interface Props {
  jobStateId: JobState;
  template: DocumentTemplate;
  permissions: Permissions | null;
  jobId: string;
  handleRefresh: () => void;
}
type Component = (props: Props) => JSX.Element;

const marginStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(0, 2),
});

export const JobReportActions: Component = ({
  template: { siteVisitReport, lastDocument, id: templateId },
  jobStateId,
  permissions,
  jobId,
  handleRefresh,
}) => {
  const history = useHistory();
  const cancelToken = useCancelToken();

  const hasGodMode = useIsAuthorised(
    permissions,
    appStrings.entities.velappity,
    appStrings.permissions.godMode
  );

  const { documentStateId, pdfGenerated, documentId } = lastDocument ?? {};

  const isSiteVisit = siteVisitReport;
  const showDownloads = !isSiteVisit;
  const showGenerate =
    !isSiteVisit && documentStateId !== DocumentState.Generating;

  const canDownload = documentStateId === DocumentState.Generated;
  const canDownloadPdf = pdfGenerated;
  let canGenerate = true;

  if (
    documentStateId === DocumentState.Generating ||
    jobStateId !== JobState.Review
  ) {
    canGenerate = false;
  }

  if (hasGodMode) {
    canGenerate = true;
  }

  const handleGenerate = async () => {
    try {
      await api.generateDocumentation(jobId, templateId, cancelToken);
      handleRefresh();
    } catch (e) {
      logger.error(e);
    }
  };

  const handleDownload = async (format: "word" | "pdf") => {
    try {
      let downloadDocument = api.getWordDocumentation;
      if (format === "pdf") {
        downloadDocument = api.getPDFDocumentation;
      }

      await downloadDocument(documentId, cancelToken);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleSiteVisitRedirect = () =>
    history.push(paths.jobs.siteVisitResults(jobId));

  return (
    <>
      <ShowIf show={showDownloads}>
        <ShowIf show={canDownload}>
          <IconAction
            tooltip={strings.labels.downloadWord}
            onClick={() => handleDownload("word")}
          >
            <DocumentIcon />
          </IconAction>
        </ShowIf>
        <ShowIf show={canDownloadPdf}>
          <IconAction
            tooltip={strings.labels.downloadPDF}
            onClick={() => handleDownload("pdf")}
          >
            <PDFIcon />
          </IconAction>
        </ShowIf>
      </ShowIf>
      <ShowIf show={showGenerate && canGenerate}>
        <Link
          config={{
            sx: [marginStyles],
            text: strings.labels.generate,
            variant: "body2",
            onClick: handleGenerate,
          }}
        />
      </ShowIf>
      <ShowIf show={isSiteVisit}>
        <IconAction
          tooltip={strings.labels.siteVisitReport}
          onClick={handleSiteVisitRedirect}
        >
          <BarChartIcon />
        </IconAction>
      </ShowIf>
    </>
  );
};
