import { createCtx } from "../hooks/createCtx";
import type { Permissions } from "../types";
export type AuthContext = {
  loggedIn: boolean;
  handleSignIn: () => void;
  handleSignOut: () => void;
  userPermissions: Permissions;
  setUserPermissions: React.Dispatch<React.SetStateAction<Permissions>>;
};

export const [useAuthContext, AuthContextProvider] = createCtx<AuthContext>();
