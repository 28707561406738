import { useCallback } from "react";
import { getValidationProps } from "../../helpers/control-helpers";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api as jobTypesApi } from "../../services/jobtypes.service";
import type { JobDto, JobType } from "../../types/documents";
import { defaultDropdownState, Dropdown } from "../general/controls/Dropdown";
import type { ChangeHandler, CustomProps } from "../general/types/Modify";

interface Props extends CustomProps<JobDto> {}
type Component = (props: Props) => JSX.Element;

const jobTypes: JobType[] = [];

export const JobTypeSelector: Component = ({
  handleChange,
  data: { jobTypeId },
  validationResults,
}) => {
  const cancelToken = useCancelToken();

  const getJobTypes = useCallback(
    async () =>
      jobTypesApi.getJobTypes("published", cancelToken).then((types) => {
        jobTypes.push(...types);
        return types.length
          ? createDropdownOptions(types, "id", "name")
          : defaultDropdownState;
      }),
    [cancelToken]
  );

  const handleJobTypeChange: ChangeHandler = (e, data = {}) => {
    const { selectedOption } = data;
    handleChange(e);
    if (!selectedOption) return;

    const { label, value } = selectedOption;
    const selectedJobType = jobTypes.find((type) => type.id === value);

    handleChange({
      target: {
        name: "name",
        value: label,
      },
    });
    if (selectedJobType) {
      handleChange({
        target: {
          name: "timeInMinutes",
          value: selectedJobType.timeInMinutes,
        },
      });
    }
  };

  return (
    <Dropdown
      config={{
        name: "jobTypeId",
        label: strings.labels.jobTypeId,
        required: true,
        options: getJobTypes,
        value: jobTypeId,
        ...getValidationProps("jobTypeId", validationResults),
      }}
      handleChange={handleJobTypeChange}
    />
  );
};
