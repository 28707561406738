import type { SxProps, Theme } from "@mui/material";
import { Button, FormHelperText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { Link } from "../../components/general/controls/Link";
import { Loader } from "../../components/general/Loader";
import { useAuthContext } from "../../context/AuthContext";
import { useForm } from "../../hooks/general/useForm";
import { completeNewPassword, signInWithDetails } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import type { CognitoUser } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

const marginStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(2, "0"),
});

export const CustomLogin: Component = ({ user }) => {
  const { handleSignIn } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState({ challengeName: "" });
  const [success, setSuccess] = useState(false);

  const [formData, handleChange] = useForm({
    username: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const history = useHistory();

  useEffect(() => {
    if (user || success) {
      handleSignIn();
      history.push(paths.root);
    }
  }, [user, success, history, handleSignIn]);

  const onLogin: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const result = await signInWithDetails(
        formData.username,
        formData.password
      );

      setError("");

      if (result.challengeName) {
        setResult(result);
      } else {
        setSuccess(true);
      }

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      setSuccess(false);
      setError(e ? e : strings.errors.signIn);
    }
  };

  const onChangePassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await completeNewPassword(result, formData.newPassword);

      setLoading(false);
      setError("");
      setResult({ challengeName: "" });

      setSuccess(true);
    } catch (e: any) {
      setLoading(false);
      setSuccess(false);
      setError(e ? e : strings.errors.changePassword);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return (
      <BaseControl control={false} margin>
        {control}
      </BaseControl>
    );
  };

  const renderLoginForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.headers.login}
        </Typography>
        <form noValidate onSubmit={onLogin} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.username,
                name: "username",
                label: strings.labels.username,
                fullWidth: true,
                autoComplete: "username",
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.password,
                name: "password",
                label: strings.labels.password,
                type: "password",
                fullWidth: true,
                autoComplete: "password",
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.login}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formData.username || !formData.password}
            sx={[marginStyles]}
          />
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
        <Link
          key="requestPasswordReset"
          config={{
            text: strings.labels.resetPassword,
            to: paths.auth.requestPasswordReset,
            sx: [marginStyles],
          }}
        />
        <Link
          key="signUp"
          config={{
            text: strings.labels.signUp,
            to: paths.auth.signUp,
          }}
        />
      </>
    );
  };

  const renderNewPasswordForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.headers.changePassword}
        </Typography>
        <form noValidate onSubmit={onChangePassword} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.newPassword,
                name: "newPassword",
                label: strings.labels.newPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "new-password",
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.confirmNewPassword,
                name: "confirmNewPassword",
                label: strings.labels.confirmNewPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "confirm-password",
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={formData.newPassword !== formData.confirmNewPassword}
            sx={[marginStyles]}
          />
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline text={strings.labels.signingIn} />;
    }

    switch (result.challengeName) {
      case strings.challenges.newPassword:
        return renderNewPasswordForm();
      default:
        return renderLoginForm();
    }
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
