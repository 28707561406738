import type { Permissions } from "../types";

const PERMISSION_SEPARATOR = ".";

const parsePermissionString = (formattedPermission: string) => {
  const separatorPosition =
    formattedPermission.lastIndexOf(PERMISSION_SEPARATOR);
  const entity = formattedPermission.substring(0, separatorPosition);
  const permission = formattedPermission.substring(separatorPosition + 1);
  return [entity, permission];
};

export const parseApiPermissions = (apiData: string[]): Permissions => {
  const permissionsObject: Permissions = {};

  for (const formattedPermission of apiData) {
    const [entity, permission] = parsePermissionString(formattedPermission);
    if (!permissionsObject[entity]) permissionsObject[entity] = [];

    permissionsObject[entity].push(permission);
  }

  return permissionsObject;
};
