import type { SxProps, Theme } from "@mui/material";
import { Typography } from "@mui/material";
import { DeleteIcon } from "../../assets/DeleteIcon";
import { PencilIcon } from "../../assets/PencilIcon";
import { ContactModify } from "../../containers/contacts/ContactModify";
import { usePalette } from "../../hooks/general/usePalette";
import { appStrings } from "../../resources/strings/app";
import { contactStrings as strings } from "../../resources/strings/contacts";
import type { CognitoUser, Permissions } from "../../types";
import type { ContactDto } from "../../types/documents/Contact";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { Button } from "../general/Button";
import { IconAction } from "../general/IconAction";
import { Modal } from "../general/Modal";

export interface Props {
  permissions: Permissions | null;
  user: CognitoUser | null;
  data: ContactDto;
  handleDeleteContact: (id: string) => void;
  handleEditContact: (id: string, dto: ContactDto) => void;
}
type Component = (props: Props) => JSX.Element;

const modalConfirmStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.red,
  "&:hover": {
    backgroundColor: theme.palette.common.red,
  },
});

export const ContactListActions: Component = ({
  permissions,
  user,
  data,
  handleDeleteContact,
  handleEditContact,
}) => {
  const { id } = data;

  const palette = usePalette();
  const handleContactChange = (dto: ContactDto) => {
    handleEditContact(id, dto);
  };
  const onDelete = () => {
    handleDeleteContact(id);
  };
  return (
    <>
      <ShowIfAuthorised
        userPermissions={permissions}
        entity={appStrings.entities.organisations}
        permission={[
          appStrings.permissions.editTenant,
          appStrings.permissions.create,
        ]}
      >
        <Modal
          trigger={
            <IconAction tooltip={strings.labels.editContact}>
              <PencilIcon />
            </IconAction>
          }
          header={strings.headers.editContact}
          actions={() => []}
        >
          {({ onClose }) => (
            <ContactModify
              permissions={permissions}
              user={user}
              handleContactChange={handleContactChange}
              handleClose={onClose}
              contactData={data}
            />
          )}
        </Modal>
      </ShowIfAuthorised>
      <ShowIfAuthorised
        userPermissions={permissions}
        entity={appStrings.entities.organisations}
        permission={[appStrings.permissions.delete]}
      >
        <Modal
          trigger={
            <IconAction tooltip={strings.labels.deleteContact}>
              <DeleteIcon sx={[palette.red]} />
            </IconAction>
          }
          header={strings.headers.deleteContact}
          actions={({ onClose }) => [
            <Button label={strings.labels.cancel} onClick={onClose} />,
            <Button
              label={strings.labels.confirm}
              onClick={onDelete}
              sx={[modalConfirmStyles]}
            />,
          ]}
        >
          <Typography>{strings.text.delete}</Typography>
        </Modal>
      </ShowIfAuthorised>
    </>
  );
};
