import type { IFormsDesignerApi } from "@pulsion/forms-designer-2";

import {
  create as createControlTemplate,
  getControls,
  getNewControlId,
  update as updateControlTemplate,
} from "./libs/ControlTemplateService";
import {
  create as createForm,
  get as getFormDetails,
  getFormSection,
  getFormSectionDetails,
  getFormSectionNames,
  getFormSubSection,
  update as updateForm,
} from "./libs/FormTemplateService";
import {
  create as createSectionTemplate,
  getSectionVariants,
  remove as deleteSectionTemplate,
  reorder as reorderSections,
  update as updateSectionTemplate,
} from "./libs/SectionTemplateService";
import {
  create as createSubSectionTemplate,
  remove as deleteSubsectionTemplate,
  reorder as reorderSubSections,
  update as updateSubSectionTemplate,
} from "./libs/SubSectionTemplateService";

export const formDesignerApi: IFormsDesignerApi = {
  createForm,
  deleteSectionTemplate,
  deleteSubsectionTemplate,
  updateForm,
  getFormDetails,
  getFormSection,
  getFormSectionDetails,
  getFormSectionNames,
  getFormSubSection,
  updateControlTemplate,
  updateSubSectionTemplate,
  reorderSections,
  reorderSubSections,
  updateSectionTemplate,
  createControlTemplate,
  createSubSectionTemplate,
  createSectionTemplate,
  getNewControlId,
  getControls,
  getSectionVariants,
};
