export const bulkUploadStrings = {
  headers: {
    bulkUpload: "Bulk Upload",
    fileTypeGuide: "CSV File Type Guide",
    organisation: "Organisation",
    asset: "Asset",
    job: "Job",
    uploadCSV: "Upload CSV",
  },
  labels: {
    help: "Help",
    downloadTemplate: "Download Template",
    uploadCSV: "Upload CSV",
    note: "Note: ",
    fileName: "Filename",
    createdBy: "Created By",
    uploadedDate: "Uploaded Date",
    status: "Status",
    downloadFailedRows: "Download Failed Rows",
    downloadCSV: "Download CSV",
    selectFileType: "Select File Type",
    name: "Name",
    save: "Save",
    cancel: "Cancel",
  },
  fields: {
    organisations: {
      orgType: "Organisation Type",
      organisation: "Organisation",
      externalRef: "External Reference",
      parentExternalRef: "Parent External Reference",
      language: "Language",
      address: "Address 1, Address 2, Address 3, Address 4, Zip, City",
      country: "Country",
      timezone: "Timezone",
      contactType: "Contact Type",
      jobTitle: "Job Title",
      title: "Title",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone",
      mobile: "Mobile",
      email: "Email",
    },
    jobs: {
      formName: "Form Name",
      siteRef: "Site Reference",
      name: "Name",
      allocatedTo: "Allocated To",
      contactFirstName: "Contact Person FirstName",
      contactLastName: "Contact Person LastName",
      scheduledDate: "Scheduled Date",
      scheduledTime: "Scheduled Time",
      externalRef: "External Reference",
      duration: "Duration",
      notes: "Notes",
    },
    assets: {
      location: "Location",
      siteRef: "Site Reference",
      assetTypeRef: "Asset Type Reference",
      externalRef: "External Reference",
      name: "Name",
      description: "Description",
      assetNum: "Asset Number",
      serialNum: "Serial Number",
      notes: "Notes",
    },
  },
  descriptions: {
    organisations: {
      orgType: "This will be, for example, Client or Site.",
      organisation: "The name of the organisation.",
      externalRef:
        "A unique reference to distinguish one organisation with another. For example, if the organisations that you are adding to Velappity are stored in another system then you can use their unique reference or ID here.",
      parentExternalRef:
        'The external reference of the organisation to add this organisation to. For example, if you have a Client with a external ref of "CO123" and you would like to add a Site with external ref of "SUBCO456" then the Organisation Type will be "Site" and External Reference will be "SUBCO456" and Parent External Reference will be "CO123".',
      language:
        "The default language for the organisation, currently only English is supported.",
      address: "The address of the organisation.",
      country:
        "The country where the organisation is located, e.g. United Kingdom.",
      timezone:
        "The time zone to be used for the organisation, e.g. Europe/London.",
      contactType: "The type of the contact.",
      jobTitle: "The job title of the contact.",
      title: "The title of the contact.",
      firstName: "The first name of the contact.",
      lastName: "The last name of the contact.",
      phone: "The phone number of the contact.",
      mobile: "The mobile number of the contact.",
      email: "The email address of the contact.",
    },
    jobs: {
      formName: "The name of the form that this job will be based on.",
      siteRef: "The external reference of the site this job will relate to.",
      name: "The job's name.",
      allocatedTo:
        'The email address of the user this job will be assigned to, or the default user of the related site. To assign to the default user place the word "default" in this field instead of the user\'s email address.',
      contactFirstName: "The the first name of the site contact.",
      contactLastName: "The the last name of the site contact.",
      scheduledDate: "The date when this job should occur.",
      scheduledTime: "The specific time this job should occur.",
      externalRef:
        "A unique reference to distinguish one job with another. For example, if the job that you are adding to Velappity are stored in another system then you can use their unique reference/ID here.",
      duration:
        "The length of time in minutes that this job should take to complete.",
      notes: "General job notes.",
    },
    assets: {
      location: "The name of the location where the asset is situated on Site.",
      siteRef: "The external reference of the site to add this asset to.",
      assetTypeRef: "The asset type external reference of the asset.",
      externalRef:
        "A unique reference to distinguish one asset with another. For example, if the asset that you are adding to Velappity are stored in another system then you can use their unique reference or ID here.",
      name: "The name of the asset.",
      description: `The asset's description.`,
      assetNum: "A unique value to identify this asset.",
      serialNum: "The serial number or barcode of the asset.",
      notes: "General asset notes.",
    },
  },
  text: {
    bulkUploadNote:
      "Assets uploaded via the Bulk Upload process currently will not be reflected in any ongoing Monitoring Regimes. If you need to add new Assets using the bulk upload and have them appear on Monitoring Regime Jobs, then you will need to recreate the Monitoring Regimes after doing the upload.",
    mandatoryFieldIndication: "indicates a mandatory field",
  },
};
