export const formStrings = {
  header: {
    formsList: "Forms List",
  },
  labels: {
    createForm: "Create New Form",
    name: "Form Name",
    version: "Version",
    state: "State",
    actions: "Actions",
    deleteForm: "Delete Form",
    deleteVersion: "Delete Version",
    search: "Search",
    copy: "Copy",
    copyForm: "Copy form",
    view: "Edit",
    template: "Template",
    export: "Export",
    unpublish: "Unpublish",
    publish: "Publish",
    showFormGroups: "Show form groups",
    formGroups: {
      published: "Published",
      new: "New",
      unpublished: "Unpublished",
    },
    cancel: "Cancel",
    newVersion: "New version",
    newForm: "New form",
    ok: "OK",
    publishForm: "Publish form",
    removeJobs: "Remove Jobs",
    keepJobs: "Keep Jobs",
    reset: "Reset",
    downloadWordPlugin: "Download Word Plugin",
    wordPluginHelp: "Word Plugin Help",
    formsDesigner: "Forms Designer",
  },
  placeholders: {
    search: "Search",
  },
  text: {
    copyForm:
      "Would you like to create a new form or a new version of the form?",
    deleteVersion: "Are you sure you wish to delete this version?",
    deleteVersionJobsWarning: (numOfJobs: number) =>
      `There are ${numOfJobs} jobs using this form version, you can either remove these from the system or keep them. If removed, you will lose any data which is still on a device.`,
    deleteForm: "Are you sure you wish to delete this form?",
    deleteFormJobsWarning: (numOfJobs: number) =>
      `There are ${numOfJobs} jobs using a version of this form, you can either remove these from the system or keep them. If removed, you will lose any data which is still on a device.`,
    publishForm:
      "There is already a published version of this form. In order to proceed, this will first be unpublished.",
    publishFormRecurrencesWarning: (numOfRecurrences: number) =>
      `There are ${numOfRecurrences} recurrences using this form.`,
    publishFormRecurrencesRemoveWarning: "These will be removed.",
  },
  errors: {
    relationshipCheckError: "Unable to check for related jobs and recurrences.",
  },
};
