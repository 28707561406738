import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { JobIcon, SiteIcon } from "../../assets";
import { PencilIcon } from "../../assets/PencilIcon";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import type { Permissions } from "../../types";
import { ShowIf, ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { IconAction } from "../general/IconAction";

interface Props {
  organisationId: string;
  getViewPath: (id: string) => string;
  editActionLabel: string;
  jobsActionLabel: string;
  permissions: Permissions | null;
  isParentOrganisation: boolean;
  viewChildLabel?: string;
  getViewChildPath?: (id: string) => string;
}
type Component = (props: Props) => JSX.Element;

export const OrganisationListActions: Component = ({
  organisationId,
  getViewPath,
  editActionLabel,
  jobsActionLabel,
  permissions,
  isParentOrganisation,
  viewChildLabel = "",
  getViewChildPath,
}) => {
  const history = useHistory();
  const redirectToJobs = () => history.push(paths.jobs.list(organisationId));
  const redirectToView = () => {
    const viewPath = getViewPath(organisationId);
    history.push(viewPath);
  };
  const redirectToChild = () => {
    if (!getViewChildPath) return;
    const viewPath = getViewChildPath(organisationId);
    history.push(viewPath);
  };
  return (
    <Box display="flex" justifyContent="flex-end">
      <ShowIfAuthorised
        entity={appStrings.entities.organisations}
        permission={appStrings.permissions.editTenant}
        userPermissions={permissions}
      >
        <IconAction tooltip={editActionLabel} onClick={redirectToView}>
          <PencilIcon />
        </IconAction>
      </ShowIfAuthorised>
      <IconAction tooltip={jobsActionLabel} onClick={redirectToJobs}>
        <JobIcon />
      </IconAction>
      <ShowIf
        show={Boolean(
          isParentOrganisation && viewChildLabel && getViewChildPath
        )}
      >
        <IconAction tooltip={viewChildLabel} onClick={redirectToChild}>
          <SiteIcon />
        </IconAction>
      </ShowIf>
    </Box>
  );
};
