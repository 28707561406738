import { useCallback } from "react";
import { formatDateTime } from "../../helpers/date-helpers";
import type { TableDefinition } from "../../helpers/export-helpers";
import { displayAllocatedTo, displaySite } from "../../helpers/job-helpers";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api } from "../../services/jobs.service";
import type { Permissions } from "../../types";
import type { Job, JobFilters } from "../../types/documents";
import { CreateEntityButton } from "../general/CreateEntityButton";
import type { GetExportData } from "../general/CSVExportButton";
import { CSVExportButton } from "../general/CSVExportButton";

interface Props {
  permissions: Permissions | null;
  filters: JobFilters;
  totalCount?: number;
  page?: number;
  size?: number;
}
type Component = (props: Props) => JSX.Element;

const jobTableDefinition: TableDefinition<Job>[] = [
  {
    Header: strings.labels.jobReferenceCode,
    accessor: "jobReferenceCode",
  },
  {
    Header: strings.labels.name,
    accessor: "name",
  },
  {
    Header: strings.labels.clientName,
    accessor: "clientName",
  },
  {
    Header: strings.labels.siteName,
    accessor: (job) => displaySite(job),
  },
  {
    Header: strings.labels.scheduledDate,
    accessor: ({ scheduledDate }) => formatDateTime(scheduledDate),
  },
  {
    Header: strings.labels.allocatedTo,
    accessor: (job) => displayAllocatedTo(job),
  },
  {
    Header: strings.labels.state,
    accessor: ({ state = {} }) => {
      const { name = "" } = state;
      return name;
    },
  },
  {
    Header: strings.labels.completedDate,
    accessor: ({ completedDate }) =>
      completedDate ? formatDateTime(completedDate) : "",
  },
];

export const JobListButtons: Component = ({
  permissions,
  filters,
  totalCount = 0,
  page = 0,
  size = 10,
}) => {
  const getExportData: GetExportData = useCallback(
    (cancelToken) =>
      api.exportJobs(
        {
          query: filters,
          size: totalCount,
          skip: page * size,
        },
        cancelToken
      ),
    [filters, totalCount, page, size]
  );

  return (
    <>
      <CSVExportButton<Job>
        getExportData={getExportData}
        tableDefinition={jobTableDefinition}
        fileName="job-list.csv"
        totalCount={totalCount}
      />
      <CreateEntityButton
        config={{
          label: strings.labels.createJob,
          userAccess: {
            entity: appStrings.entities.jobs,
            permission: [
              appStrings.permissions.createForAnyUser,
              appStrings.permissions.createAdhocJob,
            ],
          },
          path: paths.jobs.create,
        }}
        permissions={permissions}
      />
    </>
  );
};
