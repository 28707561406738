import { Grid, styled } from "@mui/material";
import { jobStrings as strings } from "../../resources/strings/jobs";
import type { DropdownOptions, FilterChangeHandler } from "../../types";
import type { JobFilters } from "../../types/documents";
import { BaseControl } from "../general/controls/BaseControl";
import { Dropdown } from "../general/controls/Dropdown";
import { Link } from "../general/controls/Link";
import type { ChangeHandler } from "../general/types/Modify";
import { JobInputFilters } from "./filters/JobInputFilters";
import type { SxProps, Theme } from "@mui/material";

interface Props {
  filters: JobFilters;
  handleFilterChange: FilterChangeHandler;
  clearFilters: () => void;
  tags: {
    jobStates: DropdownOptions;
  };
}
type Component = (props: Props) => JSX.Element;

const Span = styled("span")({});

const paddingStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(1.5, 3, 0),
});

export const JobSearchBar: Component = ({
  filters: { searchString, dateFrom, dateTo, stateIds = [] },
  handleFilterChange,
  clearFilters,
  tags: { jobStates },
}) => {
  const handleChange: ChangeHandler = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    handleFilterChange(name, value);
  };

  return (
    <Grid container spacing={1} role="searchbox">
      <Grid item xs>
        <JobInputFilters
          filters={{ searchString, dateFrom, dateTo }}
          handleFilterChange={handleChange}
        />
      </Grid>
      <Grid item xs={3}>
        <BaseControl control={false}>
          <Dropdown
            config={{
              name: "stateIds",
              placeholder: strings.placeholders.showAll,
              label: strings.labels.showStates,
              options: jobStates,
              value: stateIds,
              SelectProps: {
                multiple: true,
              },
            }}
            handleChange={handleChange}
          />
        </BaseControl>
      </Grid>
      <Span sx={[paddingStyles]}>
        <Link
          config={{
            text: strings.labels.reset,
            variant: "body2",
            onClick: clearFilters,
          }}
        />
      </Span>
    </Grid>
  );
};
