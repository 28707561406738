export const organisationStrings = {
  headers: {
    organisationDetails: "Details",
    address: "Address",
    language: "Language & Time Zone",
    contacts: "Contacts",
    documents: "Documents",
    locations: "Locations",
    users: "Users",
    loginDetails: "Login Details",
    userDetails: "User Details",
    permissions: "Permissions",
    deleteUser: "Delete User",
    grantUserAccess: "Grant User Access",
  },
  labels: {
    parent: "Client",
    name: "Name",
    address: "Address",
    reset: "Reset",
    search: "Search",
    externalReference: "External Reference",
    image: "Image",
    address1: "Address 1",
    address2: "Address 2",
    city: "City/Town",
    county: "State/Province/Region",
    zip: "ZIP/Postal Code",
    country: "Country",
    timezone: "Time Zone",
    delete: "Delete",
    manageUsers: "Manage Users",
    cancel: "Cancel",
    confirm: "Confirm",
    mapLocation: "See location on a map",
    userName: "Name",
    email: "Email",
    userType: "User Type",
    accessFromParentOrganisation: "Access From Parent Organisation",
    createUser: "Create User",
    editUser: "Edit User",
    save: "Save",
    password: "Password",
    confirmPassword: "Confirm Password",
    title: "Title",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone",
    mobileNumber: "Mobile",
    roles: "Role",
    grantUserAccess: "Grant User Access",
    submit: "Submit",
  },
  placeholders: {
    searchString: "Search",
    selectUsers: "Select user(s)",
  },
  text: {
    delete: "This is a permanent action and cannot be undone. Are you sure?",
    helpText:
      "Select the user(s) you would like to provide acess to this organisation",
  },
  ariaLabels: {
    organisationDetails: "Details",
    address: "Address",
    language: "Language & Time Zone",
    contacts: "Contacts",
    documents: "Documents",
    locations: "Locations",
    loginDetails: "Login Details",
    userDetails: "User Details",
    permissions: "Permissions",
  },
};
