import type { CircularProgressProps, SxProps, Theme } from "@mui/material";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { appStrings as strings } from "../../resources/strings/app";

interface Props {
  active: boolean | undefined;
  children?: JSX.Element;
  inline?: boolean;
  text?: string;
  compact?: boolean;
}
type Component = (props: Props) => JSX.Element | null;

const containerStyles: SxProps<Theme> = (theme) => ({
  display: "flex",
  backgroundColor: "inherit",
  color: theme.palette.primary.main,
});

const marginStyles: SxProps<Theme> = {
  margin: "0.5rem",
};

const inlineStyles: SxProps<Theme> = {
  alignContent: "center",
  flexWrap: "wrap",
  justifyContent: "center",
};

const rootStyles: SxProps<Theme> = {
  flexDirection: "column",
  alignItems: "center",
};

export const Loader: Component = ({
  active = false,
  children = null,
  inline = false,
  text = strings.labels.loading,
  compact = false,
}) => {
  const LoaderContent = (props: CircularProgressProps) => (
    <>
      <CircularProgress
        sx={[!compact && marginStyles]}
        color="inherit"
        {...props}
      />
      {text ? <Typography sx={[marginStyles]}>{text}</Typography> : null}
    </>
  );

  const renderLoader = () => {
    return inline ? (
      <Grid container sx={[containerStyles, inlineStyles]}>
        <LoaderContent size="1.25rem" />
      </Grid>
    ) : (
      <Backdrop sx={[containerStyles, rootStyles]} open>
        <LoaderContent />
      </Backdrop>
    );
  };

  return active ? <Box>{renderLoader()}</Box> : children;
};
