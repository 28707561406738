export const siteStrings = {
  headers: {
    siteList: "Site List",
    createSite: "Create Site",
    editSite: "Edit Site",
    deleteSite: "Delete Site",
    site: "Site",
  },
  labels: {
    createSite: "Create Site",
    editSite: "Edit Site",
    viewJobs: "View Jobs for the Site",
    saveSite: "Save",
  },
};
