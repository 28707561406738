import { parseApiPermissions } from "../helpers/permission-helpers";
import axios from "../libs/axios/axios-permissions";
import type { Permissions } from "../types";
import type { ReadFunction } from "../types/API";

type Response = string[];

const read: ReadFunction<Permissions> = async (_id, _childId, cancelToken?) => {
  const { data } = await axios.get<Response>("/currentuser", {
    cancelToken,
  });
  const permissions = parseApiPermissions(data);
  return {
    item: permissions,
  };
};

export const api = {
  read,
};
