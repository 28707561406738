import type { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation/paths";
import { clientStrings as strings } from "../../resources/strings/clients";
import type { RouteProps } from "../../types";
import { OrganisationUserModify } from "../organisations/OrganisationUserModify";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const ClientUserModify: Component = ({ mode, ...routeProps }) => {
  return (
    <OrganisationUserModify
      {...routeProps}
      mode={mode}
      organisationListHeader={strings.headers.clientList}
      organisationListPath={paths.clients.list}
      getViewOrganisationPath={paths.clients.view}
      viewOrganisationHeader={strings.headers.client}
      getUsersListPath={paths.clients.users.list}
    />
  );
};
