import { useCallback } from "react";
import { FileUpload, PreviewType } from "../../components/general/FileUpload";
import type { OrganisationDto } from "../../types/documents/Organisation";
import type { CustomProps } from "../general/types/Modify";

interface Props extends CustomProps<OrganisationDto> {}
type Component = (props: Props) => JSX.Element;

const allowedFileTypes = ["image/jpeg", "image/png"];

export const OrganisationImageUpload: Component = ({
  data: { image },
  handleChange,
}) => {
  const handleUpload = useCallback(
    async (fileResult) => {
      handleChange({
        target: {
          name: "image",
          value: fileResult.data,
        },
      });
    },
    [handleChange]
  );
  return (
    <>
      <FileUpload
        maxSize={5120}
        allowedFileTypes={allowedFileTypes}
        handleUpload={handleUpload}
        previewType={PreviewType.Image}
        previewData={image}
      />
    </>
  );
};
