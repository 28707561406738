import axios from "../libs/axios/axios-bulkuploads";
import type { QueryFunction } from "../types/API";
import type { BulkUpload } from "../types/documents";

const query: QueryFunction<BulkUpload> = async (_body, cancelToken) => {
  const { data } = await axios.get<BulkUpload[]>("/getall", { cancelToken });
  return { items: data, totalCount: data.length };
};

export const api = { query };
