import { useEffect, useState } from "react";
import { logger } from "../../helpers/log-helpers";
import type { DropdownOptions } from "../../types";
import { HooksLogger } from "../hooks-logger";

const hooksLogger = new HooksLogger("useDropdownOptions");

export const useDropdownOptions = (
  input: DropdownOptions | (() => Promise<DropdownOptions>)
) => {
  const [options, setOptions] = useState<DropdownOptions>(() =>
    Array.isArray(input) ? input : []
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (typeof input === "function") {
      if (mounted) setLoading(true);
      input()
        .then((result) => {
          hooksLogger.success(result);
          if (mounted) {
            setOptions(result);
            setLoading(false);
          }
        })
        .catch((error) => {
          const message = logger.error(error);
          hooksLogger.error(message);
          if (mounted) {
            setLoading(false);
          }
        });
    }
    return () => {
      mounted = false;
    };
  }, [input]);
  return [options, loading] as const;
};
