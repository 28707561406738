import { FormDesignerTheme } from "@pulsion/forms-designer-2";
import { theme } from "../../../styles/theme";
export const formDesignerThemes: FormDesignerTheme = {
  colors: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    modalHeader: `${theme.palette.common.darkgrey}`,
  },
  fonts: [`${theme.typography.fontFamily}`],
  font: {
    primary: {
      family: theme.typography.fontFamily,
      color: theme.palette.text.primary,
    },
    secondary: {
      family: `${theme.typography.fontFamily}`,
      color: theme.palette.text.secondary,
      size: "15px",
    },
  },
  button: {
    fontSize: "16px",
  },
  containers: {
    root: {
      boxShadow: "none",
      border: "none",
      borderRadius: "0px",
      textAlign: "left",
    },
    section: {
      boxShadow: "none",
      border: `1px solid ${theme.palette.common.grey}`,
      borderRadius: "0px",
      textAlign: "left",
      margin: "1rem 0",
    },
  },
  headers: {
    color: theme.palette.common.darkgrey,
    fontWeight: "bold",
    textTransform: "none",
    textAlign: "left",
    lineHeight: "1.75",
    fontSize: "1.1428571428571428rem",
  },
  fields: {
    borderRadius: "0",
    border: `1px solid ${theme.palette.common.grey}`,
    padding: "8px",
    background: theme.palette.common.lightgrey,
  },
  submitButtonContainer: {},
  buttons: {
    root: {
      borderRadius: "0",
      boxShadow: "none",
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    hover: {
      borderRadius: "0",
      boxShadow: "none",
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
    dndControl: {
      root: {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: "0",
        boxShadow: "none",
        color: theme.palette.common.darkgrey,
        backgroundColor: theme.palette.common.lightgrey,
        width: "40px",
        height: "40px",
        marginRight: "10px",
      },
      hover: {
        border: `1px solid ${theme.palette.common.grey}`,
        borderRadius: "0",
        boxShadow: "none",
        color: theme.palette.common.lightgrey,
        backgroundColor: theme.palette.primary.main,
        width: "40px",
        height: "40px",
        marginRight: "10px",
      },
    },
    icon: {
      verticalAlign: "sub",
    },
  },
  buttonSetControl: {},
  sectionContainer: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  sectionMenu: {
    container: {
      marginTop: "0 !important",
      padding: 0,
      border: "none !important",
      borderRadius: 0,
    },
    item: {
      hover: {
        backgroundColor: "#eeeeee",
      },
      root: {
        marginBottom: "20px !important",
        border: `1px solid ${theme.palette.common.grey}`,
        padding: "15px 20px",
      },
      add: {
        border: `1px dashed ${theme.palette.common.grey}`,
        textAlign: "center",
        padding: "15px 20px",
      },
    },
    selected: {
      borderLeft: "none",
      borderTop: `4px solid ${theme.palette.primary.main} !important`,
    },
  },
  subsections: {
    all: {
      borderRadius: "0",
      borderColor: theme.palette.common.grey,
    },
    adjacent: {
      borderRadius: "0",
      borderColor: theme.palette.common.grey,
      marginTop: "14px",
    },
    header: {
      backgroundColor: theme.palette.common.lightgrey,
    },
  },
  sectionColumns: {
    container: {
      borderTop: 0,
    },
    sectionDisplay: {
      marginTop: 0,
      borderLeft: 0,
    },
  },
  section: {
    borderRadius: 0,
  },
};
