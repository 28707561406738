import { bulkUploadStrings as strings } from "../../../resources/strings/settings/bulkUpload";
import type { Field } from "../../general/FieldDetailsDisplay";
import { FieldDetailsDisplay } from "../../general/FieldDetailsDisplay";

interface Props {}
type Component = (props: Props) => JSX.Element;

const labels = strings.fields.jobs;
const descriptions = strings.descriptions.jobs;

const fields: Array<Field> = [
  {
    key: "siteRef",
    label: labels.siteRef,
    description: descriptions.siteRef,
    required: true,
  },
  {
    key: "formName",
    label: labels.formName,
    description: descriptions.formName,
    required: true,
  },
  {
    key: "name",
    label: labels.name,
    description: descriptions.name,
    required: true,
  },
  {
    key: "allocatedTo",
    label: labels.allocatedTo,
    description: descriptions.allocatedTo,
    required: false,
  },
  {
    key: "contactFirstName",
    label: labels.contactFirstName,
    description: descriptions.contactFirstName,
    required: false,
  },
  {
    key: "contactLastName",
    label: labels.contactLastName,
    description: descriptions.contactLastName,
    required: false,
  },
  {
    key: "scheduledDate",
    label: labels.scheduledDate,
    description: descriptions.scheduledDate,
    required: true,
  },
  {
    key: "scheduledTime",
    label: labels.scheduledTime,
    description: descriptions.scheduledTime,
    required: true,
  },
  {
    key: "externalRef",
    label: labels.externalRef,
    description: descriptions.externalRef,
    required: false,
  },
  {
    key: "duration",
    label: labels.duration,
    description: descriptions.duration,
    required: false,
  },
  {
    key: "notes",
    label: labels.notes,
    description: descriptions.notes,
    required: false,
  },
];

export const JobHelpPanel: Component = () => {
  return <FieldDetailsDisplay fields={fields} />;
};
