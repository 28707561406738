import {
  Box,
  Checkbox as UICheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { ChangeEventHandler } from "react";
import { useDropdownOptions } from "../../../hooks/general/useDropdownOptions";
import { useTagGroup } from "../../../hooks/tags/queries/useTagGroup";
import { Loader } from "../Loader";
import type { Checkbox as TCheckbox } from "../types/Modify";

interface Props<D extends object> {
  config: Omit<TCheckbox<D>, "controltype">;
  checkData: Array<unknown>;
  setFormData: React.Dispatch<React.SetStateAction<D>>;
  errorMessage?: string;
}

export const Checkbox = <D extends object>({
  config: input,
  setFormData,
  checkData = [],
  errorMessage,
}: Props<D>): JSX.Element => {
  const {
    label,
    tagConfig,
    options: inputOptions = [],
    multiple,
    ...config
  } = input;
  // check the props to make sure we dont trigger a fetch from useDropdownOptions
  // and useTagGroup
  const tagGroup =
    tagConfig?.tagGroup && typeof inputOptions !== "function"
      ? tagConfig.tagGroup
      : "";

  const [dropdownOptions, dropdownOptionsLoading] =
    useDropdownOptions(inputOptions);
  const [options, tagsLoading] = useTagGroup(dropdownOptions, tagGroup);
  const loading = dropdownOptionsLoading || tagsLoading;

  // define a custom on change
  // we want to group any checkboxes provided as a single value
  // rather than Material UI default which would set a boolean for each checkbox
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked } = e.target;

    let newValue: unknown[] = [];
    if (multiple) {
      newValue = [...checkData];

      const valueExists = checkData.includes(value);
      if (checked && !valueExists) {
        newValue.push(value);
      } else if (!checked && valueExists) {
        newValue = newValue.filter((v) => v !== value);
      }
    } else {
      newValue = [value];
    }

    setFormData((prev) => ({
      ...prev,
      [config.name]: newValue,
    }));
  };

  return (
    <Box>
      <FormControl
        component="fieldset"
        error={!!errorMessage}
        fullWidth
        title={config.name}
      >
        <Box display="flex" flexDirection="row">
          <FormLabel
            component="legend"
            required={config.required}
            focused={false}
          >
            {label}
          </FormLabel>
        </Box>
        <FormGroup>
          <Loader active={loading} inline>
            <>
              {options.map((option, index) => (
                <FormControlLabel
                  key={`${option.value}-${index}`}
                  control={
                    <UICheckbox
                      {...config}
                      value={option.value}
                      checked={checkData.includes(option.value)}
                      color="primary"
                      onChange={onChange}
                    />
                  }
                  label={option.label}
                />
              ))}
            </>
          </Loader>
        </FormGroup>
        {errorMessage ? (
          <FormHelperText error>{errorMessage}</FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
};
