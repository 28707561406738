export type UserType = {
  id: string;
  name: string;
};

export enum UserTypes {
  Compliance = "1aae47f0-d6dc-4337-9623-2dc8938c729c",
  Client = "43cf3a83-b476-46a8-babd-94b1dbb84e8d",
  Forms = "29227d3d-3b2c-4663-8aae-521207f2757c",
}
