import axios from "../libs/axios/axios-titles";
import { ReadFunction } from "../types/API";
import { TitleDto } from "../types/documents/Title";

const getTitles: ReadFunction<TitleDto[]> = async (
  _id,
  _childId,
  cancelToken
) => {
  const { data } = await axios.get<TitleDto[]>("", { cancelToken });
  return { item: data };
};

export const api = { getTitles };
