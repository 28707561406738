import instance from "./axios";
import config from "./config";

const submitFormData = (formData: any) => {
  instance.post("/formentityextraction/import", formData);
};

const setupSectionData = (jobId: string, sectionData: any) => {
  const sectionJSONData = JSON.parse(sectionData.sectionFormData);
  // subsection state is stripped because it is not used by the API
  sectionJSONData.subsections &&
    sectionJSONData.subsections.forEach((x: any) => delete x.state);

  const {
    createdDate,
    fqn,
    gridInput,
    repeatable,
    sectionTypeId,
    template,
    sectionCaption,
    sectionId,
    sectionVariantId,
  } = sectionData;

  return {
    createdDate,
    fqn,
    gridInput,
    repeatable,
    sectionTypeId,
    template,
    caption: sectionCaption,
    id: sectionId,
    jobId: jobId,
    visible: true,
    active: true,
    state: sectionData.state,
    sectionJson: sectionJSONData,
    sectionVariantId,
  };
};

const getJobFormData = async (jobId: string) => {
  const res = await instance({
    url: `/jobs/${jobId}/formData?excludeTemplateSections=false`,
  });
  return res.data;
};

const getFormListData = async () => {
  const res = await instance({
    url: `/jobtypes`,
    method: "get",
  });
  return res.data;
};

const updateSectionData = async (
  formData: any,
  selectedSectionId: string,
  jobId: string
) => {
  if (selectedSectionId) {
    const sectionData = getSectionData(formData, selectedSectionId);
    try {
      JSON.parse(sectionData.sectionFormData);
    } catch (error) {
      throw new Error("Invalid Section Data submitted");
    }

    const preparedSectionData = setupSectionData(jobId, sectionData);
    await instance({
      url: config.buildSectionRoute(selectedSectionId),
      method: "put",
      data: preparedSectionData,
    });
  }
};

const saveRow = async (
  formData: any,
  selectedSectionId: string,
  jobId: string
) => {
  const sectionData = getSectionData(formData, selectedSectionId);
  try {
    JSON.parse(sectionData.sectionFormData);
  } catch (error) {
    throw new Error("Invalid Section Data submitted");
  }

  const preparedSectionData = setupSectionData(jobId, sectionData);
  await instance({
    url: "/sections/savesection",
    method: "post",
    data: preparedSectionData,
  });
};

const saveRowReordering = async (
  _jobId: string,
  _orderedGridSections: any[]
) => {
  // Velappity backend is not set up for row reordering so reordering of rows won't be saved
  // But we use a mock async function in its place to simulate the API call
  await resolveAfter1Second("resolved");
};

const addRepeatableSection = async (jobId: string, section: object) => {
  const res = await instance({
    url: config.createSection,
    method: "post",
    data: { jobId: jobId, sectionTypeId: section },
  });
  return res.data;
};

const deleteCopyOfRepeatableSection = async (
  jobId: string,
  sectionId: string
) => {
  const res = await instance({
    url: config.deactivateSection(jobId, sectionId),
    method: "delete",
  });
  return res.data;
};

const getTemplateOnlySections = async (jobId: string) => {
  const res = await instance({
    url: `/sectionTypes/repeatablesectionsforjob/${jobId}`,
    method: "get",
  });
  return res.data;
};

const updateAttachmentMetadata = async (
  jobId: string,
  attachmentId: string,
  fileExtension: string
) => {
  const jobAttachmentMetaDataDto = { id: attachmentId, jobId, fileExtension };

  const res = await instance({
    url: `/jobs/uploadattachment`,
    method: "post",
    data: jobAttachmentMetaDataDto,
  });
  return res.data;
};

const uploadAttachment = async (attachmentId: string, file: any) => {
  const res = await instance({
    url: `jobs/uploadattachment/${attachmentId}/base64`,
    method: "put",
    data: file,
  });
  return res.data;
};

const getControlAttachment = async (attachmentId: string) => {
  const res = await instance({
    url: `/controlattachments/${attachmentId}/base64`,
    method: "get",
  });
  return res.data;
};

const getAllJobStates = () => {
  return instance({
    url: "/jobstates/",
    method: "get",
  }).then((res) => res.data);
};

const getJobData = (id: string) => {
  return instance({
    url: `/jobs/${id}`,
    method: "get",
  });
};

const getStoredSections = async () => {
  // Velappity backend is not set up for addition of stored sections
  // But we use a mock async function in its place to simulate the API call and return data
  const data = {
    tenantStoredSections: [],

    globalStoredSections: [],
  };
  return await resolveAfter1Second(data);
};

const addStoredSection = async (
  _jobId: string,
  _sectionToAddId: any,
  _sectionTenantOrgId: string
) => {
  // Velappity backend is not set up for addition of stored sections
  // But we use a mock async function in its place to simulate the API call
  await resolveAfter1Second("resolved");
};

function getSectionData(formData: Array<any>, sectionId: string) {
  return formData.find((section) => section?.sectionId === sectionId);
}

function resolveAfter1Second(data: any) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 1000);
  });
}

export {
  getJobFormData,
  updateSectionData,
  addRepeatableSection,
  deleteCopyOfRepeatableSection,
  setupSectionData,
  submitFormData,
  getTemplateOnlySections,
  updateAttachmentMetadata,
  getControlAttachment,
  uploadAttachment,
  getAllJobStates,
  getJobData,
  getFormListData,
  saveRow,
  saveRowReordering,
  getStoredSections,
  addStoredSection,
};
