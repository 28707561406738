import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import type { CustomProps, Mode } from "../../components/general/types/Modify";
import { OrganisationUserBreadcrumbHeader } from "../../components/organisations/OrganisationUserBreadcrumbHeader";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { api as rolesApi } from "../../services/roles.service";
import { api as titlesApi } from "../../services/titles.service";
import { api } from "../../services/users.service";
import { api as userTypesApi } from "../../services/usertypes.service";
import type { RouteProps, ValidationConstraints } from "../../types";
import { Roles } from "../../types/documents/Role";
import type { UserDto } from "../../types/documents/User";
import { UserType, UserTypes } from "../../types/documents/UserType";
import { ModifyContainer } from "../general/ModifyContainer";

export interface Props extends RouteProps {
  mode: Mode;
  organisationId?: string;
  organisationListHeader?: string;
  organisationListPath?: string;
  viewOrganisationHeader?: string;
  getViewOrganisationPath?: (organisationId: string) => string;
  getUsersListPath: (organisationId: string) => string;
}
type Component = (props: Props) => JSX.Element;

const constraints: ValidationConstraints<UserDto> = {
  email: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.email} is required`,
    },
  },
  password: {
    prerequisites: {
      fieldNames: ["mode"],
      fieldValues: ["create"],
      message: `^${strings.labels.password} is required`,
    },
  },
  confirmPassword: {
    prerequisites: {
      fieldNames: ["mode"],
      fieldValues: ["create"],
      message: `^${strings.labels.confirmPassword} is required`,
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.firstName} is required`,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.lastName} is required`,
    },
  },
  roleNames: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.roles} is required`,
    },
  },
  userTypeId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.userType} is required`,
    },
  },
};

const userTypes: UserType[] = [];

const useTagResolvers = () => {
  const cancelToken = useCancelToken();
  const getRoles = useCallback(
    async () =>
      rolesApi
        .getRoles("id", "", cancelToken)
        .then(({ item }) => createDropdownOptions(item, "name", "name")),
    [cancelToken]
  );
  const getUserTypes = useCallback(
    async () =>
      userTypesApi.getUserTypes("id", "", cancelToken).then(({ item }) => {
        userTypes.push(...item);
        return createDropdownOptions(item, "id", "name");
      }),
    [cancelToken]
  );
  const getTitles = useCallback(
    async () =>
      titlesApi
        .getTitles("id", "", cancelToken)
        .then(({ item }) => createDropdownOptions(item, "id", "name")),
    [cancelToken]
  );
  return {
    getRoles,
    getUserTypes,
    getTitles,
  };
};

type RenderBreadcrumbHeaderProps = Pick<
  Props,
  | "organisationListHeader"
  | "organisationListPath"
  | "viewOrganisationHeader"
  | "getViewOrganisationPath"
  | "getUsersListPath"
>;

const renderBreadcrumbHeader =
  (orgProps: RenderBreadcrumbHeaderProps) => (props: CustomProps<UserDto>) =>
    <OrganisationUserBreadcrumbHeader {...props} {...orgProps} />;

const isClientRole = (roleNames: string[] = []) =>
  roleNames.includes(Roles.ClientPortalUser);

const DataChangeListener = ({ data, setFormData }: CustomProps<UserDto>) => {
  useEffect(() => {
    if (!isClientRole(data.roleNames)) return;
    setFormData((prev) => ({ ...prev, userTypeId: [UserTypes.Client] }));
  }, [data.roleNames, setFormData]);
  useEffect(() => {
    const userTypeOption = userTypes.find((type) =>
      data.userTypeId.includes(type.id)
    );
    if (!userTypeOption) return;
    setFormData((prev) => ({ ...prev, userTypeName: userTypeOption.name }));
  }, [data.userTypeId, setFormData]);
  return <></>;
};

export const OrganisationUserModify: Component = ({
  mode,
  organisationId: propsOrganisationId = "",
  organisationListHeader,
  organisationListPath,
  viewOrganisationHeader,
  getViewOrganisationPath,
  getUsersListPath,
  ...routeProps
}) => {
  const { getRoles, getUserTypes, getTitles } = useTagResolvers();
  const { organisationId = propsOrganisationId } = useParams<{
    organisationId: string;
  }>();

  const breadcrumb = useMemo(
    () =>
      renderBreadcrumbHeader({
        organisationListHeader,
        organisationListPath,
        viewOrganisationHeader,
        getViewOrganisationPath,
        getUsersListPath,
      }),
    [
      organisationListHeader,
      organisationListPath,
      viewOrganisationHeader,
      getViewOrganisationPath,
      getUsersListPath,
    ]
  );

  return (
    <ModifyContainer<UserDto>
      {...routeProps}
      api={api}
      mode={mode}
      initialData={{
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        userTypeId: [],
        userTypeName: "",
        externalReference: "",
        mobileNumber: "",
        phoneNumber: "",
        roleNames: [],
        specificPermissions: [],
        titleId: "",
        password: "",
        confirmPassword: "",
        organisationId,
        mode,
        originalUserType: "",
      }}
      componentConfiguration={({ data: { id, roleNames = [] }, mode }) => {
        const isCreateMode = mode === "create";
        const fetchTags = isCreateMode || id;
        const userTypeDisabled = Boolean(
          !roleNames.length || isClientRole(roleNames)
        );
        return [
          {
            key: "header",
            content: [
              {
                controltype: "custom",
                Component: breadcrumb,
                control: false,
                md: 12,
              },
            ],
          },
          {
            key: "loginDetails",
            ariaLabel: strings.ariaLabels.loginDetails,
            content: [
              {
                controltype: "header",
                text: strings.headers.loginDetails,
              },
              {
                controltype: "input",
                label: strings.labels.email,
                name: "email",
                required: true,
                disabled: !isCreateMode,
              },
              {
                controltype: "custom",
                Component: () => <></>,
              },
              {
                controltype: "input",
                label: strings.labels.password,
                name: "password",
                required: isCreateMode,
                type: "password",
              },
              {
                controltype: "input",
                label: strings.labels.confirmPassword,
                name: "confirmPassword",
                required: isCreateMode,
                type: "password",
              },
            ],
          },
          {
            key: "userDetails",
            ariaLabel: strings.ariaLabels.userDetails,
            content: [
              {
                controltype: "header",
                text: strings.headers.userDetails,
              },
              {
                controltype: "dropdown",
                label: strings.labels.title,
                name: "titleId",
                options: fetchTags ? getTitles : [],
              },
              {
                controltype: "custom",
                Component: () => <></>,
              },
              {
                controltype: "input",
                label: strings.labels.firstName,
                name: "firstName",
                required: true,
              },
              {
                controltype: "input",
                label: strings.labels.lastName,
                name: "lastName",
                required: true,
              },
              {
                controltype: "input",
                label: strings.labels.phoneNumber,
                name: "phoneNumber",
              },
              {
                controltype: "input",
                label: strings.labels.mobileNumber,
                name: "mobileNumber",
              },
              {
                controltype: "input",
                label: strings.labels.externalReference,
                name: "externalReference",
              },
            ],
          },
          {
            key: "permissions",
            ariaLabel: strings.ariaLabels.permissions,
            content: [
              {
                controltype: "header",
                text: strings.headers.permissions,
              },
              {
                controltype: "checkbox",
                label: strings.labels.roles,
                name: "roleNames",
                required: true,
                options: fetchTags ? getRoles : [],
                multiple: false,
              },
              {
                controltype: "checkbox",
                label: strings.labels.userType,
                name: "userTypeId",
                required: true,
                options: fetchTags ? getUserTypes : [],
                multiple: false,
                disabled: userTypeDisabled,
              },
            ],
          },
          {
            key: "extra",
            content: [
              {
                controltype: "custom",
                Component: DataChangeListener,
              },
            ],
          },
        ];
      }}
      constraints={constraints}
      redirectPath={getUsersListPath(organisationId)}
    />
  );
};
