import { List } from "@mui/material";
import type { MenuItemsType } from "./listItems";
import { MenuItemContent } from "./MenuItemContent";

interface MenuItemsProps {
  menuItems: MenuItemsType;
  parent?: string;
}
type MenuItemsComponent = (props: MenuItemsProps) => JSX.Element;

export const MenuItems: MenuItemsComponent = ({ parent, menuItems }) => {
  return (
    <List sx={[{ width: "100%" }]} disablePadding>
      <div>
        {menuItems.map((item, index) => (
          <MenuItemContent
            key={index}
            item={item}
            index={index}
            parent={parent}
          />
        ))}
      </div>
    </List>
  );
};
