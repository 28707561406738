import type { DateTimeFormatOptions, LocaleOptions } from "luxon";
import { DateTime } from "luxon";

const dateOptions: LocaleOptions & DateTimeFormatOptions = {
  locale: "en-gb",
  dateStyle: "short",
};

const timeOptions: LocaleOptions & DateTimeFormatOptions = {
  timeStyle: "short",
};

export const formatDateTime = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
    ...timeOptions,
  });
  return formattedString;
};

export const formatDate = (inputDate: string | DateTime) => {
  const date =
    typeof inputDate === "string" ? DateTime.fromISO(inputDate) : inputDate;
  const formattedString = date.toLocaleString({
    ...dateOptions,
  });
  return formattedString;
};

export const formatTime = (inputDate: string | DateTime) => {
  const date =
    typeof inputDate === "string" ? DateTime.fromISO(inputDate) : inputDate;
  const formattedString = date.toLocaleString({
    ...timeOptions,
  });
  return formattedString;
};

export const toDateFormat = (date: DateTime | null) =>
  date?.toISO({
    suppressMilliseconds: true,
  }) || "";

export const toTimeFormat = (date: DateTime | null) =>
  date?.toISOTime({ suppressSeconds: true, includeOffset: false }) || "";

export const formatTimezoneName = (zone: string) => {
  const now = DateTime.local().setZone(zone);
  const { zoneName, offsetNameShort } = now;
  return `${zoneName} (${offsetNameShort})`;
};

export const getAvailableTimezones = (): string[] =>
  //@ts-ignore
  Intl.supportedValuesOf("timeZone");
