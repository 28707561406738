import { logger } from "../helpers/log-helpers";

type LoggerFn = (...args: any[]) => void;
export class HooksLogger {
  constructor(private name: string) {}

  info: LoggerFn = (...args) => {
    logger.debug(`%cINFO %c${this.name}`, "color:blue", "color:grey", ...args);
  };
  request: LoggerFn = (...args) => {
    logger.info(
      `%cREQUEST %c${this.name}`,
      "color:cyan",
      "color:grey",
      ...args
    );
  };
  success: LoggerFn = (...args) => {
    logger.info(
      `%cSUCCESS %c${this.name}`,
      "color:green",
      "color:grey",
      ...args
    );
  };
  error: LoggerFn = (...args) => {
    logger.info(`%cERROR %c${this.name}`, "color:red", "color:grey", ...args);
  };
}
