import type { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation/paths";
import { siteStrings as strings } from "../../resources/strings/sites";
import type { FullRouteProps } from "../../types";
import { OrganisationModify } from "../organisations/OrganisationModify";

export interface Props extends FullRouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const SiteModify: Component = ({ ...routeProps }) => {
  return (
    <OrganisationModify
      {...routeProps}
      createHeader={strings.headers.createSite}
      editHeader={strings.headers.editSite}
      listHeader={strings.headers.siteList}
      listPath={paths.sites.list()}
      saveLabel={strings.labels.saveSite}
      redirectPath={paths.sites.list()}
      organisationTypeId={routeProps.lowestOrganisationTypeId}
      isParentOrganisation={false}
      deleteHeader={strings.headers.deleteSite}
      getUsersListPath={paths.sites.users.list}
    />
  );
};
