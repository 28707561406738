import { Box, Grid } from "@mui/material";
import { useCallback } from "react";
import { getValidationProps } from "../../helpers/control-helpers";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { appStrings as strings } from "../../resources/strings/app";
import { api as contactsApi } from "../../services/contacts.service";
import { api as orgsApi } from "../../services/organisations.service";
import { BaseControl } from "./controls/BaseControl";
import { defaultDropdownState, Dropdown } from "./controls/Dropdown";
import { CustomProps } from "./types/Modify";

const XS = 12;
const MD = 6;

interface Props<D extends object> extends CustomProps<D> {
  text?: Partial<D>;
}

export const LocationDetails = <
  D extends {
    organisationId?: string;
    siteId?: string;
    contactId?: string | null;
  }
>({
  data: { organisationId, siteId, contactId },
  handleChange,
  disabled,
  validationResults,
  text,
}: Props<D>): JSX.Element => {
  const cancelToken = useCancelToken();

  const getOrganisations = useCallback(
    async () =>
      orgsApi
        .getParentSites(undefined, cancelToken)
        .then((sites = []) =>
          sites.length
            ? createDropdownOptions(sites, "id", "name")
            : defaultDropdownState
        ),
    [cancelToken]
  );

  const getSites = useCallback(async () => {
    if (!organisationId) return defaultDropdownState;
    return orgsApi
      .getSitesForParent(organisationId)
      .then((sites = []) =>
        sites.length
          ? createDropdownOptions(sites, "id", "name")
          : defaultDropdownState
      );
  }, [organisationId]);

  const getContacts = useCallback(async () => {
    if (!siteId) return defaultDropdownState;
    return contactsApi
      .getContactsForOrganisation(siteId, cancelToken)
      .then((contacts = []) =>
        contacts.length
          ? createDropdownOptions(
              contacts,
              "id",
              ({ firstName = "", lastName = "" }) => `${firstName} ${lastName}`
            )
          : defaultDropdownState
      );
  }, [siteId, cancelToken]);

  return (
    <Box display={"flex"} flexDirection={"row"}>
      <Grid container>
        <Grid item xs={XS} md={MD}>
          <BaseControl>
            <Dropdown
              config={{
                name: "organisationId",
                label: strings.labels.organisationId,
                required: true,
                value: organisationId,
                options: getOrganisations,
                disabled,
                text: text?.organisationId,
                ...getValidationProps("organisationId", validationResults),
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={XS} md={MD}>
          <BaseControl>
            <Dropdown
              config={{
                name: "siteId",
                label: strings.labels.siteId,
                required: true,
                value: siteId,
                disabled: !organisationId || disabled,
                options: organisationId ? getSites : defaultDropdownState,
                text: text?.siteId,
                ...getValidationProps("siteId", validationResults),
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={XS} md={MD}>
          <BaseControl>
            <Dropdown
              config={{
                name: "contactId",
                label: strings.labels.contactId,
                value: contactId,
                disabled: !siteId || disabled,
                options: siteId ? getContacts : defaultDropdownState,
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>
      </Grid>
    </Box>
  );
};
