import type { ReadFunction } from "../../types/API";
import { useReadQuery } from "../general/useReadQuery";

const defaultItem = {
  jobs: 0,
  recurrences: 0,
};

export const useRelationshipCheck = (
  id: string,
  readFunction: ReadFunction<typeof defaultItem>
) => useReadQuery(defaultItem, readFunction, id);
