export enum JobTypeState {
  New = "new",
  Published = "published",
  Unpublished = "unpublished",
  // This is not an actual state, purely for UI purposes
  Deleted = "deleted",
}

export type JobType = {
  id: string;
  name: string;
  version: number;
  state: JobTypeState;
  timeInMinutes: number;
  hasSiteVisitSection: boolean;
  containsEntities: boolean;
};

export type GroupedJobType = {
  jobTypeGroupId: string;
  jobTypeList: JobType[];
  selectedVersion: number;
};

export type JobTypeFilters = Partial<Pick<JobType, "name" | "state">>;
