import { PencilIcon } from "../../../assets/PencilIcon";
import { formStrings as strings } from "../../../resources/strings/forms";
import { IconAction } from "../../general/IconAction";

type EditActionProps = {
  id: string;
  handleEditRedirect: (id: string) => void;
};

export const EditAction = ({ id, handleEditRedirect }: EditActionProps) => {
  return (
    <IconAction
      tooltip={strings.labels.view}
      onClick={() => handleEditRedirect(id)}
    >
      <PencilIcon />
    </IconAction>
  );
};
