import { FileDownloadOutlined as DownloadIcon } from "@mui/icons-material";
import { HelpIcon } from "../../assets";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { formStrings as strings } from "../../resources/strings/forms";
import type { Permissions } from "../../types";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { CreateEntityButton } from "../general/CreateEntityButton";
import { IconAction } from "../general/IconAction";

interface Props {
  permissions: Permissions | null;
}
type Component = (props: Props) => JSX.Element;

export const FormListButtons: Component = ({ permissions }) => {
  const showWordPlugin = true;

  return (
    <>
      <ShowIf show={showWordPlugin}>
        <IconAction tooltip={strings.labels.wordPluginHelp}>
          <HelpIcon />
        </IconAction>
        <IconAction tooltip={strings.labels.downloadWordPlugin}>
          <DownloadIcon />
        </IconAction>
      </ShowIf>
      <CreateEntityButton
        config={{
          label: strings.labels.createForm,
          userAccess: {
            entity: appStrings.entities.forms,
            permission: appStrings.permissions.create,
          },
          path: paths.forms.create,
        }}
        permissions={permissions}
      />
    </>
  );
};
