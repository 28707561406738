import type { CancelToken } from "axios";
import axios from "../libs/axios/axios-jobstates";
import type { JobState } from "../types/documents";

const getAllowedJobStates = async (
  jobId: string,
  cancelToken?: CancelToken
): Promise<JobState[]> => {
  const path = `/allowed/${jobId}`;
  const { data } = await axios.get<JobState[]>(path, {
    cancelToken,
  });
  return data;
};

const getJobStates = async (cancelToken?: CancelToken): Promise<JobState[]> => {
  const { data } = await axios.get<JobState[]>(``, {
    cancelToken,
  });
  return data;
};

export const api = {
  getAllowedJobStates,
  getJobStates,
};
