import type { AxiosRequestConfig, CancelToken } from "axios";
import { openUrl } from "../helpers/export-helpers";
import axios from "../libs/axios/axios-jobs";
import type {
  CreateFunction,
  CreateResult,
  QueryBody,
  QueryFunction,
  ReadFunction,
  UpdateFunction,
  UpdateResult,
} from "../types/API";
import type { Job, JobDto, JobFilters } from "../types/documents";

type QueryResponse = {
  jobsCount: number;
  jobPageItems: Job[];
};

type JobPayload = Pick<
  JobDto,
  | "allocatedToId"
  | "contactId"
  | "externalRef"
  | "jobTypeId"
  | "name"
  | "notes"
  | "organisationId"
  | "scheduledDate"
  | "siteId"
  | "timeInMinutes"
>;

const getJobPayload = (payload: JobDto): JobPayload => {
  const {
    allocatedToId,
    contactId,
    externalRef,
    jobTypeId,
    name,
    notes,
    organisationId,
    scheduledDate,
    siteId,
    timeInMinutes,
  } = payload;
  return {
    allocatedToId,
    contactId,
    externalRef,
    jobTypeId,
    name,
    notes,
    organisationId,
    scheduledDate,
    siteId,
    timeInMinutes:
      typeof timeInMinutes === "number"
        ? timeInMinutes
        : parseInt(timeInMinutes),
  };
};

const query: QueryFunction<Job> = async (
  { page = 0, size = 20, sort = [], query },
  cancelToken?
) => {
  const filters = query as JobFilters;
  const { id: sortByColumn = "", desc = false } = sort[0] ?? {};
  const skip = page * size;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      take: size,
      skip,
      sortByColumn,
      sortAscending: !desc,
    },
    cancelToken,
  };
  const { data } = await axios.get<QueryResponse>("/search", config);
  return {
    items: data.jobPageItems,
    totalCount: data.jobsCount,
  };
};

const exportJobs = async (
  {
    page = 0,
    size = 20,
    query = {},
    skip = page * size,
  }: QueryBody<JobFilters>,
  cancelToken?: CancelToken
) => {
  const { dateFrom, dateTo, orgId } = query;
  const config: AxiosRequestConfig = {
    params: {
      dateFrom,
      dateTo,
      orgId,
      take: size,
      skip,
    },
    cancelToken,
  };
  const { data } = await axios.get<Job[]>("/export", config);
  return data;
};

const create: CreateFunction<JobDto> = async ({ payload }, cancelToken?) => {
  const job = getJobPayload(payload);
  const { data } = await axios.post<CreateResult>("", job, {
    cancelToken,
  });
  return data;
};

const update: UpdateFunction<JobDto> = async ({ payload }, cancelToken?) => {
  const path = `/${payload.id}`;
  const { timeInMinutes } = getJobPayload(payload);
  const job = { ...payload, timeInMinutes };
  const { data } = await axios.put<UpdateResult>(path, job, {
    cancelToken,
  });
  return data;
};

const read: ReadFunction<JobDto> = async (id, _childId, cancelToken?) => {
  const path = `/${id}`;
  const { data } = await axios.get<Job>(path, {
    cancelToken,
  });
  return {
    item: {
      ...data,
      organisationId: data?.organisation?.id,
    },
  };
};

const copyJob = async (id: string, cancelToken: CancelToken) => {
  const path = `/clone/${id}`;
  const { data } = await axios.post<string>(
    path,
    {},
    {
      cancelToken,
    }
  );
  return data;
};

const deleteJob = async (id: string, cancelToken: CancelToken) => {
  const path = `/${id}`;
  await axios.delete(path, {
    cancelToken,
  });
  return Promise.resolve(true);
};

const generateDocumentation = async (
  jobId: string,
  templateId: string,
  cancelToken?: CancelToken
) => {
  const { data } = await axios.post<string>(
    "/generatedocumentation",
    { jobId, templateId },
    {
      cancelToken,
    }
  );
  return data;
};

const getWordDocumentation = async (
  documentId: string,
  cancelToken?: CancelToken
) => {
  const { data } = await axios.get<string>(`/getdocumentation/${documentId}`, {
    cancelToken,
  });
  openUrl(data);
  return Promise.resolve();
};

const getPDFDocumentation = async (
  documentId: string,
  cancelToken?: CancelToken
) => {
  const { data } = await axios.get<string>(`/getpdf/${documentId}`, {
    cancelToken,
  });
  openUrl(data);
  return Promise.resolve();
};

const updateJobState = async (
  jobId: string,
  jobStateId: string,
  cancelToken?: CancelToken
) => {
  await axios.put(`/${jobId}/jobstate/${jobStateId}`, {
    cancelToken,
  });
  return Promise.resolve();
};

export const api = {
  query,
  exportJobs,
  create,
  update,
  read,
  copyJob,
  deleteJob,
  generateDocumentation,
  getWordDocumentation,
  getPDFDocumentation,
  updateJobState,
};
