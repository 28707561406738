import type { Address } from "./Address";
import type { ContactDto, CreateOrganisationContactDto } from "./Contact";
import type { DocumentDto } from "./Document";

export type SearchOrganisationDto = {
  id: string;
  name: string;
  organisationTypeId?: string;
  parentName: string;
  parentId: string;
  depth: number;
  immediateDescendantCount: number;
  address: Address;
  organisationTypeName: string;
  externalReference: string;
};

export type OrganisationDto = {
  id: string;
  organisationTypeId: string;
  name: string;
  externalReference: string;
  image: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  zip: string;
  country: string;
  timezone: string;
  contacts: Array<ContactDto>;
  documents: Array<DocumentDto>;
  languageId: string;
  parentId: string;
  defaultContactId?: string | null;
};

export type CreateOrganisationDto = {
  organisationTypeId: string;
  name: string;
  externalReference: string;
  image: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  zip: string;
  country: string;
  timezone: string;
  contacts: Array<CreateOrganisationContactDto>;
  languageId: string;
  parentId: string;
};

export type UpdateOrganisationAddressDto = {
  id: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  zip: string;
  country: string;
};

export type UpdateOrganisationDetailsDto = {
  id: string;
  organisationTypeId: string;
  name: string;
  externalReference: string;
  image: string;
  parentId: string;
};

export type UpdateOrganisationLanguageDto = {
  id: string;
  timezone: string;
  languageId: string;
};

export type GetOrganisationDto = {
  id: string;
  imagePath: string;
  name: string;
  organisationTypeId: string;
  parentId: string;
  parentName: string;
  externalReference: string;
  languageId: string;
  timezone: string;
  hasImageFilePath: boolean;
  image: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  zip: string;
  country: string;
  contacts: Array<ContactDto>;
  documents: Array<DocumentDto>;
  emailSiteVisitReport: boolean;
  userToReceiveJobCompletionReport: boolean;
  depth: number;
  tenantOrganisationId: string;
  tenantOrganisationName: string;
  defaultContactId?: string;
};

export type OrganisationFilters = Partial<{
  searchString: string;
  organisationTypeId: string;
  parentId: string;
}>;

export type OrganisationUserFilters = Partial<{
  searchString: string;
  parentId: string;
  refresh: boolean;
}>;

export type GetImmediateParentsDto = {
  isClientOrganisation: boolean;
  tenantId: string;
  organisations: Array<{
    id: string;
    address: Address;
    depth: number;
    externalReference: string;
    immediateDescendantCount: number;
    name: string;
    organisationTypeId: string;
    organisationTypeName: string;
    parentId: string;
    parentName: string;
  }>;
};

export enum OrganisationSort {
  Name = "Name",
  Address = "Address",
  Parent = "Parent",
  Address1 = "Address1",
}

export enum OrganisationUserSort {
  UserName = "UserName",
  Email = "Email",
  DefaultUser = "DefaultUser",
  AccessFromParentOrganisation = "AccessFromParentOrganisation",
}
