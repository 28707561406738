import { useEffect, useState } from "react";

export const useSessionStorage = <T>(initialState: T, storageKey: string) => {
  const [state, setState] = useState<T>(() => {
    const storedData = sessionStorage.getItem(storageKey);
    if (storedData === null) return initialState;
    try {
      return JSON.parse(storedData);
    } catch (e) {
      return initialState;
    }
  });

  useEffect(() => {
    const dataToStore = JSON.stringify(state);
    sessionStorage.setItem(storageKey, dataToStore);
  }, [state, storageKey]);

  return [state, setState] as const;
};
