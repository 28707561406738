import type { CancelToken } from "axios";
import { useHistory } from "react-router-dom";
import { useFormPermissions } from "../../hooks/forms/useFormPermissions";
import { paths } from "../../navigation/paths";
import { CognitoUser, Permissions } from "../../types";
import type { JobType } from "../../types/documents";
import { JobTypeState } from "../../types/documents/JobType";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { CopyAction } from "./actions/CopyAction";
import { DeleteVersionAction } from "./actions/DeleteVersionAction";
import { EditAction } from "./actions/EditAction";
import { ExportAction } from "./actions/ExportAction";
import { PublishAction } from "./actions/PublishAction";
import { TemplatesAction } from "./actions/TemplatesAction";

interface Props {
  permissions: Permissions | null;
  user: CognitoUser | null;
  form: JobType;
  hasPublishedVersion: boolean;
  handleChange: (newState: JobTypeState) => void;
  cancelToken: CancelToken;
}
type Component = (props: Props) => JSX.Element;

export const FormListActions: Component = ({
  permissions,
  user,
  form,
  hasPublishedVersion,
  handleChange,
  cancelToken,
}) => {
  const history = useHistory();
  const {
    canCopyJobType,
    canEditJobType,
    canViewJobType,
    canPublishJobType,
    canViewTemplates,
    canDeleteJobType,
    canExportJobType,
    isNew,
  } = useFormPermissions(permissions, user, form);

  const { id, name, state } = form;

  const isPublished = state === JobTypeState.Published;

  const handleEditRedirect = (formId: string) =>
    history.push(paths.forms.edit(formId));

  return (
    <>
      <ShowIf show={canCopyJobType}>
        <CopyAction
          id={id}
          cancelToken={cancelToken}
          handleEditRedirect={handleEditRedirect}
        />
      </ShowIf>
      <ShowIf show={canEditJobType || canViewJobType}>
        <EditAction id={id} handleEditRedirect={handleEditRedirect} />
      </ShowIf>
      <ShowIf show={canPublishJobType}>
        <PublishAction
          id={id}
          cancelToken={cancelToken}
          handleChange={handleChange}
          hasPublishedVersion={hasPublishedVersion}
          isNew={isNew}
          isPublished={isPublished}
        />
      </ShowIf>
      <ShowIf show={canViewTemplates}>
        <TemplatesAction id={id} />
      </ShowIf>
      <ShowIf show={canDeleteJobType}>
        <DeleteVersionAction
          id={id}
          handleChange={() => handleChange(JobTypeState.Deleted)}
          cancelToken={cancelToken}
          isPublished={isPublished}
        />
      </ShowIf>
      <ShowIf show={canExportJobType}>
        <ExportAction id={id} name={name} cancelToken={cancelToken} />
      </ShowIf>
    </>
  );
};
