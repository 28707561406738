import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useJobPermissions } from "../../context/JobPermissionsContext";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { api as orgsApi } from "../../services/organisations.service";
import type { Job, JobDto } from "../../types/documents";
import { defaultDropdownState, Dropdown } from "../general/controls/Dropdown";
import type { CustomProps } from "../general/types/Modify";

interface Props extends CustomProps<JobDto> {}
type Component = (props: Props) => JSX.Element;

export const JobAllocatedUserSelector: Component = ({
  handleChange,
  data: { siteId, allocatedToId },
  disabled,
}) => {
  const { state } = useLocation<Job>();

  const { canEditJobDetails } = useJobPermissions();
  const cancelToken = useCancelToken();

  const getUsers = useCallback(
    () =>
      orgsApi
        .getUsersForJob(siteId, cancelToken)
        .then(({ items }) =>
          createDropdownOptions(
            items,
            "id",
            ({ firstName, lastName }) => `${firstName} ${lastName}`
          )
        ),
    [siteId, cancelToken]
  );

  return (
    <Dropdown
      config={{
        name: "allocatedToId",
        label: strings.labels.allocatedTo,
        disabled: !canEditJobDetails || !siteId || disabled,
        options: siteId ? getUsers : defaultDropdownState,
        value: allocatedToId ?? "",
        text: state?.allocatedToId,
      }}
      handleChange={handleChange}
    />
  );
};
