import type { Mode } from "../../components/general/types/Modify";
import { useLoggedInUser } from "../../hooks/authentication/useLoggedInUser";
import { paths } from "../../navigation/paths";
import type { RouteProps } from "../../types";
import { OrganisationUserModify } from "../organisations/OrganisationUserModify";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const TenantUserModify: Component = ({ mode, ...routeProps }) => {
  const { tenantId } = useLoggedInUser(routeProps);
  return (
    <OrganisationUserModify
      {...routeProps}
      mode={mode}
      organisationId={tenantId}
      getUsersListPath={paths.tenants.users.list}
    />
  );
};
