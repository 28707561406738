import { bulkUploadStrings as strings } from "../../../resources/strings/settings/bulkUpload";
import type { Field } from "../../general/FieldDetailsDisplay";
import { FieldDetailsDisplay } from "../../general/FieldDetailsDisplay";

interface Props {}
type Component = (props: Props) => JSX.Element;

const labels = strings.fields.assets;
const descriptions = strings.descriptions.assets;

const fields: Array<Field> = [
  {
    key: "siteRef",
    label: labels.siteRef,
    description: descriptions.siteRef,
    required: true,
  },
  {
    key: "location",
    label: labels.location,
    description: descriptions.location,
    required: true,
  },
  {
    key: "assetTypeRef",
    label: labels.assetTypeRef,
    description: descriptions.assetTypeRef,
    required: true,
  },
  {
    key: "name",
    label: labels.name,
    description: descriptions.name,
    required: false,
  },
  {
    key: "description",
    label: labels.description,
    description: descriptions.description,
    required: false,
  },
  {
    key: "assetNum",
    label: labels.assetNum,
    description: descriptions.assetNum,
    required: false,
  },
  {
    key: "serialNum",
    label: labels.serialNum,
    description: descriptions.serialNum,
    required: false,
  },
  {
    key: "notes",
    label: labels.notes,
    description: descriptions.notes,
    required: false,
  },
];

export const AssetHelpPanel: Component = () => {
  return <FieldDetailsDisplay fields={fields} />;
};
