import log, { debug, info, trace, warn } from "loglevel";
export { levels } from "loglevel";

const getLogLevel = (level: string | number) => {
  const parsedLevel = typeof level === "string" ? parseInt(level) : level;
  if (typeof parsedLevel !== "number") return log.levels.ERROR;
  switch (parsedLevel) {
    case log.levels.TRACE:
      return log.levels.TRACE;
    case log.levels.DEBUG:
      return log.levels.DEBUG;
    case log.levels.INFO:
      return log.levels.INFO;
    case log.levels.WARN:
      return log.levels.WARN;
    case log.levels.ERROR:
      return log.levels.ERROR;
    case log.levels.SILENT:
      return log.levels.SILENT;
    default:
      return log.levels.ERROR;
  }
};

export const setLogLevel = (level: string | number) => {
  const parsedLevel = getLogLevel(level);
  log.setLevel(parsedLevel);
};

const hasError = (object: object): object is { error: unknown } =>
  "error" in object;

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  if (error instanceof Object && hasError(error)) return String(error.error);
  return String(error);
};

const error = (error: unknown, logText = "ERROR") => {
  const message = getErrorMessage(error);
  log.error(logText, message);
  return message;
};

export const logger = {
  error,
  trace,
  debug,
  info,
  warn,
};
