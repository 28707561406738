import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken } from "../auth";

const onRequest = async (
  initialConfig: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const config = Object.assign({}, initialConfig);

  const token = await getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const interceptors = {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
};

export default interceptors;
