import type { AxiosRequestConfig, CancelToken } from "axios";
import axios from "../libs/axios/axios-organisations";
import {
  CreateFunction,
  QueryFunction,
  ReadFunction,
  UpdateFunction,
} from "../types/API";
import type { Organisation } from "../types/documents";
import type {
  CreateOrganisationDto,
  GetImmediateParentsDto,
  GetOrganisationDto,
  OrganisationDto,
  OrganisationUserFilters,
  UpdateOrganisationAddressDto,
  UpdateOrganisationDetailsDto,
  UpdateOrganisationLanguageDto,
} from "../types/documents/Organisation";
import { OrganisationFilters } from "../types/documents/Organisation";
import type { JobUser } from "../types/documents/User";

type JobUsersResponse = {
  usersCount: number;
  userPageItems: JobUser[];
};

type SearchOrganisationsDto = {
  organisationsCount: number;
  organisationPageItems: Organisation[];
};

type SearchOrganisationUsersDto = {
  usersCount: number;
  userPageItems: JobUser[];
};

const query: QueryFunction<Organisation> = async (
  { page = 0, size = 20, sort = [], query },
  cancelToken?
) => {
  const filters = query as OrganisationFilters;
  const { id: sortBy = "", desc = false } = sort[0] ?? {};
  const skip = page * size;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      take: size,
      skip,
      sortBy,
      sortAscending: !desc,
    },
    cancelToken,
  };
  const { data } = await axios.get<SearchOrganisationsDto>("/search", config);
  return {
    items: data.organisationPageItems,
    totalCount: data.organisationsCount,
  };
};

const searchOrganisationUsers: QueryFunction<JobUser> = async (
  { page = 0, size = 20, sort = [], query },
  cancelToken?
) => {
  const filters = query as OrganisationUserFilters;
  const { id: sortBy = "", desc = false } = sort[0] ?? {};
  const skip = page * size;
  const { parentId, refresh, ...searchFilters } = filters;
  const config: AxiosRequestConfig = {
    params: {
      ...searchFilters,
      take: size,
      skip,
      sortBy,
      sortAscending: !desc,
    },
    cancelToken,
  };
  const path = `/${parentId}/users`;
  const { data } = await axios.get<SearchOrganisationUsersDto>(path, config);
  return {
    items: data.userPageItems,
    totalCount: data.usersCount,
  };
};

const getParentSites = async (limit?: number, cancelToken?: CancelToken) => {
  const { data } = await axios.get<Organisation[]>(`/parentsofsites/${limit}`, {
    cancelToken,
  });
  return data;
};

const getSitesForParent = async (
  parentId: string,
  limit?: number,
  cancelToken?: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
    params: {
      maxCount: limit,
    },
  };
  const { data } = await axios.get<Organisation[]>(
    `/getSitesForOrganisation/${parentId}/dropdown`,
    config
  );
  return data;
};

const getUsersForJob = async (
  organisationId: string,
  cancelToken?: CancelToken
) => {
  const { data } = await axios.get<JobUsersResponse>(
    `/${organisationId}/users`,
    {
      cancelToken,
    }
  );
  return {
    items: data.userPageItems,
    totalCount: data.usersCount,
  };
};

const create: CreateFunction<OrganisationDto> = async (
  { payload },
  cancelToken
) => {
  const {
    organisationTypeId,
    name,
    externalReference,
    image,
    address1,
    address2,
    city,
    county,
    zip,
    country,
    timezone,
    contacts,
    parentId,
    languageId,
  } = payload;
  const body: CreateOrganisationDto = {
    organisationTypeId,
    name,
    externalReference,
    image,
    address1,
    address2,
    city,
    county,
    zip,
    country,
    timezone,
    contacts: contacts.map(({ id, ...contact }) => ({ ...contact })),
    languageId,
    parentId,
  };
  const config: AxiosRequestConfig = { cancelToken };
  await axios.post("", body, config);
  return Promise.resolve({
    id: "",
  });
};

const updateOrganisationAddress = async (
  payload: OrganisationDto,
  cancelToken?: CancelToken
) => {
  const { id, address1, address2, city, country, county, zip } = payload;
  const body: UpdateOrganisationAddressDto = {
    id,
    address1,
    address2,
    city,
    country,
    county,
    zip,
  };
  return axios.put(`/1/${id}`, body, { cancelToken });
};

const updateOrganisationDetails = async (
  payload: OrganisationDto,
  cancelToken?: CancelToken
) => {
  const { id, externalReference, image, name, organisationTypeId, parentId } =
    payload;
  const body: UpdateOrganisationDetailsDto = {
    id,
    externalReference,
    image,
    name,
    organisationTypeId,
    parentId,
  };
  return axios.put(`/0/${id}`, body, { cancelToken });
};

const updateOrganisationLanguage = async (
  payload: OrganisationDto,
  cancelToken?: CancelToken
) => {
  const { id, languageId, timezone } = payload;
  const body: UpdateOrganisationLanguageDto = {
    id,
    languageId,
    timezone,
  };
  return axios.put(`/2/${id}`, body, { cancelToken });
};

const update: UpdateFunction<OrganisationDto> = async (
  { payload },
  cancelToken
) => {
  const updatePromises = [
    updateOrganisationAddress(payload, cancelToken),
    updateOrganisationDetails(payload, cancelToken),
    updateOrganisationLanguage(payload, cancelToken),
  ];
  await Promise.all(updatePromises);
  return Promise.resolve({
    id: "",
  });
};

const read: ReadFunction<OrganisationDto> = async (
  id,
  _childId,
  cancelToken
) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = { cancelToken };
  const { data } = await axios.get<GetOrganisationDto>(path, config);
  const {
    id: responseId,
    organisationTypeId,
    name,
    externalReference,
    image,
    address1,
    address2,
    city,
    country,
    county,
    zip,
    timezone,
    contacts = [],
    documents,
    languageId,
    parentId,
    defaultContactId,
  } = data;
  const item: OrganisationDto = {
    id: responseId,
    organisationTypeId,
    name,
    externalReference,
    image,
    address1,
    address2,
    city,
    country,
    county,
    zip,
    timezone,
    contacts: contacts.filter(({ active }) => active),
    documents,
    languageId,
    parentId,
    defaultContactId,
  };
  return Promise.resolve({
    item,
  });
};

const getImmediateParents = async (
  organisationId: string,
  size: number,
  cancelToken?: CancelToken
) => {
  const path = `/immediateparents/${organisationId}`;
  const config: AxiosRequestConfig = {
    cancelToken,
    params: {
      take: size,
    },
  };
  const { data } = await axios.get<GetImmediateParentsDto>(path, config);
  return Promise.resolve(data);
};

const deleteOrganisation = async (id: string, cancelToken: CancelToken) => {
  await axios.delete(`/${id}`, { cancelToken });
  return Promise.resolve(true);
};

const getUnassignedUsersForOrganisation: ReadFunction<JobUser[]> = async (
  id,
  _childId,
  cancelToken
) => {
  const { data } = await axios.get<JobUser[]>(`/${id}/alltenantusers`, {
    cancelToken,
  });
  return {
    item: data.filter(
      ({ canAccessCurrentOrganisation }) => !canAccessCurrentOrganisation
    ),
  };
};

const addUsersToOrganisation = async (
  organisationId: string,
  userIds: string[],
  cancelToken: CancelToken
) => {
  await axios.post(`/${organisationId}/users`, { userIds }, { cancelToken });
  return Promise.resolve(true);
};

const setDefaultContact = async (
  organisationId: string,
  contactId: string | null,
  cancelToken: CancelToken
) => {
  await axios.put(
    `/${organisationId}/defaultcontact/${contactId}`,
    {},
    { cancelToken }
  );
  return Promise.resolve(true);
};

export const api = {
  getParentSites,
  getSitesForParent,
  getUsersForJob,
  query,
  create,
  read,
  update,
  getImmediateParents,
  deleteOrganisation,
  searchOrganisationUsers,
  getUnassignedUsersForOrganisation,
  addUsersToOrganisation,
  setDefaultContact,
};
