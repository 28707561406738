import { ListOutlined as ListIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { paths } from "../../../navigation/paths";
import { formStrings as strings } from "../../../resources/strings/forms";
import { IconAction } from "../../general/IconAction";

type TemplateActionProps = {
  id: string;
};

export const TemplatesAction = ({ id }: TemplateActionProps) => {
  const history = useHistory();

  const handleTemplatesRedirect = () => {
    // TODO: check for relevant tenant feature
    history.push(paths.forms.templates(id));
  };

  return (
    <IconAction
      tooltip={strings.labels.template}
      onClick={handleTemplatesRedirect}
    >
      <ListIcon />
    </IconAction>
  );
};
