import { Alert, Stack, Typography } from "@mui/material";
import { useFormVersionRelationshipCheck } from "../../hooks/forms/useFormVersionRelationshipCheck";
import { formStrings as strings } from "../../resources/strings/forms";
import { Loader } from "../general/Loader";

interface Props {
  versionId: string;
}
type Component = (props: Props) => JSX.Element;

export const FormPublishModalContent: Component = ({ versionId }) => {
  const [item, loading, error] = useFormVersionRelationshipCheck(versionId);
  if (loading) return <Loader inline active />;

  let feedback = strings.text.publishFormRecurrencesWarning(item.recurrences);
  if (item.recurrences > 0) {
    feedback += ` ${strings.text.publishFormRecurrencesRemoveWarning}`;
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography>{strings.text.publishForm}</Typography>
        {error ? (
          <Alert severity="error">
            {strings.errors.relationshipCheckError}
          </Alert>
        ) : (
          <Alert severity="info">{feedback}</Alert>
        )}
      </Stack>
    </>
  );
};
