import { api as assetUploadApi } from "../services/assetupload.service";
import { api as jobUploadApi } from "../services/jobupload.service";
import { api as orgsUploadApi } from "../services/organisationsupload.service";
import { BulkUploadType } from "../types/documents/BulkUpload";

export const getApiService = (uploadType: BulkUploadType) => {
  switch (uploadType) {
    case BulkUploadType.Organisation:
      return orgsUploadApi;
    case BulkUploadType.Asset:
      return assetUploadApi;
    case BulkUploadType.Job:
      return jobUploadApi;
    default:
      throw new Error("invalid upload type");
  }
};
