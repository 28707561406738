import type { SxProps, Theme } from "@mui/material";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { Loader } from "../../components/general/Loader";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useForm } from "../../hooks/general/useForm";
import { forgotPasswordSubmit, validateToken } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import type { CognitoUser } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

const marginStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(2, "0"),
});

export const CompletePasswordReset: Component = ({ user }) => {
  const { userId } = useParams<{ userId: string }>();
  const { search } = useLocation();
  const cancelToken = useCancelToken();

  const [tokenValid, setTokenValid] = useState(true);
  const [token, setToken] = useState("");

  const [loading, setLoading] = useState(false);
  const [, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [formData, handleChange] = useForm({
    username: "",
    code: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const history = useHistory();

  useEffect(() => {
    if (user) history.push(paths.root);
    if (success) history.push(paths.auth.login);
  }, [user, success, history]);

  useEffect(() => {
    const validate = async (inputToken: string) => {
      try {
        const result = await validateToken(
          { token: inputToken, userId },
          cancelToken
        );
        if (!result) throw new Error("Token not valid");

        setToken(inputToken);
        setLoading(false);
      } catch (e) {
        if (cancelToken.reason) return;
        logger.error(e);

        setToken("");
        setTokenValid(false);
        setLoading(false);
      }
    };

    setLoading(true);

    const params = new URLSearchParams(search);
    const tokenParam = params.get("token");
    if (!tokenParam) {
      setTokenValid(false);
      setLoading(false);
      return;
    }

    validate(tokenParam);
  }, [search, userId, cancelToken]);

  const onSubmitPassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await forgotPasswordSubmit(
        userId,
        token,
        formData.newPassword,
        cancelToken
      );

      setLoading(false);
      setError("");
      setSuccess(true);
    } catch (e) {
      setLoading(false);
      setSuccess(false);
      setError(strings.errors.changePassword);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return (
      <BaseControl control={false} margin>
        {control}
      </BaseControl>
    );
  };

  const renderPasswordForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.headers.completePassword}
        </Typography>
        <form noValidate onSubmit={onSubmitPassword} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.newPassword,
                name: "newPassword",
                label: strings.labels.newPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "new-password",
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.confirmNewPassword,
                name: "confirmNewPassword",
                label: strings.labels.confirmNewPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "confirm-password",
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={[
              !formData.newPassword,
              !formData.confirmNewPassword,
              formData.newPassword !== formData.confirmNewPassword,
            ].includes(true)}
            sx={[marginStyles]}
          />
        </form>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline />;
    }

    if (!tokenValid) return <Redirect to={paths.auth.requestPasswordReset} />;
    return renderPasswordForm();
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
