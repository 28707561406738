import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { Button } from "../../components/general/Button";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { contactStrings as strings } from "../../resources/strings/contacts";
import { api as contactTypesApi } from "../../services/contacttypes.service";
import { api as titlesApi } from "../../services/titles.service";
import type {
  CognitoUser,
  Permissions,
  ValidationConstraints,
} from "../../types";
import type { ApiFunctionBody } from "../../types/API";
import { ContactDto } from "../../types/documents/Contact";
import { ModifyContainer } from "../general/ModifyContainer";

export interface Props {
  permissions: Permissions | null;
  user: CognitoUser | null;
  contactData?: ContactDto;
  handleContactChange: (dto: ContactDto) => void;
  handleClose: () => void;
}
type Component = (props: Props) => JSX.Element;

const constraints: ValidationConstraints<ContactDto> = {
  contactTypeId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.contactTypeId} is required`,
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.firstName} is required`,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.lastName} is required`,
    },
  },
};

const defaultContact: ContactDto = {
  contactTypeId: "",
  firstName: "",
  id: "",
  lastName: "",
  active: false,
  default: false,
  email: "",
  jobTitle: "",
  mobile: "",
  phone: "",
  titleId: "",
};

const useTagResolvers = () => {
  const cancelToken = useCancelToken();
  const getContactTypes = useCallback(
    async () =>
      contactTypesApi
        .getContactTypes("id", "", cancelToken)
        .then(({ item }) => createDropdownOptions(item, "id", "name")),
    [cancelToken]
  );
  const getTitles = useCallback(
    async () =>
      titlesApi
        .getTitles("id", "", cancelToken)
        .then(({ item }) => createDropdownOptions(item, "id", "name")),
    [cancelToken]
  );
  return {
    getContactTypes,
    getTitles,
  };
};

export const ContactModify: Component = ({
  permissions,
  user,
  contactData = defaultContact,
  handleContactChange,
  handleClose,
}) => {
  const { getTitles, getContactTypes } = useTagResolvers();

  const api = useMemo(() => {
    const actionHandler = ({ payload }: ApiFunctionBody<ContactDto>) => {
      handleContactChange(payload);
      handleClose();
      return Promise.resolve({ id: "" });
    };
    return {
      create: (body: ApiFunctionBody<ContactDto>) =>
        actionHandler({
          ...body,
          payload: {
            ...body.payload,
            id: uuid(),
          },
        }),
      update: actionHandler,
      read: () =>
        Promise.resolve<{ item: ContactDto }>({
          item: contactData,
        }),
    };
  }, [handleClose, handleContactChange, contactData]);

  return (
    <>
      <ModifyContainer<ContactDto>
        permissions={permissions}
        user={user}
        mode={"create"}
        api={api}
        componentConfiguration={[
          {
            key: "header",
            content: [
              {
                controltype: "dropdown",
                name: "contactTypeId",
                label: strings.labels.contactTypeId,
                md: 12,
                required: true,
                options: getContactTypes,
              },
              {
                controltype: "input",
                name: "jobTitle",
                label: strings.labels.jobTitle,
                md: 12,
              },
              {
                controltype: "dropdown",
                name: "titleId",
                label: strings.labels.titleId,
                md: 12,
                options: getTitles,
              },
              {
                controltype: "input",
                name: "firstName",
                label: strings.labels.firstName,
                md: 12,
                required: true,
              },
              {
                controltype: "input",
                name: "lastName",
                label: strings.labels.lastName,
                md: 12,
                required: true,
              },
              {
                controltype: "input",
                name: "phone",
                label: strings.labels.phone,
                md: 12,
              },
              {
                controltype: "input",
                name: "mobile",
                label: strings.labels.mobile,
                md: 12,
              },
              {
                controltype: "input",
                name: "email",
                label: strings.labels.email,
                md: 12,
              },
            ],
          },
          {
            key: "actions",
            content: [
              {
                controltype: "custom",
                Component: ({ validateForm }) => (
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      onClick={handleClose}
                      type="button"
                      label={strings.labels.cancel}
                      variant="outlined"
                    />
                    <Button
                      onClick={validateForm}
                      type="button"
                      label={strings.labels.submit}
                    />
                  </Box>
                ),
                md: 12,
                control: false,
              },
            ],
          },
        ]}
        constraints={constraints}
        initialData={contactData}
        redirectPath=""
      />
    </>
  );
};
