import { useEffect, useMemo } from "react";
import { useParentOrganisationDetails } from "../../hooks/organisations/useParentOrganisationDetails";
import type { OrganisationDto } from "../../types/documents/Organisation";
import type { CustomProps } from "../general/types/Modify";

export interface Props extends CustomProps<OrganisationDto> {
  isParentOrganisation: boolean;
}
type Component = (props: Props) => JSX.Element;

export const OrganisationParentDetails: Component = ({
  data: { parentId, organisationTypeId },
  setFormData,
  isParentOrganisation,
}) => {
  const id = useMemo(
    () => (!isParentOrganisation ? parentId : organisationTypeId),
    [isParentOrganisation, parentId, organisationTypeId]
  );
  const [parentDetails] = useParentOrganisationDetails(
    id,
    isParentOrganisation
  );
  useEffect(() => {
    if (!parentDetails.id) return;
    setFormData((prev) => {
      return {
        ...prev,
        parentId: prev.parentId ? prev.parentId : parentDetails.id,
        timezone: parentDetails.timezone,
        country: parentDetails.country,
        languageId: parentDetails.languageId,
      };
    });
  }, [
    parentDetails.id,
    parentDetails.country,
    parentDetails.timezone,
    parentDetails.languageId,
    setFormData,
  ]);
  return <></>;
};
