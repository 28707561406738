import {
  ApiContextProvider,
  ControlContextProvider,
  FormDesignerContainer,
} from "@pulsion/forms-designer-2";
import { useParams } from "react-router-dom";
import { BreadcrumbHeader } from "../../components/general/BreadcrumbHeader";
import { paths } from "../../navigation/paths";
import { formStrings as strings } from "../../resources/strings/forms";
import { formDesignerApi } from "../../services/formsDesigner/formdesigner.service";
import { formDesignerThemes } from "./themes/formDesignerTheme";

const FormDesignerBreadcrumbHeader = () => (
  <BreadcrumbHeader
    crumbs={[
      {
        text: strings.header.formsList,
        to: paths.forms.list,
      },
      {
        text: strings.labels.formsDesigner,
      },
    ]}
    children={<></>}
  />
);

const initialData = {
  name: "",
  timeInMinutes: 60,
};

export const FormDesigner = () => {
  const { id: formId } = useParams<{ id: string }>();
  return (
    <>
      <ApiContextProvider api={formDesignerApi}>
        <ControlContextProvider>
          <>
            <FormDesignerBreadcrumbHeader />
            <FormDesignerContainer<{}>
              formId={formId}
              theme={formDesignerThemes}
              initialData={initialData}
              controlTypes={[
                "camera",
                "check",
                "date",
                "dropdown",
                "gps",
                "groupCheckboxes",
                "label",
                "number",
                "radio",
                "sectionGrid",
                "signature",
                "text",
                "time",
                "subsection",
              ]}
              fieldConfiguration={[]}
            />
          </>
        </ControlContextProvider>
      </ApiContextProvider>
    </>
  );
};
