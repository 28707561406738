import {
  FileDownloadOutlined as DownloadIcon,
  KeyboardArrowDown,
} from "@mui/icons-material";
import type { SvgIconTypeMap } from "@mui/material";
import { Link, Menu, MenuItem } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import { useState } from "react";
import { getStaticFileUrl } from "../../helpers/static-file-helpers";
import { bulkUploadStrings as strings } from "../../resources/strings/settings/bulkUpload";
import { Button } from "../general/Button";
import { Modal } from "../general/Modal";
import { BulkUploadContent } from "./BulkUploadContent";
import { BulkUploadHelpContent } from "./BulkUploadHelpContent";

interface Props {}
type Component = (props: Props) => JSX.Element;

type MenuItemsProps = {
  items: Array<{
    text: string;
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | (() => JSX.Element);
    filePath: string;
  }>;
};

const templateMenuOptions: MenuItemsProps["items"] = [
  {
    text: strings.headers.organisation,
    filePath: "organisation/OrganisationUploadTemplate.csv",
    Icon: DownloadIcon,
  },
  {
    text: strings.headers.asset,
    filePath: "asset/AssetUploadTemplate.csv",
    Icon: DownloadIcon,
  },
  {
    text: strings.headers.job,
    filePath: "job/JobUploadTemplate.csv",
    Icon: DownloadIcon,
  },
];

const MenuItems = ({ items }: MenuItemsProps) => (
  <>
    {items.map(({ text, filePath, Icon }) => (
      <MenuItem
        component={Link}
        key={text}
        disableRipple
        href={getStaticFileUrl(filePath)}
      >
        <Icon sx={[{ mr: 1.5 }]} />
        {text}
      </MenuItem>
    ))}
  </>
);

const ButtonMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        label={strings.labels.downloadTemplate}
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItems items={templateMenuOptions} />
      </Menu>
    </>
  );
};

export const BulkUploadListButtons: Component = () => {
  return (
    <>
      <Modal
        trigger={<Button label={strings.labels.help} variant="outlined" />}
        header={strings.headers.fileTypeGuide}
        paddedContent={false}
      >
        <BulkUploadHelpContent />
      </Modal>
      <ButtonMenu />
      <Modal
        trigger={<Button label={strings.labels.uploadCSV} />}
        header={strings.headers.uploadCSV}
        actions={() => []}
      >
        {({ onClose }) => <BulkUploadContent onClose={onClose} />}
      </Modal>
    </>
  );
};
