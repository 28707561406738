import { useMemo } from "react";
import { OrganisationListActions } from "../../components/organisations/OrganisationListActions";
import { OrganisationSearchBar } from "../../components/organisations/OrganisationSearchBar";
import { displayAddress } from "../../helpers/organisation-helpers";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import { appStrings } from "../../resources/strings/app";
import { organisationStrings as strings } from "../../resources/strings/organisations";
import { api } from "../../services/organisations.service";
import type { FilterChangeHandler, RouteProps } from "../../types";
import type { Organisation } from "../../types/documents";
import {
  OrganisationSort,
  type OrganisationFilters,
} from "../../types/documents/Organisation";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {
  header: string;
  filterStorageKey: string;
  getViewPath: (id: string) => string;
  createButtonLabel: string;
  createButtonPath: string;
  editActionLabel: string;
  jobsActionLabel: string;
  isParentOrganisation: boolean;
  viewChildLabel?: string;
  getViewChildPath?: (id: string) => string;
  parentId?: string;
  organisationTypeId: string;
  sortStorageKey: string;
}
type Component = (props: Props) => JSX.Element;

export const OrganisationList: Component = ({
  header,
  filterStorageKey,
  getViewPath,
  createButtonLabel,
  createButtonPath,
  editActionLabel,
  jobsActionLabel,
  isParentOrganisation,
  viewChildLabel,
  getViewChildPath,
  parentId,
  organisationTypeId,
  sortStorageKey,
  ...routeProps
}) => {
  const [filters, setFilters] = useSessionStorage<OrganisationFilters>(
    {},
    filterStorageKey
  );

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => {
    setFilters({});
  };

  const tableFilters: any = useMemo(
    (): typeof filters => ({
      ...filters,
      organisationTypeId,
      parentId,
    }),
    [filters, organisationTypeId, parentId]
  );

  return (
    <div>
      <TableContainer<Organisation>
        {...routeProps}
        api={api}
        header={header}
        tableColumns={[
          {
            id: OrganisationSort.Parent,
            Header: strings.labels.parent,
            accessor: "parentName",
            hidden: isParentOrganisation,
          },
          {
            id: OrganisationSort.Name,
            Header: strings.labels.name,
            accessor: "name",
          },
          {
            id: OrganisationSort.Address,
            Header: strings.labels.address,
            accessor: ({ address }) => displayAddress(address),
          },
          {
            id: "actions",
            Header: "",
            accessor: ({ id }) => (
              <OrganisationListActions
                organisationId={id}
                getViewPath={getViewPath}
                editActionLabel={editActionLabel}
                jobsActionLabel={jobsActionLabel}
                permissions={routeProps.permissions}
                isParentOrganisation={isParentOrganisation}
                viewChildLabel={viewChildLabel}
                getViewChildPath={getViewChildPath}
              />
            ),
            disableSortBy: true,
            width: 50,
          },
        ]}
        manualPagination
        defaultSort={[
          {
            id: OrganisationSort.Name,
            desc: false,
          },
        ]}
        createButtonConfig={{
          label: createButtonLabel,
          path: createButtonPath,
          userAccess: {
            entity: appStrings.entities.organisations,
            permission: appStrings.permissions.create,
          },
        }}
        children={
          <OrganisationSearchBar<OrganisationFilters>
            filters={filters}
            handleFilterChange={handleFilterChange}
            clearFilters={clearFilters}
          />
        }
        filters={tableFilters}
        sortStorageKey={sortStorageKey}
      />
    </div>
  );
};
