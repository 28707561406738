import { MenuItem } from "@mui/material";
import { DownIcon } from "../../../assets";
import { displayDropdownValue } from "../../../helpers/dropdown-helpers";
import { useDropdownOptions } from "../../../hooks/general/useDropdownOptions";
import { useTagGroup } from "../../../hooks/tags/queries/useTagGroup";
import { appStrings as strings } from "../../../resources/strings/app";
import type { DropdownOptions } from "../../../types";
import type { ChangeHandler, Dropdown as TDropdown } from "../types/Modify";
import { Input } from "./Input";

export interface Props<D extends object> {
  config: Omit<TDropdown<D>, "controltype">;
  handleChange: ChangeHandler;
}

const loadingValue = "loading";
const loadingState: DropdownOptions = [
  { label: strings.labels.loadingTags, value: loadingValue, disabled: true },
];

export const defaultDropdownState: DropdownOptions = [
  {
    label: strings.labels.noOptionsFound,
    value: "none",
    disabled: true,
  },
];

export const Dropdown = <D extends object>({
  config: input,
  handleChange,
}: Props<D>): JSX.Element => {
  const { options: inputOptions = [], tagConfig, text, ...config } = input;
  // check the props to make sure we dont trigger a fetch from useDropdownOptions
  // and useTagGroup
  const tagGroup =
    tagConfig?.tagGroup && typeof inputOptions !== "function"
      ? tagConfig.tagGroup
      : "";

  // not the most ideal to call two hooks which kind of do similar jobs but
  // since hooks cant be called conditionally, not sure what alternatives there are
  const [dropdownOptions, dropdownOptionsLoading] =
    useDropdownOptions(inputOptions);
  const [options, tagsLoading] = useTagGroup(dropdownOptions, tagGroup);
  const loading = dropdownOptionsLoading || tagsLoading;

  const renderOptions = (dropdownOptions: typeof options) => {
    return dropdownOptions.map((option, index) => (
      <MenuItem
        key={`${option.value}-${index}`}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <Input<D>
      config={{
        ...config,
        select: true,
        SelectProps: {
          ...config.SelectProps,
          IconComponent: DownIcon,
          renderValue: (data) => displayDropdownValue(data, options, text),
        },
        children: renderOptions(loading ? loadingState : options),
      }}
      handleChange={(e) => {
        const { value } = e.target;
        const selectedOption = options.find((option) => option.value === value);
        handleChange(e, { selectedOption });
      }}
    />
  );
};
