import { Clear as CloseIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Grid,
  Modal as MuiModal,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { usePalette } from "../../hooks/general/usePalette";
import { appStrings as strings } from "../../resources/strings/app";
import { Button } from "./Button";
import { IconAction } from "./IconAction";

type TActions = (props: ActionProps) => JSX.Element[];

interface Props {
  header: string;
  trigger: JSX.Element;
  paddedContent?: boolean;
  children: JSX.Element | ((props: ActionProps) => JSX.Element);
  actions?: TActions;
}
type Component = (props: Props) => JSX.Element;

const Span = styled("span")({});

interface HeaderProps {
  onClose: () => void;
  header: Props["header"];
}

interface BodyProps {
  children: Props["children"];
  paddedContent: Props["paddedContent"];
}

interface ActionProps {
  onClose: () => void;
}

interface ModalActionProps extends ActionProps {
  actions: TActions;
}

const borderRadius = 0.5;

const spacerStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2),
});

const headerStyles: SxProps<Theme> = (theme) => ({
  borderRadius: theme.spacing(borderRadius, borderRadius, 0, 0),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
});

const containerStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(borderRadius),
  display: "flex",
  flexDirection: "column",
});

const ModalHeader = ({ onClose, header }: HeaderProps) => {
  const palette = usePalette();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={[spacerStyles, headerStyles]}
    >
      <Grid item>
        <Typography variant="subtitle1">{header}</Typography>
      </Grid>
      <Grid item>
        <IconAction tooltip="" onClick={onClose}>
          <CloseIcon sx={[palette.white]} data-testid="close-icon" />
        </IconAction>
      </Grid>
    </Grid>
  );
};

const ModalBody = ({ children, paddedContent = false }: BodyProps) => {
  return <Span sx={[paddedContent && spacerStyles]}>{children}</Span>;
};

const ModalActions = ({ onClose, actions }: ModalActionProps) => {
  const actionsToRender = actions({ onClose });
  return (
    <Grid container justifyContent="flex-end" sx={[spacerStyles]}>
      {actionsToRender.map((action, index) => (
        <Grid item key={index}>
          {action}
        </Grid>
      ))}
    </Grid>
  );
};

const DefaultActions = ({ onClose }: ActionProps) => [
  <Button label={strings.labels.close} onClick={onClose} />,
];

export const Modal: Component = ({
  header,
  trigger,
  paddedContent = true,
  children,
  actions = DefaultActions,
}) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const BodyContent =
    typeof children === "function" ? children({ onClose }) : children;

  return (
    <>
      <MuiModal open={open}>
        <Box
          display={"flex"}
          height="100vh"
          width="100vw"
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={12} md={6} sx={[containerStyles]}>
            <ModalHeader onClose={onClose} header={header} />
            <ModalBody children={BodyContent} paddedContent={paddedContent} />
            <ModalActions onClose={onClose} actions={actions} />
          </Grid>
        </Box>
      </MuiModal>
      <span onClick={onOpen}>{trigger}</span>
    </>
  );
};
