import { Box, FormHelperText } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getApiService } from "../../helpers/bulk-upload-helpers";
import { getValidationProps } from "../../helpers/control-helpers";
import type { FileReaderResult } from "../../helpers/file-parser";
import { logger } from "../../helpers/log-helpers";
import { validate } from "../../helpers/validation-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useForm } from "../../hooks/general/useForm";
import { fileTypeOptions } from "../../resources/options/settings/bulkUpload";
import { bulkUploadStrings as strings } from "../../resources/strings/settings/bulkUpload";
import type { ValidationConstraints, ValidationResults } from "../../types";
import { BulkUploadType } from "../../types/documents/BulkUpload";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { Dropdown } from "../general/controls/Dropdown";
import { Input } from "../general/controls/Input";
import { FileUpload } from "../general/FileUpload";

export interface Props {
  onClose: () => void;
}
type Component = (props: Props) => JSX.Element;

const allowedFileTypes = ["text/csv"];

type UploadData = {
  type: BulkUploadType | "";
  file: null | FileReaderResult;
  name: string;
};

const initialUploadData: UploadData = {
  type: "",
  file: null,
  name: "",
};

const constraints: ValidationConstraints<UploadData> = {
  type: {
    presence: {
      allowEmpty: false,
      message: `^File Type is required`,
    },
  },
  name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.name} is required`,
    },
    regex: {
      patterns: ["^([^&+]+)$", "^.*\\.(csv)$"],
      messages: [
        "^Invalid characters contained in file name. The following is not permitted: & +",
        "^Invalid file format. The following is permitted: .csv",
      ],
    },
  },
  file: {
    presence: {
      allowEmpty: false,
      message: `^File upload is required`,
    },
  },
};

export const BulkUploadContent: Component = ({ onClose }) => {
  const cancelToken = useCancelToken();
  const [uploadData, handleChange, setUploadData] =
    useForm<UploadData>(initialUploadData);

  const [validationResults, setValidationResults] = useState<
    Partial<ValidationResults<UploadData>>
  >({});

  const fileUploadProps = useMemo(
    () => ({ error: false, ...getValidationProps("file", validationResults) }),
    [validationResults]
  );

  const api = useMemo(
    () => (uploadData.type ? getApiService(uploadData.type) : undefined),
    [uploadData.type]
  );

  useEffect(() => {
    if (!uploadData.file) return;
    handleChange({
      target: {
        name: "name",
        value: uploadData.file.name,
      },
    });
  }, [uploadData.file, handleChange]);

  const handleFileSelect = useCallback(
    async (fileResult: FileReaderResult) => {
      handleChange({
        target: { name: "file", value: fileResult },
      });
    },
    [handleChange]
  );

  const handleUpload = async () => {
    setValidationResults({});
    if (!uploadData.file || typeof uploadData.file.data !== "string" || !api) {
      return;
    }

    try {
      const body = new FormData();
      body.append("file", uploadData.file.data);

      await api.uploadFile(uploadData.name, body, cancelToken);
      setUploadData(initialUploadData);
      onClose();
    } catch (e) {
      logger.error(e);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    validate(uploadData, constraints, handleUpload, setValidationResults);
  };

  const handleReset: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    onClose();
  };

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Box display={"flex"} gap={2} flexDirection="column">
        <BaseControl control={false}>
          <Dropdown
            config={{
              name: "type",
              label: strings.labels.selectFileType,
              options: fileTypeOptions,
              value: uploadData.type,
              ...getValidationProps("type", validationResults),
            }}
            handleChange={handleChange}
          />
        </BaseControl>
        <>
          <FileUpload
            allowedFileTypes={allowedFileTypes}
            handleUpload={handleFileSelect}
            maxSize={102400}
          />
          <ShowIf show={fileUploadProps.error}>
            <FormHelperText error>{fileUploadProps.helperText}</FormHelperText>
          </ShowIf>
        </>
        <BaseControl control={false}>
          <Input
            config={{
              name: "name",
              label: strings.labels.name,
              value: uploadData.name,
              ...getValidationProps("name", validationResults),
            }}
            handleChange={handleChange}
          />
        </BaseControl>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} sx={[{ mt: 2 }]}>
        <Button label={strings.labels.cancel} type="reset" variant="outlined" />
        <Button label={strings.labels.save} type="submit" />
      </Box>
    </form>
  );
};
