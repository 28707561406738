import { styled, SxProps, Theme } from "@mui/material";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  menuItemClasses,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { DownIcon } from "../../assets";
import { UpIcon } from "../../assets/UpIcon";
import { useMenuContext } from "../../context/MenuContext";
import { ShowIf, ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import type { MenuItemType } from "./listItems";
import { SubMenuItemContent } from "./SubMenuItemContent";

type MenuItemContentProps = {
  item: MenuItemType;
  index: number;
  parent?: string;
};

const selectedStyles: SxProps<Theme> = (theme) => ({
  borderLeft: theme.spacing(0.5, "solid", "transparent"),
  [`&.${menuItemClasses.selected}`]: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.lightgrey,
    "&:hover": {
      backgroundColor: theme.palette.common.grey,
    },
  },
});

const selectedTextStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.darkgrey,
});

const selectedIconStyles: SxProps<Theme> = (_theme) => ({
  color: "primary.main",
});

const textStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.altgrey,
  fontWeight: "bolder",
});

const iconStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.altgrey,
});

const Span = styled("span")({});

export const MenuItemContent = ({
  item,
  index,
  parent,
}: MenuItemContentProps) => {
  const {
    permissions,
    open: menuOpen,
    selected,
    handleSelect,
  } = useMenuContext();

  const history = useHistory();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { key, type, label, Icon, itemPermission } = item;

  const isSelected = useMemo(() => {
    if (!parent) return selected.includes(key);
    const [selectedParent, selectedChild] = selected;
    return selectedParent === parent && selectedChild === key;
  }, [parent, selected, key]);

  useEffect(() => {
    if (!isSelected) setSubmenuOpen(false);
  }, [isSelected]);

  const { isStandardItem, link, childItems, hasChildItems } = useMemo(() => {
    const isStandardItem = type === "standard";

    const link = isStandardItem ? item.link : "";
    const childItems = isStandardItem ? [] : item.childItems;
    const hasChildItems = Boolean(childItems.length);
    return { isStandardItem, link, childItems, hasChildItems };
  }, [type, item]);

  const handleToggleSubmenu = () => {
    setSubmenuOpen((prev) => !prev);
  };

  const handleRedirect = (link: string) => {
    history.push(link);
  };

  const handleClick = () => {
    handleSelect(key, parent);

    if (isStandardItem) {
      handleRedirect(link);
    } else if (!parent) {
      handleToggleSubmenu();
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (menuOpen || !hasChildItems) return;
    setAnchorEl(e.currentTarget);
    if (!submenuOpen) handleToggleSubmenu();
  };

  const handleClosePopover = () => setAnchorEl(null);

  const Content = (
    <>
      <ListItem
        component={ListItemButton}
        sx={[selectedStyles]}
        selected={isSelected}
        onClick={handleClick}
        //@ts-ignore
        onMouseEnter={handleMouseEnter}
      >
        <ListItemIcon sx={[iconStyles, isSelected && selectedIconStyles]}>
          {Icon && <Icon />}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            sx: [textStyles, isSelected && selectedTextStyles],
          }}
        />
        <ShowIf show={hasChildItems}>
          <Span
            sx={[
              {
                color: (theme) => theme.palette.common.altgrey,
              },
              isSelected && selectedTextStyles,
            ]}
          >
            {submenuOpen ? (
              <UpIcon data-testid="up-icon" fontSize="large" />
            ) : (
              <DownIcon data-testid="down-icon" fontSize="large" />
            )}
          </Span>
        </ShowIf>
      </ListItem>
      <ShowIf show={hasChildItems && submenuOpen}>
        <SubMenuItemContent
          selectedStyles={selectedStyles}
          isSelected={isSelected}
          itemKey={key}
          childItems={childItems}
          anchorEl={anchorEl}
          handleClosePopover={handleClosePopover}
        />
      </ShowIf>
    </>
  );

  return (
    <Fragment key={`${label}-${index}`}>
      {itemPermission ? (
        <ShowIfAuthorised userPermissions={permissions} {...itemPermission}>
          {Content}
        </ShowIfAuthorised>
      ) : (
        <>{Content}</>
      )}
    </Fragment>
  );
};
