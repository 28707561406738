import { Button, FormHelperText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { Link } from "../../components/general/controls/Link";
import { Loader } from "../../components/general/Loader";
import { useForm } from "../../hooks/general/useForm";
import { confirmSignUp, signInWithDetails, signUp } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import { CognitoUser } from "../../types";
import { AuthWrapper } from "./AuthWrapper";
import type { SxProps, Theme } from "@mui/material";

interface Props {
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

const marginStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(2, "0"),
});

export const SignUp: Component = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const [formData, handleChange] = useForm({
    username: "",
    password: "",
    email: "",
    code: "",
  });
  const history = useHistory();

  useEffect(() => {
    if (user || success) history.push(paths.root);
  }, [user, success, history]);

  const onSignUp: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await signUp(formData.username, formData.password, formData.email);

      setLoading(false);
      setError("");
      setSignedUp(true);
    } catch (e: any) {
      setLoading(false);
      setSignedUp(false);
      setError(e ? e : strings.errors.signUp);
    }
  };

  const onConfirmSignUp: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await confirmSignUp(formData.username, formData.code);
      // confirming the sign up doesn't consider the user signed in
      // use the details received earlier to sign them in automatically
      await signInWithDetails(formData.username, formData.password);

      setLoading(false);
      setError("");
      setSuccess(true);
    } catch (e: any) {
      setLoading(false);
      setSignedUp(false);
      setError(e ? e : strings.errors.signUp);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return (
      <BaseControl control={false} margin>
        {control}
      </BaseControl>
    );
  };

  const renderSignUpForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.headers.signUp}
        </Typography>
        <form noValidate onSubmit={onSignUp} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.username,
                name: "username",
                label: strings.labels.username,
                fullWidth: true,
                autoComplete: "username",
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.email,
                name: "email",
                label: strings.labels.email,
                fullWidth: true,
                autoComplete: "email",
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.password,
                name: "password",
                label: strings.labels.password,
                type: "password",
                fullWidth: true,
                autoComplete: "password",
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.signUp}
            color="primary"
            variant="contained"
            fullWidth
            disabled={
              !formData.username || !formData.password || !formData.email
            }
            sx={[marginStyles]}
          />
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
        <Link
          key="login"
          config={{
            text: strings.labels.returnToLogin,
            to: paths.auth.login,
            sx: [marginStyles],
          }}
        />
      </>
    );
  };

  const renderVerificationForm = () => {
    return (
      <>
        <Typography>{strings.text.signUpCodeSent}</Typography>
        <form noValidate onSubmit={onConfirmSignUp} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.code,
                name: "code",
                label: strings.labels.code,
                type: "password",
                fullWidth: true,
                autoComplete: "code",
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formData.code}
            sx={[marginStyles]}
          />
        </form>
        <Link
          key="login"
          config={{
            text: strings.labels.returnToLogin,
            to: paths.auth.login,
            sx: [marginStyles],
          }}
        />
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline text={strings.labels.signingUp} />;
    }

    if (signedUp) {
      return renderVerificationForm();
    }
    return renderSignUpForm();
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
