import { useCallback, useEffect, useMemo, useState } from "react";
import type { Column, SortingRule } from "react-table";
import type { Crumb } from "../../components/general/BreadcrumbHeader";
import type { HeaderContent } from "../../components/general/Table";
import { Table } from "../../components/general/Table";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import { HooksLogger } from "../../hooks/hooks-logger";
import type { RouteProps, UserAccess } from "../../types";
import type { QueryBody, QueryFunction } from "../../types/API";

const hooksLogger = new HooksLogger("TableContainer/useEffect");

type TableColumn<D extends object> = Column<D> & { hidden?: boolean };

export interface Props<D extends object> extends RouteProps {
  api: {
    query: QueryFunction<D>;
  };
  header?: string;
  headerContent?: HeaderContent;
  tableColumns:
    | TableColumn<D>[]
    | ((props: {
        setTableData: React.Dispatch<React.SetStateAction<D[]>>;
      }) => TableColumn<D>[]);
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
    state?: object;
  };
  clickHandler?: (data: D) => void;
  manualPagination?: boolean;
  manualFiltering?: boolean;
  filters?: D;
  defaultSort?: SortingRule<D>[];
  children?: JSX.Element | Array<JSX.Element>;
  loading?: boolean;
  sortStorageKey?: string;
  inlineLoader?: boolean;
  topBorder?: boolean;
  pageSize?: number;
  crumbs?: Crumb[];
}

export const TableContainer = <D extends Record<string, unknown>>({
  api,
  permissions,
  header,
  tableColumns,
  createButtonConfig,
  clickHandler,
  manualPagination = false,
  filters: inputFilters,
  defaultSort = [],
  children,
  headerContent,
  loading: propsLoading,
  sortStorageKey = "sortObject",
  inlineLoader,
  topBorder,
  pageSize = 10,
  manualFiltering = true,
  crumbs,
}: Props<D>) => {
  const [tableData, setTableData] = useState<D[]>(() => []);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");
  const cancelToken = useCancelToken();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(() => pageSize);
  const [sort, setSort] = useSessionStorage<SortingRule<D>[]>(
    defaultSort,
    sortStorageKey
  );
  const filters = useMemo(
    () => (manualFiltering ? inputFilters : undefined),
    [manualFiltering, inputFilters]
  );

  const columns = useMemo(() => {
    const cols = Array.isArray(tableColumns)
      ? tableColumns
      : tableColumns({ setTableData });
    return cols.filter((col) => !col.hidden);
  }, [tableColumns]);

  const sortObject = useMemo(() => sort, [sort]);

  const isLoading = [loading, propsLoading].some((l) => l);

  const searchCritera = useMemo<QueryBody<D>>(
    () => ({
      page,
      size,
      sort: sortObject,
    }),
    [page, size, sortObject]
  );

  useEffect(() => {
    const body: QueryBody<D> = manualPagination ? searchCritera : {};
    if (filters) {
      body.query = filters;
    }

    const query = async () => {
      hooksLogger.request("Getting table data");

      setLoading(true);
      try {
        const { items = [], totalCount: count } = await api.query(
          body,
          cancelToken
        );

        setTableData(items);
        setTotalCount(count);
        setLoading(false);
        hooksLogger.success(items, count);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = logger.error(e);
        setError(error);
        setLoading(false);
        hooksLogger.error(error);
      }
    };

    query();
  }, [manualPagination, cancelToken, api, searchCritera, filters]);

  const onPageChange = useCallback((pageIndex: number) => {
    setPage(pageIndex);
  }, []);

  const onSizeChange = useCallback((pageSize: number) => {
    setSize(pageSize);
  }, []);

  const onSortChange = useCallback(
    (sortBy: SortingRule<D>[]) => {
      setSort(sortBy);
    },
    [setSort]
  );

  return (
    <div>
      <Table<D>
        // custom props
        permissions={permissions}
        header={header}
        createButtonConfig={createButtonConfig}
        loading={isLoading}
        clickHandler={clickHandler}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        onSortChange={onSortChange}
        sortObject={sortObject}
        children={children}
        headerContent={headerContent}
        inlineLoader={inlineLoader}
        topBorder={topBorder}
        filters={inputFilters}
        crumbs={crumbs}
        // react table props
        data={tableData}
        columns={columns}
        size={size}
        manualPagination={manualPagination}
        totalCount={totalCount}
      />
    </div>
  );
};
