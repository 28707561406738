import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { Mode } from "../../../components/general/types/Modify";
import { useAuthContext } from "../../../context/AuthContext";
import { logger } from "../../../helpers/log-helpers";
import { appStrings } from "../../../resources/strings/app";
import type { JobPermissionHandler } from "../../../services/jobpermissions.service";
import { useIsAuthorised } from "../../authentication/useIsAuthorised";
import { useCancelToken } from "../../general/useCancelToken";
import { HooksLogger } from "../../hooks-logger";

const hooksLogger = new HooksLogger("useJobPermission");

export const useJobPermission = (
  mode: Mode,
  fetch: boolean,
  readFunction: JobPermissionHandler,
  permission: string | string[],
  entity = appStrings.entities.jobs
) => {
  const { userPermissions } = useAuthContext();
  const { id: jobId } = useParams<{ id?: string }>();
  const [result, setResult] = useState(() => mode === "create");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const hasUserPermission = useIsAuthorised(
    userPermissions,
    entity,
    permission
  );

  const cancelToken = useCancelToken();

  useEffect(() => {
    const query = async (id: string) => {
      hooksLogger.request("Checking job permission:", permission);
      setLoading(true);
      try {
        const response = await readFunction(id, cancelToken);
        setResult(response);
        hooksLogger.success(permission, response);
        setLoading(false);
        setError("");
      } catch (e) {
        if (cancelToken.reason) return;
        const message = logger.error(e);
        hooksLogger.error(permission, message);
        setError(message);
        setLoading(false);
      }
    };
    if (mode !== "create" && jobId && hasUserPermission) query(jobId);
  }, [
    mode,
    readFunction,
    jobId,
    cancelToken,
    permission,
    hasUserPermission,
    // the value of "fetch" is not really important, just need to be able to re-execute
    // the use effect any time it changes
    fetch,
  ]);

  return [result, loading, error] as const;
};
