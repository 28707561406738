import {
  ApiContextProvider,
  ControlContextProvider,
  JobSectionContainer,
} from "@pulsion/forms-designer-2";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { BreadcrumbHeader } from "../../components/general/BreadcrumbHeader";
import { paths } from "../../navigation/paths";
import { jobStrings as strings } from "../../resources/strings/jobs";
import { jobRendererApi } from "../../services/formsDesigner/jobrenderer.service";
import { jobRendererThemes } from "./themes/jobRendererTheme";

const JobRendererBreadcrumbHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ jobName: string }>();

  return (
    <BreadcrumbHeader
      crumbs={[
        {
          text: state?.jobName ? state.jobName : strings.labels.job,
          to: paths.jobs.edit(id),
        },
        {
          text: strings.labels.jobSections,
        },
      ]}
      children={<></>}
    />
  );
};

export const JobRenderer = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <ApiContextProvider api={jobRendererApi}>
        <ControlContextProvider allowControlChanges>
          <>
            <JobRendererBreadcrumbHeader />
            <JobSectionContainer
              inputTheme={jobRendererThemes}
              jobId={id}
              rootOrganisation=""
            />
          </>
        </ControlContextProvider>
      </ApiContextProvider>
    </>
  );
};
