import { paths } from "../../navigation/paths";
import { clientStrings as strings } from "../../resources/strings/clients";
import type { FullRouteProps } from "../../types";
import { OrganisationList } from "../organisations/OrganisationList";

export interface Props extends FullRouteProps {}
type Component = (props: Props) => JSX.Element;

export const ClientList: Component = ({ ...routeProps }) => {
  return (
    <OrganisationList
      {...routeProps}
      header={strings.headers.clientList}
      createButtonLabel={strings.labels.createClient}
      createButtonPath={paths.clients.create}
      filterStorageKey="clientFilters"
      sortStorageKey="clientSort"
      getViewPath={paths.clients.view}
      editActionLabel={strings.labels.editClient}
      jobsActionLabel={strings.labels.viewJobs}
      isParentOrganisation
      viewChildLabel={strings.labels.viewSites}
      getViewChildPath={paths.sites.list}
      organisationTypeId={routeProps.secondLowestOrganisationTypeId}
    />
  );
};
